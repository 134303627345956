import { SORT_TYPES } from "app/constants";
import orderBy from "lodash/orderBy";

const sortTypesConfigs = {
	[SORT_TYPES.RECOMMENDED]: {
		sortFunc: items => orderBy(items, "ranking", "desc"),
	},
	[SORT_TYPES.TRIPADVISOR]: {
		sortFunc: items => orderBy(items, "tripadvisor.rating", "desc"),
	},
	[SORT_TYPES.HOLIDAYCHECK]: {
		sortFunc: items => orderBy(items, "holidaycheck.recommendation", "desc"),
	},
	[SORT_TYPES.PRICE_ASC]: {
		sortFunc: items => orderBy(items, "total", "asc"),
	},
	[SORT_TYPES.PRICE_DESC]: {
		sortFunc: items => orderBy(items, "total", "desc"),
	},
};
// we should filter the products list because orderBy by default, it places the elements that do not contain the specified property at the top of the list
const getFilteredProducts = (activeSort, products) => {
	switch (activeSort) {
		case SORT_TYPES.RECOMMENDED:
			return products.filter(product => product?.ranking !== undefined);
		case SORT_TYPES.TRIPADVISOR:
			return products.filter(product => product?.tripadvisor?.rating !== undefined);
		case SORT_TYPES.HOLIDAYCHECK:
			return products.filter(product => product?.holidaycheck?.recommendation !== undefined);
		case SORT_TYPES.PRICE_ASC:
		case SORT_TYPES.PRICE_DESC:
			return products.filter(product => product?.total !== undefined);
		default:
			return products;
	}
};

const getProductsWithoutProperty = (activeSort, products) => {
	switch (activeSort) {
		case SORT_TYPES.RECOMMENDED:
			return products.filter(product => product?.ranking === undefined);
		case SORT_TYPES.TRIPADVISOR:
			return products.filter(product => product?.tripadvisor?.rating === undefined);
		case SORT_TYPES.HOLIDAYCHECK:
			return products.filter(product => product?.holidaycheck?.recommendation === undefined);
		case SORT_TYPES.PRICE_ASC:
		case SORT_TYPES.PRICE_DESC:
			return products.filter(product => product?.total === undefined);
		default:
			return [];
	}
};

const updateSort = ({ products, activeSort }) => {
	const sortFunc = sortTypesConfigs[activeSort].sortFunc;
	const filteredProductList = getFilteredProducts(activeSort, products);
	const withoutPropertyProductList = getProductsWithoutProperty(activeSort, products);
	return sortFunc(filteredProductList).concat(withoutPropertyProductList);
};

export { updateSort };
