import { SEARCH_BOOKING } from "app/actionTypes";
import axios from "axios";
import env from "app/utils/env";

const GET_BOOKING_URL = "/recoverMyBookingData";

export const recoverBookingData = payload => {
	return {
		type: SEARCH_BOOKING,
		promise: axios.post(GET_BOOKING_URL, payload, {
			baseURL: env("ACCOUNT_API_URL"),
			headers: {
				"Content-type": "text/plain",
			},
		}),
	};
};
