import { format } from "date-fns";
import { STRESS_MARKETING_LEVEL } from "app/constants";

const getStressMarketingDataFromDate = (date, stressMarketingMsgMapping = {}) => {
	const departureDate = format(new Date(date), "yyyy-MM-dd");
	const stressMarketingMsg = stressMarketingMsgMapping[departureDate];
	const isHighLevelMsg = stressMarketingMsg?.level === STRESS_MARKETING_LEVEL.HIGH;

	const isMediumLevelMsg = stressMarketingMsg?.level === STRESS_MARKETING_LEVEL.MEDIUM;

	return {
		isHighLevelMsg,
		isMediumLevelMsg,
		stressMarketingMsg,
	};
};
export default getStressMarketingDataFromDate;
