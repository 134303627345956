import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import "./FooterLogos.scss";
import IconLogoAtoutFrance from "app/pages/.shared/static/icons/IconLogoAtoutFrance";
import IconLogoSeto from "app/pages/.shared/static/icons/IconLogoSeto";

class FooterLogos extends PureComponent {
	render() {
		const { shop = "" } = this.props;

		const locale = shop.slice(3, 5);

		switch (locale) {
			case "FR": {
				return (
					<Fragment>
						<div className="footer-logos">
							<div className="footer-logos__item">
								<IconLogoAtoutFrance width={45} />
							</div>
							<div className="footer-logos__item">
								<IconLogoSeto width={45} />
							</div>
						</div>
					</Fragment>
				);
			}
			case "GB": {
				return <div className="footer-logos" />;
			}
			default:
				return false;
		}
	}
}

FooterLogos.propTypes = {
	shop: PropTypes.string.isRequired,
};

export default FooterLogos;
