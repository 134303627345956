import { connect } from "react-redux";
import PaymentModesTooltip from "app/pages/Booking/Payment/PaymentData/PaymentDataModeFirst/PaymentModes/PaymentModesTooltip";

const mapStateToProps = state => {
	const availablePaymentMethods = state?.partner?.availablePaymentMethods;

	let { onlinePercentage = null } = availablePaymentMethods.find(({ termsChoices }) =>
		termsChoices.includes(2)
	);
	return {
		onlinePercentage: onlinePercentage ? onlinePercentage : 50,
	};
};
export default connect(mapStateToProps)(PaymentModesTooltip);
