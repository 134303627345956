import { useContext, useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import env from "app/utils/env";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import AppGlobalsContext from "app/AppGlobalsContext";
import { useLocation, useParams } from "react-router-dom";
import { getQuoteTotalWithDelta } from "app/pages/Booking/bookingSelectors";
import { getStore } from "app/configureStore";
import { BRANDS } from "app/constants";

const useOrangeHeader = ({ display = true }) => {
	const [oLoadScriptLoaded, setOLoadScriptLoaded] = useState(false);
	const [idZoneScript, setIdZoneScriptLoaded] = useState(false);

	const { brand } = useContext(AppGlobalsContext);

	const { pathname } = useLocation();
	const { productUri } = useParams();

	const isListing = pathname === "/listing";
	const isSDPListing = pathname === "/sdp/listing";
	const isConfirmationPage = pathname.includes("/confirmation");
	const isSDPProductQuotePage = pathname === "/sdp/booking/quote";
	const isQuotePage = pathname === "/booking/quote";
	const isPaiementPage = pathname.includes("/payment");
	const isHome = pathname === "/" || pathname === "/sdp/search";
	const isProductPage = Boolean(productUri);

	let pageType = "";
	if (isListing) {
		pageType = "listing_vente_flash";
	} else if (isSDPListing) {
		pageType = "listing_recherche";
	} else if (isHome) {
		pageType = "home_page";
	} else if (isProductPage) {
		pageType = "fiche_voyage_non_config";
	} else if (isConfirmationPage) {
		pageType = "confirmation";
	} else if (isQuotePage) {
		pageType = "devis";
	} else if (isPaiementPage) {
		pageType = "paiement";
	} else if (isSDPProductQuotePage) {
		pageType = "fiche_voyage_config";
	}

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	useLayoutEffect(() => {
		if (brand === BRANDS.OV) {
			axios.get("/partner/orange/idzone", { baseURL: env("BASE_URL") }).then(response => {
				const o_data = {
					domaine: "voyages",
					univers_affichage: "perfectstay",
					sous_univers: "perfectstay",
					source_contenu: "perfectstay",
					segment: "orange",
					titre_page: document.title, // document title
					type_page: pageType, // type de page : hp, listing, offre, video
					// client_log: userIsConnected ? "Authentifie" : "Anonyme", // si utilisateur logué : "Authentifie" , sinon "Anonyme",
					canal: isMobile ? "Mobile Web" : "Web", // si desktop : "Web", sinon : "Mobile Web"
				};

				if (isConfirmationPage) {
					const state = getStore().getState(); // a remplacer par un hook de react-redux
					// on envoie le montant du panier sans les frais de paiement
					const carAmount = getQuoteTotalWithDelta(state);
					o_data.cart_amount = carAmount;
				}

				const o_confCommon = {
					typeEnv: "prod",
					domainTitle: "Tendances",
					domainUrl: "https://tendances.orange.fr/",
					header: {
						id: "o-header",
					},
					footer: {
						id: "o-footer",
					},
					headerDisplay: display,
					unifiedSearchEngine: {
						cmplUrl: "//completion.ke.orange.fr/proxy/cmplsearchbox",
						label: "Rechercher dans Tendances",
						queryUrl: "https://lemoteur.orange.fr",
						queryParamName: "kw",
						queryAdditionalParams: "module=chaine&bhv=tendances&ap=-1",
						cmplBlocks: [
							{
								baseId: "-tendances",
								max: null,
							},
						],
					},
				};

				window.o_data = o_data;
				window.o_confCommon = o_confCommon;

				const script = document.createElement("script");
				script.setAttribute("src", response.data.url);
				script.setAttribute("type", "text/javascript");
				script.onload = () => {
					setIdZoneScriptLoaded(true);
				};
				document.head.appendChild(script);

				const oLoadScript = document.createElement("script");
				oLoadScript.setAttribute(
					"src",
					"https://c.woopic.com/libs/common/o_load_responsive.js"
				);
				oLoadScript.setAttribute("type", "text/javascript");
				oLoadScript.onload = () => {
					setOLoadScriptLoaded(true);
				};
				document.head.appendChild(oLoadScript);

				// pour les pubs et liens commerciaux
				const adgw_js = document.createElement("script");
				adgw_js.src = `https://cdn.adgtw.orangeads.fr/mediation/ora_voyages.${
					productUri ? "resultat.fichesproduits" : "hp"
				}.js`;
				adgw_js.defer = true;
				document.getElementsByTagName("head")[0].appendChild(adgw_js);
			});
		}
	}, [pathname, brand, display]);

	useEffect(() => {
		if (oLoadScriptLoaded) {
			const script2 = document.createElement("script");
			script2.innerText = `head.ready(function() {o_footer();});`;
			script2.setAttribute("type", "text/javascript");
			document.body.appendChild(script2);
		}
	}, [oLoadScriptLoaded, idZoneScript]);
};

export default useOrangeHeader;
