import { createSelector } from "reselect";

const getBookingActivities = state => state.booking.activities;
const getQuotationActivities = state => state.quotation.activities;

export const getGuestsCountCrossCategories = activityCode =>
	createSelector(
		[getBookingActivities],
		(activities = []) => {
			const activity = activities.find(activity => activity.code === activityCode);

			if (!activity) {
				return 0;
			}

			return activity.guests.reduce((acc, { guestsCount }) => {
				return acc + guestsCount;
			}, 0);
		}
	);

export const getSelectedActivitiesForPriceDetails = createSelector(
	[getBookingActivities, getQuotationActivities],
	(selectedActivities = [], quotationActivities = []) => {
		const selectedActivitiesForPriceDetails = selectedActivities
			.filter(selectedActivity => selectedActivity.date)
			.map(selectedActivity => {
				const selectedDate = selectedActivity.date;

				const quotationActivity = quotationActivities.find(
					quotationActivity => quotationActivity.code === selectedActivity.code
				);

				const quotationActivityGuests =
					quotationActivity && quotationActivity.guestsPerDay[selectedDate];

				if (quotationActivityGuests && quotationActivityGuests.length > 0) {
					const selectedActivityGuestsWithPrice = selectedActivity.guests.map(
						selectedActivityGuest => {
							// eslint-disable-next-line max-nested-callbacks
							const quotationActivity = quotationActivityGuests.find(guest => {
								return guest.code === selectedActivityGuest.code;
							});

							if (quotationActivity) {
								selectedActivityGuest.includedGuest =
									quotationActivity.includedGuest;
								selectedActivityGuest.pricePerQuantity =
									quotationActivity.pricePerQuantity;
								selectedActivityGuest.minAge = quotationActivity.minAge;
								selectedActivityGuest.maxAge = quotationActivity.maxAge;
							}

							return selectedActivityGuest;
						}
					);

					selectedActivity.guests = selectedActivityGuestsWithPrice;
				}

				return selectedActivity;
			});

		return selectedActivitiesForPriceDetails;
	}
);
