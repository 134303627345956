import { createSelector } from "reselect";
import { buildCalendarMessageMapping } from "app/pages/StressMarketing/stressMarketingUtils";
import { getPrices } from "app/pages/PrePackage/searchEngineSelectors";
import { PRODUCT_EXPERIENCES_WITH_HOTEL } from "app/constants";
import getStressMarketingDataFromDate from "app/utils/hooks/getStressMarketingDataFromDate";
import { addDays, differenceInDays } from "date-fns";
import { isEmpty } from "lodash";

const getStressMarketingMessages = state => state?.stressMarketing?.stressMarketingMessages;
const getProductStressMarketingData = state => state?.stressMarketing?.productStressMarketingData;

const getIsWithHotelProduct = state =>
	PRODUCT_EXPERIENCES_WITH_HOTEL.includes(state?.ficheProduit?.productExperience);

export const getCalendarStressMarketingMessagesMapping = createSelector(
	[getStressMarketingMessages, getProductStressMarketingData],
	(stressMarketingMessages = [], productStressMarketingData = {}) => {
		return buildCalendarMessageMapping(stressMarketingMessages, productStressMarketingData);
	}
);

export const getStressMarketingLabel = departureDate => {
	return createSelector(
		[getCalendarStressMarketingMessagesMapping],
		(stressMarketingMsgMapping = {}) => {
			if (departureDate && !isEmpty(stressMarketingMsgMapping)) {
				const {
					isHighLevelMsg,
					stressMarketingMsg,
					isMediumLevelMsg,
				} = getStressMarketingDataFromDate(departureDate, stressMarketingMsgMapping);

				return {
					stressMarketingLabel:
						stressMarketingMsg &&
						(isHighLevelMsg
							? stressMarketingMsg.longMessage
							: stressMarketingMsg.message),
					isMediumLevelMsg: isMediumLevelMsg,
					level: stressMarketingMsg?.level,
					stressMarketingShortLabel: isHighLevelMsg && stressMarketingMsg?.shortMessage,
				};
			}
			return {};
		}
	);
};

export const getStressMarketingLabelForQuotationRoom = departureDate => {
	return createSelector(
		[getCalendarStressMarketingMessagesMapping],
		(stressMarketingMsgMapping = {}) => {
			if (departureDate && !isEmpty(stressMarketingMsgMapping)) {
				const { isHighLevelMsg, stressMarketingMsg } = getStressMarketingDataFromDate(
					departureDate,
					stressMarketingMsgMapping
				);
				return isHighLevelMsg ? stressMarketingMsg?.shortMessage : undefined;
			}

			return undefined;
		}
	);
};

export const getAllCalendarDates = (offerCode, contractType) => {
	return createSelector(
		[getPrices(offerCode, contractType), getIsWithHotelProduct],
		(prices = [], isWithHotel) => {
			let sortedDates = prices;
			if (isWithHotel) {
				// Sort dates by dd date
				sortedDates = sortedDates?.sort((a, b) => a.dd - b.dd);

				// calculate duration
				let dateWithCompleteInfo = sortedDates?.find(item => item.dd && item.ed);
				let duration = dateWithCompleteInfo
					? (dateWithCompleteInfo.ed - dateWithCompleteInfo.dd) / (1000 * 60 * 60 * 24)
					: 0;

				for (let i = 0; i < sortedDates.length - 1; i++) {
					let currentDate = new Date(sortedDates[i].dd);
					let nextDate = new Date(sortedDates[i + 1].dd);
					let diffDays = differenceInDays(nextDate, currentDate);

					// Check if the difference between two dates is more than 7 days
					if (diffDays > 7) {
						continue; // Ignore adding objects for this date pair
					}

					// Add missing dates if the difference is more than 1 day
					if (diffDays > 1) {
						for (let j = 1; j < diffDays; j++) {
							let missingDate = addDays(currentDate, j);
							let missingDateTimestamp = missingDate.getTime();

							// Add the object of the missing day
							sortedDates.splice(i + j, 0, {
								dd: missingDateTimestamp,
								ed: addDays(missingDate, duration).getTime(),
								soldOutDate: true,
								o: false,
							});
						}
					}
				}
			}
			return sortedDates;
		}
	);
};
