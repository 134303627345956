import env from "app/utils/env";
import axios from "axios";
import {
	FETCH_LANDING_PAGE,
	SET_LANDING_PAGE_PRODUCTS_FILTER_BY,
	UNSET_LANDING_PAGE_PRODUCTS_FILTER_BY,
} from "app/pages/LandingPage/landingPageActionTypes";
import {
	finishLoadingBar,
	showLoadingBar,
} from "app/pages/.shared/LoadingBar/loadingBarActionCreators";

export const fetchLandingPage = ({ landingPageCode, shop, productsVisibility, partnerCode }) => {
	return {
		type: FETCH_LANDING_PAGE,
		promise: fetchLandingPageApi({
			landingPageCode,
			shop,
			productsVisibility,
			partnerCode,
		}),
	};
};

export const fetchLandingPageApi = ({ landingPageCode, shop, productsVisibility, partnerCode }) => {
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return axios.get(
		`${endpoint}/${productsVisibility}/${shop}/${partnerCode}/landingPageSeo/${landingPageCode}.json`
	);
};

export const setLandingPageProductsFilter = filterBy => dispatch => {
	dispatch(showLoadingBar());

	setTimeout(() => {
		dispatch(finishLoadingBar());
	}, 300);

	dispatch({
		type: SET_LANDING_PAGE_PRODUCTS_FILTER_BY,
		filterBy,
	});
};

export const unsetLandingPageProductsFilter = name => {
	return {
		type: UNSET_LANDING_PAGE_PRODUCTS_FILTER_BY,
		name,
	};
};
