import { createContext } from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const AppGlobalsContext = createContext({
	brand: "",
	shop: "",
	partnerCode: "",
	resolution: RESOLUTION.UNKNOWN,
	productsVisibility: "",
	envVars: {},
	strategy: "",
});

export default AppGlobalsContext;
