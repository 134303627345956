import { useContext, useEffect, useState } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";

const FACEBOOK_API_VERSION = "v12.0";

export default () => {
	const [isFacebookSDKReady, setFacebookSDKReady] = useState(false);
	const { shop = "", envVars } = useContext(AppGlobalsContext);
	const language = shop.replace("-", "_");

	const facebookAppId = envVars.FACEBOOK_APP_ID;

	const loadSdkAsynchronously = () => {
		(function(d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = `https://connect.facebook.net/${language}/sdk.js`;
			fjs.parentNode.insertBefore(js, fjs);
		})(document, "script", "facebook-jssdk");
		// code above is given by facebook deocumentation
	};

	const addFacebookSDKListener = () => {
		// Code below is given by facebook deocumentation.
		// Paste from the doc and update appId, version and language
		// @see https://developers.facebook.com/docs/javascript/reference/FB.init/v12.0

		window.fbAsyncInit = function() {
			FB.init({
				appId: facebookAppId,
				cookie: true,
				version: FACEBOOK_API_VERSION,
			});

			setFacebookSDKReady(true);
		};
	};

	const checkIfFacebookSDKReady = () => {
		return document.getElementById("facebook-jssdk");
	};

	useEffect(() => {
		if (facebookAppId) {
			if (checkIfFacebookSDKReady()) {
				setFacebookSDKReady(true);
			} else {
				addFacebookSDKListener();
				loadSdkAsynchronously();
			}
		}
	}, []);

	return isFacebookSDKReady;
};
