import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";
import isEmpty from "lodash/isEmpty";

const INITIAL_STATE = {
	officeHours: [],
	callPrice: "",
	contextPhones: [],
	helpContactUrl: "",
	email: "",
	crispWebsiteId: "",
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			const { callCenter: callCenterData = {} } = action.res.data || {};

			let callCenter = callCenterData;

			if (isEmpty(callCenterData)) {
				callCenter = state;
			} else if (callCenterData.contextPhones && callCenterData.contextPhones.length > 0) {
				callCenter = {
					...callCenterData,
					contextPhones: callCenterData.contextPhones.map(contextPhone => ({
						...contextPhone,
						displayPhone: contextPhone.displayPhone
							? contextPhone.displayPhone.split(" ")
							: undefined,
						displayInternationalPhone: contextPhone.displayInternationalPhone
							? contextPhone.displayInternationalPhone.split(" ")
							: undefined,
					})),
				};
			}

			if (callCenterData.services && !isEmpty(callCenterData.services)) {
				callCenter.services = {
					...callCenterData.services,
					servicesDisplayPhone: callCenterData.services.servicesDisplayPhone
						? callCenterData.services.servicesDisplayPhone.split(" ")
						: undefined,
					servicesDisplayInternationalPhone: callCenterData.services
						.servicesDisplayInternationalPhone
						? callCenterData.services.servicesDisplayInternationalPhone.split(" ")
						: undefined,
				};
			}

			return callCenter;
		}
		default: {
			return state;
		}
	}
};
