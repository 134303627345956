import PropTypes from "prop-types";
import { merchandisingMediaShape, resolutionPropType } from "app/utils/propTypes";
import { memo } from "react";
import MerchandisingMedias from "app/pages/Merchandising/Media/MerchandisingMedias";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import "./ContentImageCarrousel.scss";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { htmlSanitize } from "app/utils/sanitize";
import LineClampViewMore from "app/pages/.shared/LineClampViewMore/LineClampViewMore";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const ContentImageCarrousel = ({ title, description = [], articles = [], resolution }) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	return (
		<div className="content-image-carousel">
			{title && (
				<div className="content-image-carousel__title">
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL4}
						variantMD={TYPOGRAPHY_VARIANTS.XL6}
						isBold
						className="content-image-carousel__title-label"
					>
						{title}
					</Typography>
				</div>
			)}
			{!isEmpty(description) && (
				<div className="content-image-carousel__description">
					<LineClampViewMore
						enableShowMore={true}
						lineCount={isMobile ? 4 : 10}
						text={
							<span
								dangerouslySetInnerHTML={{
									__html: htmlSanitize(description),
								}}
							/>
						}
					/>
				</div>
			)}

			<div
				className={classNames("content-image-carousel__articles", {
					"content-image-carousel__articles-only": !title && isEmpty(description),
				})}
			>
				<MerchandisingMedias articles={articles} resolution={resolution} />
			</div>
		</div>
	);
};

ContentImageCarrousel.propTypes = {
	title: PropTypes.string,
	description: PropTypes.arrayOf(PropTypes.string),
	articles: PropTypes.arrayOf(merchandisingMediaShape),
	resolution: resolutionPropType,
};

export default memo(ContentImageCarrousel);
