import {
	HIDE_ASIDE_MENU,
	SET_LISTING_FILTER_STICKYNESS,
	TOGGLE_ASIDE_MENU,
} from "../../actionTypes";

export const toggleAsideMenu = () => {
	return {
		type: TOGGLE_ASIDE_MENU,
	};
};

export const hideAsideMenu = () => {
	return {
		type: HIDE_ASIDE_MENU,
	};
};

export const setListingFilterStickness = isSticky => {
	return {
		type: SET_LISTING_FILTER_STICKYNESS,
		isSticky,
	};
};
