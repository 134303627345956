import {
	COUPON_STATUS,
	COUPON_TYPE,
	COUPON_UPCOMING_FOR,
	CREDIT_TYPES,
	LOYALTY_CREDIT_TYPES,
	LOYALTY_DISCOUNT_TYPES,
	PAYMENT_COUPON_TYPE,
	SHOW_CREDIT_NOTES_EXPIRATION_MAX_YEAR,
	SPONSORSHIP_SOURCES,
} from "app/constants";
import intervalToDuration from "date-fns/intervalToDuration";
import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { createSelector } from "reselect";

const getCoupons = state => state.coupons;
const getUsableCoupons = state => state.usableCoupons;
const getPromotionValue = state => get(state, "promotion.promotionValue", 0);
const getCouponType = state => state.booking.coupon;
const getCreditCampain = state => state?.coupons?.creditCampaign;
const getEnableCredits = state => state?.partner?.enableCredits;
const getEnableCreditNotes = state => state?.partner?.enableCreditNotes;

const isUpcoming = ({ minUseDate }) => {
	if (!minUseDate) {
		return false;
	}
	const today = new Date();
	today.setUTCHours(0, 0, 0, 0); // Truncate time to midnight

	const inputDate = new Date(minUseDate);
	inputDate.setUTCHours(0, 0, 0, 0);
	if (isSameDay(today, inputDate)) {
		return false;
	}
	return isAfter(inputDate, today);
};

const isExpired = ({ maxUseDate }) => {
	if (!maxUseDate) {
		return false;
	}
	const today = new Date();
	today.setUTCHours(0, 0, 0, 0); // Truncate time to midnight

	const inputDate = new Date(maxUseDate);
	inputDate.setUTCHours(0, 0, 0, 0);

	if (isSameDay(today, inputDate)) {
		return false;
	}
	return isAfter(today, inputDate);
};

export const getInscriptionCreditCampaign = createSelector(
	[getCreditCampain],
	(creditCampaign = []) => {
		return Array.isArray(creditCampaign) && creditCampaign?.length > 0
			? creditCampaign?.filter(
					c =>
						c.type === LOYALTY_CREDIT_TYPES.INSCRIPTION &&
						c.discountType === LOYALTY_DISCOUNT_TYPES.CURRENCY
			  )[0] || undefined
			: undefined;
	}
);

export const getTravelBackCreditCampaign = createSelector(
	[getCreditCampain],
	(creditCampaign = []) => {
		return Array.isArray(creditCampaign) && creditCampaign?.length > 0
			? creditCampaign?.filter(c => c.type === LOYALTY_CREDIT_TYPES.TRAVEL_BACK)[0] ||
					undefined
			: undefined;
	}
);

export const getActiveGenericCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return coupons?.generic?.current?.filter(c => !isUpcoming(c)) || [];
	}
);

export const getActiveTravelbackCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return coupons?.travelback?.current?.filter(c => !isUpcoming(c)) || [];
	}
);

export const getActiveSponsorshipCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return coupons?.sponsorship?.current?.filter(c => !isUpcoming(c)) || [];
	}
);

const checkIfCreditUsable = (credit = {}) =>
	!isExpired(credit) && credit.balance !== 0 && !isUpcoming(credit);

export const getAvailableCreditsTotal = createSelector(
	[getActiveGenericCredits, getActiveTravelbackCredits, getActiveSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		const genericCreditsUsable = genericCredits.filter(checkIfCreditUsable);
		const genericCreditsTotal =
			genericCreditsUsable.length > 0
				? genericCreditsUsable.reduce((accCredit, currentCredit = { balance: 0 }) => {
						return {
							balance: (accCredit.balance || 0) + (currentCredit.balance || 0),
						};
				  })
				: { balance: 0 };

		const travelBackCreditsUsable = travelBackCredits.filter(checkIfCreditUsable);
		const travelBackCreditsTotal =
			travelBackCreditsUsable.length > 0
				? travelBackCreditsUsable.reduce((accCredit, currentCredit = { balance: 0 }) => {
						return {
							balance: (accCredit.balance || 0) + (currentCredit.balance || 0),
						};
				  })
				: { balance: 0 };

		const sponsorshipCreditsUsable = sponsorshipCredits.filter(checkIfCreditUsable);
		const sponsorshipCreditsTotal =
			sponsorshipCreditsUsable.length > 0
				? sponsorshipCreditsUsable.reduce((accCredit, currentCredit = { balance: 0 }) => {
						return {
							balance: (accCredit.balance || 0) + (currentCredit.balance || 0),
						};
				  })
				: { balance: 0 };

		return (
			genericCreditsTotal.balance +
			travelBackCreditsTotal.balance +
			sponsorshipCreditsTotal.balance
		);
	}
);

export const getActiveCreditNotes = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "creditNote.current", []);
	}
);

export const getHistoryCreditNotes = createSelector(
	[getCoupons],
	(coupons = {}) => {
		const creditNotes = coupons?.creditNote?.history || [];
		const today = Date.now();

		return creditNotes.filter(creditNote => {
			if (creditNote.maxUseDate) {
				const duration = intervalToDuration({
					start: today,
					end: creditNote.maxUseDate,
				});

				return duration.years <= SHOW_CREDIT_NOTES_EXPIRATION_MAX_YEAR;
			}

			return false;
		});
	}
);

export const hasAnyCreditNotes = createSelector(
	[getActiveCreditNotes, getHistoryCreditNotes],
	(activeCreditNotes = [], historyCreditNotes = []) => {
		return activeCreditNotes.length > 0 || historyCreditNotes.length > 0;
	}
);

export const getAvailableCreditNotesTotal = createSelector(
	[getActiveCreditNotes],
	(creditNotes = []) => {
		const creditNoteTotal =
			creditNotes.length > 0
				? creditNotes.reduce((accCreditNote, currentCreditNote = { balance: 0 }) => {
						return {
							balance:
								(accCreditNote.balance || 0) + (currentCreditNote.balance || 0),
						};
				  })
				: { balance: 0 };

		return creditNoteTotal.balance;
	}
);

/**
 * USABLE CREDITS
 */

export const getUsableGenericCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "generic.usable", []);
	}
);

export const getUnusableGenericCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "generic.unusable", []);
	}
);

export const getUsableTravelbackCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "travelback.usable", []);
	}
);

export const getUnusableTravelbackCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "travelback.unusable", []);
	}
);

export const getUsableSponsorshipCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "sponsorship.usable", []);
	}
);

export const getUnusableSponsorshipCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "sponsorship.unusable", []);
	}
);

export const getSortedUsableSponsorshipCredits = createSelector(
	[getUsableSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source !== SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getUsableSponsorshipAcceptedCredits = createSelector(
	[getUsableSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source === SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getSortedUnusableSponsorshipCredits = createSelector(
	[getUnusableSponsorshipCredits],
	(unusableSponsorships = []) => {
		return (
			groupBy(
				unusableSponsorships.filter(
					unusableSponsorship =>
						unusableSponsorship.source !== SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getUnusableSponsorshipAcceptedCredits = createSelector(
	[getUnusableSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source === SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getUsableCreditsTotal = createSelector(
	[getUsableGenericCredits, getUsableTravelbackCredits, getUsableSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		return calculateUsableCreditsTotal(genericCredits, travelBackCredits, sponsorshipCredits);
	}
);

export const calculateUsableCreditsTotal = (
	genericCredits = [],
	travelBackCredits = [],
	sponsorshipCredits = []
) => {
	const genericCreditsTotal =
		genericCredits.length > 0
			? genericCredits.reduce((accCredit, usableCredit = { usableAmount: 0 }) => {
					return {
						usableAmount:
							(accCredit.usableAmount || 0) + (usableCredit.usableAmount || 0),
					};
			  })
			: { usableAmount: 0 };

	const travelBackCreditsTotal =
		travelBackCredits.length > 0
			? travelBackCredits.reduce((accCredit, usableCredit = { usableAmount: 0 }) => {
					return {
						usableAmount:
							(accCredit.usableAmount || 0) + (usableCredit.usableAmount || 0),
					};
			  })
			: { usableAmount: 0 };

	const sponsorshipCreditsTotal =
		sponsorshipCredits.length > 0
			? sponsorshipCredits.reduce((accCredit, usableCredit = { usableAmount: 0 }) => {
					return {
						usableAmount:
							(accCredit.usableAmount || 0) + (usableCredit.usableAmount || 0),
					};
			  })
			: { usableAmount: 0 };

	return (
		genericCreditsTotal.usableAmount +
		travelBackCreditsTotal.usableAmount +
		sponsorshipCreditsTotal.usableAmount
	);
};

export const getUsableCreditNotes = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "creditNote.usable", []);
	}
);

export const getUnusableCreditNotes = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "creditNote.unusable", []);
	}
);

export const getUsableCreditNotesTotal = createSelector(
	[getUsableCreditNotes],
	(creditNotes = []) => {
		const creditNoteTotal =
			creditNotes.length > 0
				? creditNotes.reduce((accCreditNote, usableCredit = { usableAmount: 0 }) => {
						return {
							usableAmount:
								(accCreditNote.usableAmount || 0) +
								(usableCredit.usableAmount || 0),
						};
				  })
				: { usableAmount: 0 };

		return creditNoteTotal.usableAmount;
	}
);

export const getOrderedUsableCredits = createSelector(
	[getUsableGenericCredits, getUsableTravelbackCredits, getUsableSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		let totallyUsableCredits = [];
		let partiallyUsableCredits = [];

		sponsorshipCredits.forEach(sponsorshipCredit => {
			if (sponsorshipCredit.usableAmount === sponsorshipCredit.balance) {
				totallyUsableCredits.push({
					...sponsorshipCredit,
					type: COUPON_TYPE.SPONSORSHIP,
				});
			} else if (sponsorshipCredit.usableAmount !== sponsorshipCredit.balance) {
				partiallyUsableCredits.push({
					...sponsorshipCredit,
					type: COUPON_TYPE.SPONSORSHIP,
				});
			}
		});

		travelBackCredits.forEach(travelBackCredit => {
			if (travelBackCredit.usableAmount === travelBackCredit.balance) {
				totallyUsableCredits.push({
					...travelBackCredit,
					type: COUPON_TYPE.TRAVELBACK,
				});
			} else if (travelBackCredit.usableAmount !== travelBackCredit.balance) {
				partiallyUsableCredits.push({
					...travelBackCredit,
					type: COUPON_TYPE.TRAVELBACK,
				});
			}
		});

		genericCredits.forEach(genericCredit => {
			if (genericCredit.usableAmount === genericCredit.balance) {
				totallyUsableCredits.push({
					...genericCredit,
					type: COUPON_TYPE.PROMOTION,
				});
			} else if (genericCredit.usableAmount !== genericCredit.balance) {
				partiallyUsableCredits.push({
					...genericCredit,
					type: COUPON_TYPE.PROMOTION,
				});
			}
		});

		totallyUsableCredits = orderBy(
			totallyUsableCredits,
			totallyUsableCredit => {
				return totallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		partiallyUsableCredits = orderBy(
			partiallyUsableCredits,
			partiallyUsableCredit => {
				return partiallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		return totallyUsableCredits.concat(partiallyUsableCredits);
	}
);

export const getOrderedUnusableCredits = createSelector(
	[getUnusableGenericCredits, getUnusableTravelbackCredits, getUnusableSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		let applicableUnusableCredits = [];
		let unApplicableUnusableCredits = [];

		sponsorshipCredits.forEach(sponsorshipCredit => {
			if (sponsorshipCredit.isApplicable) {
				applicableUnusableCredits.push({
					...sponsorshipCredit,
					type: COUPON_TYPE.SPONSORSHIP,
				});
			} else {
				unApplicableUnusableCredits.push({
					...sponsorshipCredit,
					type: COUPON_TYPE.SPONSORSHIP,
				});
			}
		});

		travelBackCredits.forEach(travelBackCredit => {
			if (travelBackCredit.isApplicable) {
				applicableUnusableCredits.push({
					...travelBackCredit,
					type: COUPON_TYPE.TRAVELBACK,
				});
			} else {
				unApplicableUnusableCredits.push({
					...travelBackCredit,
					type: COUPON_TYPE.TRAVELBACK,
				});
			}
		});

		genericCredits.forEach(genericCredit => {
			if (genericCredit.isApplicable) {
				applicableUnusableCredits.push({
					...genericCredit,
					type: COUPON_TYPE.PROMOTION,
				});
			} else {
				unApplicableUnusableCredits.push({
					...genericCredit,
					type: COUPON_TYPE.PROMOTION,
				});
			}
		});

		applicableUnusableCredits = orderBy(
			applicableUnusableCredits,
			applicableUnusableCredit => {
				return applicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		unApplicableUnusableCredits = orderBy(
			unApplicableUnusableCredits,
			unApplicableUnusableCredit => {
				return unApplicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		return applicableUnusableCredits.concat(unApplicableUnusableCredits);
	}
);

export const getOnlyNonApplicableCredits = createSelector(
	[getOrderedUnusableCredits],
	unusableCredits => {
		return unusableCredits.filter(unusableCredit => {
			return !unusableCredit.isApplicable;
		});
	}
);

export const getOnlyNonApplicableCreditNotes = createSelector(
	[getUnusableCreditNotes],
	unusableCreditNotes => {
		return unusableCreditNotes.filter(unusableCreditNote => {
			return !unusableCreditNote.isApplicable;
		});
	}
);

export const getOrderedUsableCreditNotes = createSelector(
	[getUsableCreditNotes],
	(usableCreditNotes = []) => {
		let totallyUsableCredits = [];
		let partiallyUsableCredits = [];

		usableCreditNotes.forEach(usableCreditNote => {
			if (usableCreditNote.usableAmount === usableCreditNote.balance) {
				totallyUsableCredits.push(usableCreditNote);
			} else if (usableCreditNote.usableAmount !== usableCreditNote.balance) {
				partiallyUsableCredits.push(usableCreditNote);
			}
		});

		totallyUsableCredits = orderBy(
			totallyUsableCredits,
			totallyUsableCredit => {
				return totallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		partiallyUsableCredits = orderBy(
			partiallyUsableCredits,
			partiallyUsableCredit => {
				return partiallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		return totallyUsableCredits.concat(partiallyUsableCredits);
	}
);

export const getOrderedUnusableCreditNotes = createSelector(
	[getUnusableCreditNotes],
	(unusableCreditNotes = []) => {
		let applicableUnusableCredits = [];
		let unApplicableUnusableCredits = [];

		unusableCreditNotes.forEach(unusableCreditNote => {
			if (unusableCreditNote.isApplicable) {
				applicableUnusableCredits.push(unusableCreditNote);
			} else {
				unApplicableUnusableCredits.push(unusableCreditNote);
			}
		});

		applicableUnusableCredits = orderBy(
			applicableUnusableCredits,
			applicableUnusableCredit => {
				return applicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		unApplicableUnusableCredits = orderBy(
			unApplicableUnusableCredits,
			unApplicableUnusableCredit => {
				return unApplicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		return applicableUnusableCredits.concat(unApplicableUnusableCredits);
	}
);

export const getPromotionAmount = createSelector(
	[getCouponType, getPromotionValue],
	(couponType, promotionValue = 0) => {
		return couponType === PAYMENT_COUPON_TYPE.PROMOCODE ? promotionValue : 0;
	}
);

export const getUsableCreditAmount = createSelector(
	[getCouponType, getUsableCreditsTotal],
	(couponType, creditsValue = 0) => {
		return couponType === PAYMENT_COUPON_TYPE.CREDIT ? creditsValue : 0;
	}
);

export const getAllCouponsTotal = createSelector(
	[
		getUsableCreditAmount,
		getUsableCreditNotesTotal,
		getPromotionAmount,
		getEnableCredits,
		getEnableCreditNotes,
	],
	(
		usableCreditsTotal = 0,
		usableCreditNotesTotal = 0,
		promotionValue = 0,
		isCreditEnabled = false,
		isCreditNoteEnabled = false
	) => {
		const creditsAmount = isCreditEnabled ? usableCreditsTotal : 0;
		const creditNotesAmount = isCreditNoteEnabled ? usableCreditNotesTotal : 0;
		return promotionValue + creditsAmount + creditNotesAmount;
	}
);

export const getVoucherTypesForAnalytics = createSelector(
	[getActiveGenericCredits, getActiveTravelbackCredits, getActiveSponsorshipCredits],
	(genericCredits = 0, travelBackCredits = 0, sponsorshipCredits = 0) => {
		const creditTypes = [];

		if (sponsorshipCredits.length > 0) {
			creditTypes.push(CREDIT_TYPES.SPONSORSHIP);
		}

		if (travelBackCredits.length > 0) {
			creditTypes.push(CREDIT_TYPES.TRAVELBACK);
		}

		if (genericCredits.length > 0) {
			creditTypes.push(CREDIT_TYPES.GENERIC);
		}

		return creditTypes.length > 0 ? creditTypes.join(",") : "";
	}
);

const getDefaultCouponValue = type =>
	({
		[COUPON_UPCOMING_FOR.friendBooked]: 15,
		[COUPON_UPCOMING_FOR.friendSubscribed]: 5,
	}[type] || 0);

const addTypeToCoupon = (list, type) => {
	if (!list || (list && list.length === 0)) {
		return [];
	}
	return type ? list.map(c => ({ ...c, type })) : list;
};

/*
 * The function categorizes coupons into three types: USABLE, UPCOMING, and USED.
 * - USABLE coupons: coupons with a non-zero balance or coupons with minUseDate before current date,
 * and must have maxUseDate after current date.
 * Usable coupons are categorized as either EXPIRE_SOON or AVAILABLE based on whether maxUseDate is within one week of the current date.
 * - UPCOMING coupons: coupons with a zero balance (coupon credited from someone's booking) or minUseDate after current date.
 * - USED coupons: list of expired coupons with any balance and historical coupons with a balance that has been used.
 */
export const getComputedCreditList = createSelector(
	[getCoupons],
	(coupons = {}) => {
		const todayTs = new Date().getTime();
		const oneWeekTs = 7 * 24 * 60 * 60 * 1000;

		const typedCurrentCredit = [
			...addTypeToCoupon(coupons?.generic?.current, COUPON_TYPE.PROMOTION),
			...addTypeToCoupon(coupons?.sponsorship?.current, COUPON_TYPE.SPONSORSHIP),
			...addTypeToCoupon(coupons?.travelback?.current, COUPON_TYPE.TRAVELBACK),
		].flat();
		const typedHistoryCredit = [
			...addTypeToCoupon(coupons?.generic?.history, COUPON_TYPE.PROMOTION),
			...addTypeToCoupon(coupons?.sponsorship?.history, COUPON_TYPE.SPONSORSHIP),
			...addTypeToCoupon(coupons?.travelback?.history, COUPON_TYPE.TRAVELBACK),
		].flat();

		const usableCredits = typedCurrentCredit
			.filter(credit => !isExpired(credit) && credit.balance !== 0 && !isUpcoming(credit))
			.map(credit => ({
				...credit,
				status:
					credit.maxUseDate && credit.maxUseDate - todayTs <= oneWeekTs // if today is before endAt one week => expire soon
						? COUPON_STATUS.EXPIRE_SOON
						: COUPON_STATUS.AVAILABLE,
			}));

		const upcomingCredits = typedCurrentCredit
			.filter(credit => credit.balance === 0 || isUpcoming(credit))
			.map(credit => ({
				...credit,
				amount: [
					COUPON_UPCOMING_FOR.friendBooked,
					COUPON_UPCOMING_FOR.friendSubscribed,
				].includes(credit.source) // check if credit coming from friendBooked or friendSubscribed
					? getDefaultCouponValue(credit.source)
					: credit.amount,
				status: COUPON_STATUS.UPCOMING,
			}));

		const unusedExpiredCredits = typedCurrentCredit.filter(credit => isExpired(credit));

		const usedCredits = [...unusedExpiredCredits, ...typedHistoryCredit].map(credit => ({
			...credit,
			status: credit.uses?.length > 0 ? COUPON_STATUS.USED : COUPON_STATUS.EXPIRED,
		}));

		return {
			...(usableCredits && usableCredits.length > 0 && { USABLE: usableCredits }),
			...(upcomingCredits && upcomingCredits.length > 0 && { UPCOMING: upcomingCredits }),
			...(usedCredits && usedCredits.length > 0 && { USED: usedCredits }),
		};
	}
);
