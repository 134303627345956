import { FETCH_TRIP_ADVISOR_DETAIL } from "app/actionTypes";
import axios from "axios";
import env from "app/utils/env";

export const fetchTripAdvisorDetail = (lang = "fr", id) => {
	const url = `${env("MARKETING_API_URL")}/tripadvisor/${lang}/${id}`;
	const headers = {
		"Content-type": "application/json",
	};
	return {
		type: FETCH_TRIP_ADVISOR_DETAIL,
		promise: axios.get(url, {}, { headers }),
	};
};
