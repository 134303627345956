import {
	CLEAR_PRODUCT_PAGE,
	FETCH_FICHE_PRODUIT_SUCCESS,
	FETCH_PRE_PACKAGE_PRICES_SUCCESS,
} from "../../actionTypes";
import find from "lodash/find";
import { OFFER_CONTRACT_TYPES, OFFER_TYPES } from "app/constants";

const prePackageInitialState = {
	timestamp: undefined,
	offers: [],
};

export default function prePackageOfferReducer(prePackageOffer = prePackageInitialState, action) {
	switch (action.type) {
		case CLEAR_PRODUCT_PAGE: {
			return prePackageInitialState;
		}
		case FETCH_FICHE_PRODUIT_SUCCESS: {
			return Object.assign({}, prePackageOffer, {
				offers: action.res.data.offers || [],
				timestamp: Date.now(),
			});
		}
		case FETCH_PRE_PACKAGE_PRICES_SUCCESS: {
			let prePackageOfferWithPrices = Object.assign({}, prePackageOffer);
			let offer = find(
				prePackageOfferWithPrices.offers,
				offer => offer.pricing.type === action.offerCode
			);

			if (offer) {
				if (
					action.offerCode === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION &&
					action.contractType === OFFER_CONTRACT_TYPES.HOTEL
				) {
					let departure;
					if (action.isAlternativeCity) {
						departure = find(
							offer.pricing.alternativeDepartureCities,
							city => city.code === action.departureCityCode
						);
					} else {
						departure = find(
							offer.pricing.departureCities,
							city => city.code === action.departureCityCode
						);
					}
					departure.durations = action.pricesPerDurations;
				} else if (
					action.offerCode === OFFER_TYPES.ACCOMODATION_ONLY &&
					action.contractType === OFFER_CONTRACT_TYPES.RENTAL
				) {
					offer.pricing.durations = action.pricesPerDurations;
				} else if (
					action.offerCode === OFFER_TYPES.ACCOMODATION_ONLY &&
					action.contractType === OFFER_CONTRACT_TYPES.HOTEL
				) {
					offer.pricing.durations = action.pricesPerDurations;
				}
			}
			return prePackageOfferWithPrices;
		}
		default:
			return prePackageOffer;
	}
}
