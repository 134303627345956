import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TripAdvisorDetail from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorDetail/TripAdvisorDetail";
import { fetchTripAdvisorDetail } from "app/pages/FicheProduit/components/TripAdvisor/tripAdvisorActionCreators";
import Loader from "app/pages/.shared/Loader";
import AppGlobalsContext from "app/AppGlobalsContext";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { HTTP_STATUS_CODES } from "app/constants";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";

const TripAdvisorDetailContainer = ({ tripAdvisorId, fetchTripAdvisorDetail = () => {} }) => {
	const [isfetching, setIsFetching] = useState(false);
	const [detail, setDetail] = useState({});
	const { shop = "" } = useContext(AppGlobalsContext);
	const lang = shop?.slice(0, 2);

	useEffect(() => {
		if (tripAdvisorId?.length > 0) {
			setIsFetching(true);
			fetchTripAdvisorDetail(lang, tripAdvisorId)
				.then(res => {
					if (res.status === HTTP_STATUS_CODES.OK) {
						setDetail(res.data);
					}
				})
				.finally(() => {
					setIsFetching(false);
				});
		}
	}, [shop]);

	if (isfetching) {
		return <Loader />;
	}
	return isEmpty(detail) ? (
		<AlertMessage
			message={<FormattedMessage id="error.generic" />}
			alertType={ALERT_TYPE.ERROR}
		/>
	) : (
		<TripAdvisorDetail
			rating={detail.rating}
			ranking_string={detail.ranking_data?.ranking_string}
			reviewsCount={detail.num_reviews}
			reviewRatingCount={detail.review_rating_count}
			subratings={detail.subratings}
			reviews={detail.reviews}
		/>
	);
};

TripAdvisorDetailContainer.propTypes = {
	tripAdvisorId: PropTypes.string,
	fetchTripAdvisorDetail: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ fetchTripAdvisorDetail }, dispatch);
};

export default connect(
	null,
	mapDispatchToProps
)(TripAdvisorDetailContainer);
