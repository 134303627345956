import loadable from "@loadable/component";

export const AsyncListingContainer = loadable(() =>
	import(/* webpackChunkName: "listing" */ "./pages/Listing/ListingContainer")
);

export const AsyncPriceMatchGuarantee = loadable(() =>
	import(/* webpackChunkName: "best-match-guarantee" */ "./pages/BestMatchGuarantee/BestMatchGuarantee")
);

export const AsyncFicheProduitContainer = loadable(() =>
	import(/* webpackChunkName: "fiche-produit" */ "./pages/FicheProduit/FicheProduitContainer")
);

export const AsyncMerchandisingContainer = loadable(() =>
	import(/* webpackChunkName: "merchandising" */ "./pages/Merchandising/MerchandisingContainer")
);

export const AsyncLandingPageContainer = loadable(() =>
	import(/* webpackChunkName: "landing-pages" */ "./pages/LandingPage/LandingPageContainer")
);

export const AsyncConfirmationContainer = loadable(() =>
	import(/* webpackChunkName: "confirmation" */ "./pages/Confirmation/ConfirmationContainer")
);

export const AsyncRestoreSessionContainer = loadable(() =>
	import(/* webpackChunkName: "restore-booking-session" */ "./pages/Booking/RestoreSession/RestoreSessionContainer")
);

export const AsyncSignupAuthContainer = loadable(() =>
	import(/* webpackChunkName: "landing-auth" */ "./pages/SignupPage/Auth/SignupAuthContainer")
);

export const AsyncAccountContainer = loadable(() =>
	import(/* webpackChunkName: "account" */ "./pages/Account/AccountContainer")
);

export const AsyncUnsubscribeContainer = loadable(() =>
	import(/* webpackChunkName: "unsubscribe" */ "./pages/Unsubscribe/UnsubscribeContainer")
);

export const AsyncAuthContainer = loadable(() =>
	import(/* webpackChunkName: "auth" */ "./pages/Auth/AuthPage/AuthPageContainer")
);

export const AsyncHomeContainer = loadable(() =>
	import(/* webpackChunkName: "home" */ "./pages/Home/HomeContainer")
);

export const AsyncSearchBookingPage = loadable(() =>
	import(/* webpackChunkName: "search-booking" */ "./pages/CorporateSite/CorporateSiteContainer.jsx")
);

export const AsyncNotFound = loadable(() =>
	import(/* webpackChunkName: "not-found" */ "./pages/NotFound/NotFound")
);

export const AsyncPaymentExternalRedirect = loadable(() =>
	import(/* webpackChunkName: "payment-external-redirect" */ "./pages/Booking/ExternalRedirect/PaymentExternalRedirect")
);

export const AsyncBooking = loadable(() =>
	import(/* webpackChunkName: "booking" */ "./pages/Booking/Booking")
);

export const AsyncProfileContainer = loadable(() =>
	import(/* webpackChunkName: "profile" */ "./pages/Account/Profile/ProfileContainer")
);

export const AsyncMyBookingsContainer = loadable(() =>
	import(/* webpackChunkName: "my-bookings" */ "./pages/Account/MyBookings/MyBookingsContainer")
);

export const AsyncCustomerRequestFormContainer = loadable(() =>
	import(/* webpackChunkName: "customer-request" */ "./pages/Account/CustomerRequest/CustomerRequestFormContainer")
);

export const AsyncMyBookingPageContainer = loadable(() =>
	import(/* webpackChunkName: "my-booking-page" */ "./pages/Account/MyBookings/MyBookingPage/MyBookingPageContainer")
);

export const AsyncSubscriptionsContainer = loadable(() =>
	import(/* webpackChunkName: "subscriptions" */ "./pages/Account/Subscriptions/SubscriptionsContainer")
);

export const AsyncSecurityContainer = loadable(() =>
	import(/* webpackChunkName: "security" */ "./pages/Account/Security/SecurityContainer")
);

export const AsyncParrainageContainer = loadable(() =>
	import(/* webpackChunkName: "parrainage" */ "./pages/Account/Parrainage/ParrainageContainer")
);

export const AsyncMyCreditsContainer = loadable(() =>
	import(/* webpackChunkName: "my-credits" */ "./pages/Account/MyCoupons/MyCreditsContainer")
);

export const AsyncMyRefundsContainer = loadable(() =>
	import(/* webpackChunkName: "my-refunds" */ "./pages/Account/MyCoupons/MyRefundsContainer")
);

export const AsyncBookingAsideContainer = loadable(() =>
	import(/* webpackChunkName: "booking-aside" */ "./pages/Booking/BookingAside/BookingAsideContainer")
);

export const AsyncRentalSearchEngineContainer = loadable(() =>
	import(/* webpackChunkName: "rental-serach-engine" */ "./pages/PrePackage/rental/RentalSearchEngineContainer")
);

export const AsyncSearchEngineContainer = loadable(() =>
	import(/* webpackChunkName: "search-engine" */ "./pages/PrePackage/SearchEngineContainer")
);

export const AsyncQuotationContainer = loadable(() =>
	import(/* webpackChunkName: "quotation" */ "./pages/Booking/Quotation/QuotationContainer")
);

export const AsyncBookingSearchContainer = loadable(() =>
	import(/* webpackChunkName: "search" */ "./pages/Booking/Search/BookingSearchContainer")
);

export const AsyncAuthBookingContainer = loadable(() =>
	import(/* webpackChunkName: "auth-booking" */ "./pages/Booking/Auth/AuthBookingContainer")
);

export const AsyncSignupMarketing = loadable(() =>
	import(/* webpackChunkName: "signup-marketing" */ "./pages/SignupPage/Auth/SignupMarketing/SignupMarketing")
);

export const AsyncTechnicalErrorContainer = loadable(() =>
	import(/* webpackChunkName: "technical-error" */ "./pages/TechnicalError/TechnicalErrorContainer")
);

export const AsyncBookingHeaderContainer = loadable(() =>
	import(/* webpackChunkName: "booking-header" */ "./pages/Booking/BookingHeader/BookingHeaderContainer")
);

export const AsyncHeaderContainer = loadable(() =>
	import(/* webpackChunkName: "header" */ "./pages/Header/HeaderContainer")
);

export const AsyncFaqHeaderContainer = loadable(() =>
	import(/* webpackChunkName: "faq-header" */ "./pages/Faq/FaqHeader/FaqHeader")
);

export const AsyncFaqContainer = loadable(() =>
	import(/* webpackChunkName: "faq" */ "./pages/Faq/FaqContainer")
);

export const AsyncStaticPageContainer = loadable(() =>
	import(/* webpackChunkName: "staticPage" */ "./pages/StaticPage/StaticPageContainer")
);

export const AsyncSmartDPContainer = loadable(() =>
	import(/* webpackChunkName: "smartdp" */ "./pages/SmartDP/SmartDPContainer")
);

export const AsyncStopoverContainer = loadable(() =>
	import(/* webpackChunkName: "stopover" */ "./pages/Stopover/StopoverContainer")
);

export const AsyncHotelOnlyContainer = loadable(() =>
	import(/* webpackChunkName: "hotel-only" */ "./pages/HotelOnly/HotelOnlyContainer")
);

export const AsyncAuthBookingLayerContainer = loadable(() =>
	import(/* webpackChunkName: "booking-auth" */ "./pages/Auth/AuthBookingLayer/AuthBookingLayerContainer")
);

export const AsyncSearchMyBookingContainer = loadable(() =>
	import(/* webpackChunkName: "search-my-booking" */ "./pages/Account/SearchMyBooking/SearchMyBookingContainer")
);

export const AsyncAccountFaqContactContainer = loadable(() =>
	import(/* webpackChunkName: "faq-contact" */ "./pages/Account/AccountFaqContact/AccountFaqContactContainer")
);

export const AsyncSmartDPSearchFormContainer = loadable(() =>
	import(/* webpackChunkName: "sdp-search-form" */ "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchFormContainer")
);

export const AsyncStopoverSearchFormContainer = loadable(() =>
	import(/* webpackChunkName: "stopover-search-form" */ "app/pages/Stopover/StopoverSearchForm/StopoverSearchFormContainer")
);

export const AsyncHotelOnlySearchFormContainer = loadable(() =>
	import(/* webpackChunkName: "hotel-only-search-form" */ "app/pages/HotelOnly/HotelOnlySearchForm/HotelOnlySearchFormContainer")
);

export const AsyncHotelOnlyListingContainer = loadable(() =>
	import(/* webpackChunkName: "hotel-only-listing" */ "app/pages/HotelOnly/Listing/HotelOnlyListingContainer")
);

export const AsyncWebsiteTunnelSwitcher = loadable(() =>
	import(/* webpackChunkName: "website-tunnel-switcher" */ "app/pages/.shared/WebsiteTunnelSwitcher/WebsiteTunnelSwitcher")
);

export const AsyncSmartDPListingContainer = loadable(() =>
	import(/* webpackChunkName: "smartdp-listing" */ "./pages/SmartDP/Listing/SmartDPListingContainer")
);

// export const AsyncPaymentContainer = loadable(() =>
// 	import("./pages/Booking/Payment/PaymentContainer")
// );
