import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { hasCustomDynamicHeader } from "app/reducers/brandSelector";
import { getPartnerMarketing } from "app/reducers/partnerSelector";
import { useEffect } from "react";
import { clearBooking } from "app/pages/Booking/bookingActionCreators";
import PropTypes from "prop-types";
import HomeLayout from "app/pages/HomeLayout/HomeLayout";
import { clearSmartDP } from "app/pages/SmartDP/smartDPActionCreators";

const HomeLayoutContainer = props => {
	useEffect(() => {
		props.clearSmartDP();
		props.clearBooking();
	}, []);

	return <HomeLayout {...props} />;
};

const mapStateToProps = state => {
	const marketing = getPartnerMarketing(state);
	return {
		isBrandWithCustomHeader: hasCustomDynamicHeader(state),
		isTransparentHeader: marketing?.isTransparentSdpSearchHeader || false,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			clearBooking,
			clearSmartDP,
		},
		dispatch
	);
};

HomeLayoutContainer.propTypes = {
	clearBooking: PropTypes.func,
	clearSmartDP: PropTypes.func,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeLayoutContainer);
