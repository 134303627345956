/**
 *
 * @callback successHandler
 * @param {object} success The post request response
 * @param {function} dispatch
 *
 */
/**
 * @callback errorHandler
 * @param {object} error The post request response
 * @param {function} dispatch
 *
 */
/**
 * @function promiseFactory
 * @param {promiseGenerator} promiseOnData
 * @param {successHandler} successHandler
 * @param {errorHandler} errorHandler
 * @param {bodyToRequest} bodyToRequest
 */
export default (promiseOnData, successHandler = id => id, errorHandler = id => id) => {
	return (data, dispatch, props) => {
		return new Promise((resolve, reject) => {
			promiseOnData(data, dispatch, props)
				.then(res => resolve(successHandler(res, dispatch, props)))
				.catch(error => {
					if (error.response) {
						return reject(errorHandler(error.response, dispatch, props));
					}
					return reject(errorHandler(error, dispatch, props));
				});
		});
	};
};
