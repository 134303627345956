import env from "app/utils/env";
import {
	FETCH_SPONSORSHIP,
	GET_SPONSOR_ID,
	SET_SPONSOR_ID,
	SPONSOR_FRIENDS,
	RESET_PARENT_SPONSOR_ID,
	SET_CAN_INVITE_BY_EMAIL_AT,
} from "app/actionTypes";
import axios from "app/utils/http/http";

export const fetchSponsorship = () => {
	return {
		type: FETCH_SPONSORSHIP,
		promise: axios.get(env("SPONSORSHIP_API_URL") + "/sponsorship", {
			headers: {
				"Content-type": "application/json",
			},
		}),
	};
};

export const getSponsorId = () => {
	return {
		type: GET_SPONSOR_ID,
		promise: axios.get(env("SPONSORSHIP_API_URL") + "/sponsorship/getSponsorId", {
			headers: {
				"Content-type": "application/json",
			},
		}),
	};
};

export const setSponsorId = sponsorId => {
	return {
		type: SET_SPONSOR_ID,
		sponsorId,
	};
};

export const sponsorFriends = payload => {
	return {
		type: SPONSOR_FRIENDS,
		promise: axios.post(env("SPONSORSHIP_API_URL") + "/sponsorship/inviteByEmail", payload, {
			headers: {
				"Content-type": "application/json",
			},
		}),
	};
};

export const resetParentSponsorId = () => {
	return {
		type: RESET_PARENT_SPONSOR_ID,
	};
};

export const setCanInviteByEmailAt = canInviteByEmailAt => {
	return {
		type: SET_CAN_INVITE_BY_EMAIL_AT,
		canInviteByEmailAt,
	};
};
