import { useContext } from "react";
import { getPartnerMarketing } from "app/reducers/partnerSelector";
import { connect } from "react-redux";
import SmartDPMarketingHeroImage from "app/pages/SmartDP/SmartDPMarketingHeroImage/SmartDPMarketingHeroImage";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import PropTypes from "prop-types";

const SmartDPMarketingHeroImageContainer = ({
	heroImageMobile,
	heroImageDesktop,
	heroImageMobileThumbnail,
	heroImageDesktopThumbnail,
	gradient,
	sizes,
	backgroundPosition,
	isBackgroundProgressiveImg,
}) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;
	const backgroundImageUrl = isMobile ? heroImageMobile : heroImageDesktop;
	const backgroundImageThumbnail = isMobile
		? heroImageMobileThumbnail
		: heroImageDesktopThumbnail;
	return (
		<SmartDPMarketingHeroImage
			imageUrl={backgroundImageUrl}
			imageThumbnail={backgroundImageThumbnail}
			gradient={gradient}
			sizes={sizes}
			resolution={resolution}
			backgroundPosition={backgroundPosition}
			isBackgroundProgressiveImg={isBackgroundProgressiveImg}
		/>
	);
};

SmartDPMarketingHeroImageContainer.propTypes = {
	heroImageMobile: PropTypes.string,
	heroImageDesktop: PropTypes.string,
	heroImageMobileThumbnail: PropTypes.string,
	heroImageDesktopThumbnail: PropTypes.string,
	sizes: PropTypes.object,
	isBackgroundProgressiveImg: PropTypes.bool,
	backgroundPosition: PropTypes.string,
	gradient: PropTypes.string,
};

const mapStateToProps = (state, props) => {
	const marketing = getPartnerMarketing(state);
	const {
		heroImageMobile,
		heroImageDesktop,
		heroImageMobileThumbnail,
		heroImageDesktopThumbnail,
	} = props;
	return {
		heroImageMobile: heroImageMobile || marketing?.heroImageMobile,
		heroImageDesktop: heroImageDesktop || marketing?.heroImageDesktop,
		heroImageMobileThumbnail: heroImageMobileThumbnail || marketing?.heroImageMobileThumbnail,
		heroImageDesktopThumbnail:
			heroImageDesktopThumbnail || marketing?.heroImageDesktopThumbnail,
	};
};

export default connect(mapStateToProps)(SmartDPMarketingHeroImageContainer);
