import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { forceCheck } from "react-lazyload";
import { authSuccess } from "app/pages/Auth/AuthActions";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";
import { AUTH_LAYER_SHOW_DELAY, BRANDS } from "app/constants";
import AuthLayerMobile from "app/pages/Auth/AuthLayerMobile/AuthLayerMobile";
import { isAuthenticated, isIdentified } from "app/pages/Auth/authSelectors";
import FrequencyModal from "app/pages/.shared/NewsletterFrequency/FrequencyModal/FrequencyModal";
import { shouldShowFrequencyModal } from "app/pages/Account/Profile/profileSelector";
import {
	postSubscriptions,
	setNewsletterFrequencyModalViewed,
} from "app/pages/Account/Subscriptions/subscriptionsActionCreators";
import { sendTagOnSubscriptionUpdate } from "app/utils/analytics";
import AuthModal from "app/pages/Auth/AuthModal/AuthModal";
import { brandPropTypes } from "app/utils/propTypes";
import { Navigate } from "react-router-dom";
import useBlockViewportScroll from "app/pages/.shared/useBlockViewportScroll";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { getPartnerAuthLayer } from "app/reducers/partnerSelector";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const RequireSignupFirst = props => {
	const {
		resolution,
		acceptCookyPolicy,
		showFrequencyModal,
		renderComponent,
		postSubscriptions,
		setNewsletterFrequencyModalViewed,
		shop,
		brand,
		isAuthenticated,
		isIdentified,
		protectedPageTexts = {},
	} = props;
	const [isAuthLayerVisible, setAuthLayerVisible] = useState(false);

	const { title: landingTitle, description: landingDescription } = protectedPageTexts;

	const signinMarketingBlock = useMemo(
		() => (
			<div className="auth-modal__marketing">
				<div className="auth-modal__marketing-content">
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL3}
						variantMD={TYPOGRAPHY_VARIANTS.XL4}
						isBold
					>
						<div className="auth-modal__marketing-title">
							{landingTitle && landingTitle.length > 0 && (
								<Paragraphs paragraphs={landingTitle} enableHTMLFormatting />
							)}
						</div>
					</Typography>

					{landingDescription && landingDescription.length > 0 && (
						<div className="auth-modal__marketing-description">
							<Paragraphs paragraphs={landingDescription} enableHTMLFormatting />
						</div>
					)}
				</div>
			</div>
		),
		[protectedPageTexts]
	);

	const signupMarketingBlock = useMemo(
		() => (
			<div className="auth-modal__marketing">
				<div className="auth-modal__marketing-content">
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL3}
						variantMD={TYPOGRAPHY_VARIANTS.XL4}
						isBold
					>
						<div className="auth-modal__marketing-title">
							{landingTitle && landingTitle.length > 0 && (
								<Paragraphs paragraphs={landingTitle} enableHTMLFormatting />
							)}
						</div>
					</Typography>

					{landingDescription && landingDescription.length > 0 && (
						<div className="auth-modal__marketing-description">
							<Paragraphs paragraphs={landingDescription} enableHTMLFormatting />
						</div>
					)}
				</div>
			</div>
		),
		[protectedPageTexts]
	);

	const shouldShowAuthLayer = !isAuthenticated && !isIdentified;

	useEffect(() => {
		// La verification du uuid doit etre a l'interieur du setTimeout pour laisser le temps à la rehydratation
		// de valoriser l'uuid. De meme ne pas mettre AUTH_LAYER_SHOW_DELAY = 0s
		setTimeout(() => {
			if (shouldShowAuthLayer) {
				setAuthLayerVisible(true);
				if (resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM) {
					acceptCookyPolicy();
				}
				forceCheck(); // forcer l'apparition du footer qui est lazyloadé
			} else {
				setAuthLayerVisible(false);
			}
		}, AUTH_LAYER_SHOW_DELAY);
	}, [shouldShowAuthLayer]);

	useBlockViewportScroll(isAuthLayerVisible || showFrequencyModal);

	const onAuthSuccess = useCallback(() => {
		setAuthLayerVisible(false);
	}, []);

	const saveFrequency = useCallback(data => {
		data.silent = true; // permet de ne pas envoyer d'email de modification de compte et d'harceler le client
		postSubscriptions(data).then(() => {
			sendTagOnSubscriptionUpdate(data.newsletterPreference);
		});
	}, []);

	const onFrequencyModalClose = useCallback(() => {
		setNewsletterFrequencyModalViewed();
	}, []);

	if (brand === BRANDS.XX) {
		if (!isAuthenticated) {
			return (
				<Navigate
					to={{
						pathname: `/`,
					}}
				/>
			);
		}
		return renderComponent(props);
	} else if (resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM) {
		return isAuthLayerVisible ? (
			<AuthLayerMobile
				onAuthSuccess={onAuthSuccess}
				resolution={resolution}
				showAtolAbta={shop === "en-GB"}
				signinMarketingBlock={signinMarketingBlock}
				signupMarketingBlock={signupMarketingBlock}
			/>
		) : (
			<>
				{renderComponent(props)}
				<FrequencyModal
					isOpen={showFrequencyModal && !isAuthLayerVisible}
					handleSubmit={saveFrequency}
					onModalClose={onFrequencyModalClose}
				/>
			</>
		);
	}

	return (
		<>
			{renderComponent(props)}
			{isAuthLayerVisible && (
				<AuthModal
					isOpen={isAuthLayerVisible}
					onAuthSuccess={onAuthSuccess}
					showAtolAbta={shop === "en-GB"}
					signinMarketingBlock={signinMarketingBlock}
					signupMarketingBlock={signupMarketingBlock}
				/>
			)}
			<FrequencyModal
				isOpen={showFrequencyModal && !isAuthLayerVisible}
				handleSubmit={saveFrequency}
				onModalClose={onFrequencyModalClose}
			/>
		</>
	);
};

RequireSignupFirst.propTypes = {
	resolution: PropTypes.string,
	brand: brandPropTypes,
	shop: PropTypes.string,
	renderComponent: PropTypes.func,
	acceptCookyPolicy: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	isIdentified: PropTypes.bool,
	showFrequencyModal: PropTypes.bool,
	postSubscriptions: PropTypes.func,
	setNewsletterFrequencyModalViewed: PropTypes.func,
	protectedPageTexts: PropTypes.object,
};

const mapStateToProps = state => {
	const authLayerTexts = getPartnerAuthLayer(state);
	return {
		shop: state.shop,
		brand: state.brand.code,
		resolution: state.resolution,
		isAuthenticated: isAuthenticated(state),
		isIdentified: isIdentified(state),
		showFrequencyModal: shouldShowFrequencyModal(state),
		protectedPageTexts: authLayerTexts.protectedPage,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			authSuccess,
			acceptCookyPolicy,
			postSubscriptions,
			setNewsletterFrequencyModalViewed,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RequireSignupFirst);
