import {
	REPLACE_SDP_PRODUCTS_FILTERS,
	RESET_SDP_PRODUCTS_FILTER,
	SET_SDP_PRODUCTS_FILTER_BY,
	SET_SDP_PRODUCTS_FILTERS,
	UNSET_SDP_PRODUCTS_FILTER_BY,
	UNSET_SDP_PRODUCTS_FILTERS,
} from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionsTypes";

const initialState = {};

export default function smartDPProductsFilterReducer(productsFilter = initialState, action) {
	switch (action.type) {
		case SET_SDP_PRODUCTS_FILTER_BY: {
			const filters = Object.assign({}, productsFilter, action.filterBy);

			if (typeof window !== "undefined") {
				window.filtre = `?filterBy=${encodeURIComponent(JSON.stringify(filters))}`;
			}

			return filters;
		}
		case UNSET_SDP_PRODUCTS_FILTER_BY: {
			if (Object.prototype.hasOwnProperty.call(productsFilter, action.name)) {
				const filters = { ...productsFilter };
				delete filters[action.name];
				return filters;
			}
			return productsFilter;
		}
		case RESET_SDP_PRODUCTS_FILTER: {
			return initialState;
		}
		case UNSET_SDP_PRODUCTS_FILTERS: {
			const filters = { ...productsFilter };
			Object.keys(action.filters).forEach(filterKey => delete filters[filterKey]);
			return filters;
		}
		case SET_SDP_PRODUCTS_FILTERS: {
			if (action.filters.length) {
				return action.filters.reduce(
					(filtersAcc, filter) => ({
						...filtersAcc,
						[filter.key]: filter.value,
					}),
					{ ...smartDPProductsFilterReducer }
				);
			}
			return productsFilter;
		}
		case REPLACE_SDP_PRODUCTS_FILTERS: {
			return { ...initialState, ...action.filters };
		}
		default:
			return productsFilter;
		// return {};
	}
}
