import { useEffect } from "react";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import { useLocation } from "react-router-dom";

/**
 * Ce tag doit être envoyé au premier chargement de la page et à chaque changement d'url côté front.
 * En effet, GA est paramétré pour être téléchargé dans le navigateur à chaque virtualPageLoading. Sans GA chargé
 * au démarrage de l'appli, il n'y a pas d'analytics envoyés.
 * Le Tag de PageView Facebook se base sur l'event virtualPageLoading pour se déclencher
 * Crisp sur les pages account et faq se base sur l'event virtualPageLoading pour se déclencher
 */
const useVirtualPageLoading = () => {
	const { pathname } = useLocation();
	const { track } = useAnalytics();

	useEffect(() => {
		track("virtualPageLoading", {});
	}, [pathname]);
};

export default useVirtualPageLoading;
