import PropTypes from "prop-types";
import initial from "lodash/initial";
import List from "app/pages/.shared/List";
import { FormattedMessage, useIntl } from "react-intl";
import { PAYMENT_METHODS, PAYMENT_TERMS } from "app/constants";

const PaymentModesTooltip = ({ paymentModes = [], onlinePercentage }) => {
	const intl = useIntl();

	const paymentTermsAlma = paymentModes
		.filter(
			paymentMode =>
				paymentMode?.type && paymentMode.type.toLowerCase() === PAYMENT_METHODS.ALMA
		)
		.map(item => item.terms);
	const paymentModesInfo = [];
	const hasPaymentMode2X = paymentModes.find(
		paymentMode => paymentMode?.terms === PAYMENT_TERMS["2x"]
	);
	const hasPaymentMode4XCasino = paymentModes
		.filter(
			paymentMode =>
				paymentMode?.type && paymentMode.type.toLowerCase() === PAYMENT_METHODS.CASINO
		)
		.find(paymentMode => paymentMode?.terms === PAYMENT_TERMS["4x"]);

	const hasPaymentAlma = paymentModes.some(
		paymentMode => paymentMode?.type && paymentMode.type.toLowerCase() === PAYMENT_METHODS.ALMA
	);

	const hasStrictPaymentMode2X =
		paymentModes.length <= 2 &&
		paymentModes.some(paymentMode => {
			return paymentMode?.terms === PAYMENT_TERMS["2x"];
		});

	const hasStrictPaymentMode4XCasino =
		paymentModes.length <= 2 &&
		paymentModes.some(paymentMode => {
			return (
				paymentMode?.terms === PAYMENT_TERMS["4x"] &&
				paymentMode?.type &&
				paymentMode.type.toLowerCase() === PAYMENT_METHODS.CASINO
			);
		});

	const hasStrictPaymentAlma = paymentModes.every(paymentMode => {
		return (
			(paymentMode?.type && paymentMode.type.toLowerCase() === PAYMENT_METHODS.ALMA) ||
			paymentMode?.terms === 1
		);
	});

	if (hasPaymentMode2X && !hasStrictPaymentMode2X) {
		paymentModesInfo.push(
			<FormattedMessage
				id={"payment.options.popover.message.3.1"}
				key="payment.options.popover.message.3"
				values={{
					percentage: `${onlinePercentage}%`,
				}}
			/>
		);
	}
	if (hasStrictPaymentMode2X) {
		paymentModesInfo.push(
			<FormattedMessage
				id={"payment.options.popover.message.3.2"}
				key="payment.options.popover.message.3.2"
				values={{
					percentage: `${onlinePercentage}%`,
				}}
			/>
		);
	}
	if (hasPaymentMode4XCasino && !hasStrictPaymentMode4XCasino) {
		paymentModesInfo.push(
			<FormattedMessage
				id="payment.options.popover.message.4"
				key="payment.options.popover.message.4"
				values={{
					terms: (
						<FormattedMessage id="general.payment.term.short" values={{ term: "4" }} />
					),
				}}
			/>
		);
	}
	if (hasStrictPaymentMode4XCasino) {
		paymentModesInfo.push(
			<FormattedMessage
				id="payment.options.popover.message.4.1"
				key="payment.options.popover.message.4.1"
				values={{
					terms: (
						<FormattedMessage id="general.payment.term.short" values={{ term: "4" }} />
					),
				}}
			/>
		);
	}

	if (hasPaymentAlma && !hasStrictPaymentAlma) {
		paymentModesInfo.push(
			<FormattedMessage
				id="payment.options.popover.message.4"
				key="payment.options.popover.message.4"
				values={{
					terms:
						paymentTermsAlma.length > 1 ? (
							<FormattedMessage
								id="available.payment.terms.list"
								values={{
									firstTerms: initial(paymentTermsAlma)
										.map(term =>
											intl.formatMessage(
												{ id: "general.payment.term.short" },
												{ term }
											)
										)
										.join(", "),
									lastTerms: (
										<FormattedMessage
											id="general.payment.term.short"
											values={{
												term: paymentTermsAlma[paymentTermsAlma.length - 1],
											}}
										/>
									),
								}}
							/>
						) : (
							<FormattedMessage
								id="general.payment.term.short"
								values={{ term: paymentTermsAlma[0] }}
							/>
						),
				}}
			/>
		);
	}
	if (hasStrictPaymentAlma) {
		paymentModesInfo.push(
			<FormattedMessage
				id="payment.options.popover.message.4.1"
				key="payment.options.popover.message.4.1"
				values={{
					terms:
						paymentTermsAlma.length > 1 ? (
							<FormattedMessage
								id="available.payment.terms.list"
								values={{
									firstTerms: initial(paymentTermsAlma)
										.map(term =>
											intl.formatMessage(
												{ id: "general.payment.term.short" },
												{ term }
											)
										)
										.join(", "),
									lastTerms: (
										<FormattedMessage
											id="general.payment.term.short"
											values={{
												term: paymentTermsAlma[paymentTermsAlma.length - 1],
											}}
										/>
									),
								}}
							/>
						) : (
							<FormattedMessage
								id="general.payment.term.short"
								values={{ term: paymentTermsAlma[0] }}
							/>
						),
				}}
			/>
		);
	}

	return paymentModesInfo.length === 1 ? (
		<div>{paymentModesInfo}</div>
	) : (
		<>
			<div>
				<FormattedMessage id="payment.options.popover.message.1" />
			</div>
			<List items={paymentModesInfo} />
		</>
	);
};

PaymentModesTooltip.propTypes = {
	paymentModes: PropTypes.arrayOf(PropTypes.number),
	onlinePercentage: PropTypes.number,
};

export default PaymentModesTooltip;
