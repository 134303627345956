import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { memo, useState, useLayoutEffect, useRef } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import TripAdvisorRatingImage from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorRatingImage/TripAdvisorRatingImage";
import "./TripAdvisorReview.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const TripAdvisorReview = ({ rating = 0, title, text, travel_date, user = {} }) => {
	const contentRef = useRef();
	const [isContentClamped, updateContentClamped] = useState(false);
	const [isViewMore, updateIsViewMore] = useState(false);

	useLayoutEffect(() => {
		if (text && contentRef.current) {
			updateContentClamped(
				contentRef.current.offsetHeight < contentRef.current.scrollHeight === true
			);
		}
	}, [contentRef, text]);

	const toggleView = () => updateIsViewMore(!isViewMore);

	const contentClassNames = classNames({
		"tripadvisor-review__content": true,
		"tripadvisor-review__content--more": isViewMore,
	});

	return (
		<div className="tripadvisor-review" data-testid="tripadvisor-review">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.LARGE}
				isBold
				className="tripadvisor-review__title"
			>
				{title}
			</Typography>
			<div className="tripadvisor-review__meta">
				<TripAdvisorRatingImage rating={rating} />
				<div className="tripadvisor-review__date">
					<FormattedDate value={travel_date} day="2-digit" month="long" year="numeric" />
				</div>
				{!isEmpty(user) && (
					<span className="tripadvisor-review__user">{user.username}</span>
				)}
			</div>
			<div className={contentClassNames} ref={contentRef}>
				{text}
			</div>
			{isContentClamped && (
				<p className="tripadvisor-review__read-more" onClick={toggleView}>
					<FormattedMessage id={isViewMore ? "general.reduce" : "general.read.more"} />
				</p>
			)}
		</div>
	);
};

TripAdvisorReview.propTypes = {
	rating: PropTypes.number,
	title: PropTypes.string,
	text: PropTypes.string,
	travel_date: PropTypes.string,
	user: PropTypes.object,
};

export default memo(TripAdvisorReview);
