import { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
	FloatingPortal,
	offset,
	safePolygon,
	shift,
	useFloating,
	useHover,
	useInteractions,
} from "@floating-ui/react";
import HamburgerAccount from "app/pages/.shared/HamburgerAccount/HamburgerAccount";
import AccountMenuAuthenticatedContainer from "app/pages/Header/AccountMenu/AccountMenuAuthenticated/AccountMenuAuthenticatedContainer";
import AccountMenuUnauthenticatedContainer from "app/pages/Header/AccountMenu/AccountMenuUnauthenticated/AccountMenuUnauthenticatedContainer";
import IconCircleClose from "app/pages/.shared/IconCircleClose";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { BRANDS } from "app/constants";
import AsidePanel from "app/pages/.shared/AsidePanel/AsidePanel";

import "./AccountMenu.scss";
import RelativeLink from "app/pages/.shared/RelativeLink";

const AccountMenu = ({
	brand,
	isMobile = false,
	userIsConnected = false,
	toggleAsideMenu = () => {},
	hideAsideMenu = () => {},
}) => {
	const [openDropdownMenu, toggleDropdownMenu] = useState(false);
	const [openSideMenu, toggleSideMenu] = useState(false);

	const closeDropdownMenu = useCallback(() => {
		toggleDropdownMenu(false);
	}, []);

	const closeSideMenu = useCallback(() => {
		toggleSideMenu(false);
	}, []);

	const { context, x, y, refs, strategy } = useFloating({
		placement: "bottom-end",
		strategy: "absolute",
		openDropdownMenu,
		onOpenChange: toggleDropdownMenu,
		middleware: [offset(10), shift()],
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useHover(context, {
			mouseOnly: true,
			handleClose: safePolygon(),
		}),
	]);

	useEffect(() => {
		if (openSideMenu) {
			toggleAsideMenu();
		} else {
			hideAsideMenu();
		}
	}, [openSideMenu]);

	const AccountMenuContent = userIsConnected ? (
		<AccountMenuAuthenticatedContainer
			onItemClick={isMobile ? closeSideMenu : closeDropdownMenu}
		/>
	) : (
		<AccountMenuUnauthenticatedContainer
			onItemClick={isMobile ? closeSideMenu : closeDropdownMenu}
		/>
	);

	return (
		<div className="account-menu" data-testid="account-menu">
			{isMobile ? (
				<div className="header__hamburger">
					<HamburgerAccount onToogle={toggleSideMenu} />
					<AsidePanel
						isOpen={openSideMenu}
						className={classNames("account-menu__side-panel", {
							"account-menu__side-panel--eh": brand === BRANDS.EK,
						})}
						data-testid="account-menu-side-panel"
					>
						<div className="account-menu__side-panel-header">
							<RelativeLink to={{ pathname: "/" }} onClick={closeSideMenu}>
								<LogoBrandContainer />
							</RelativeLink>
							<IconCircleClose width={25} height={25} onClick={closeSideMenu} />
						</div>
						<div className="account-menu__side-panel-content">{AccountMenuContent}</div>
					</AsidePanel>
				</div>
			) : (
				<>
					<div
						className="account-menu__popover-button"
						ref={refs.setReference}
						{...getReferenceProps({})}
					>
						<HamburgerAccount />
					</div>
					<FloatingPortal>
						{openDropdownMenu && (
							<>
								<div
									ref={refs.setFloating}
									className="account-menu__dropdown"
									{...getFloatingProps({
										style: {
											position: strategy,
											left: x ?? "",
											top: y ?? "",
										},
									})}
								>
									{AccountMenuContent}
								</div>
							</>
						)}
					</FloatingPortal>
				</>
			)}
		</div>
	);
};

AccountMenu.propTypes = {
	brand: PropTypes.string,
	isMobile: PropTypes.bool,
	userIsConnected: PropTypes.bool,
	toggleAsideMenu: PropTypes.func,
	hideAsideMenu: PropTypes.func,
};

export default AccountMenu;
