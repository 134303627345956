import { useState } from "react";
import IconEye from "app/pages/.shared/static/icons/IconEye";
import IconEyeOff from "app/pages/.shared/static/icons/IconEyeOff";

const ICON_SIZE = 20;

const useShowPassword = (defaultState, size = ICON_SIZE) => {
	const [showPassword, setShowPassword] = useState(defaultState || false);
	const icon = showPassword ? (
		<IconEyeOff
			className="icon-eye-off"
			height={size}
			width={size}
			onClick={() => setShowPassword(false)}
		/>
	) : (
		<IconEye
			className="icon-eye"
			height={size}
			width={size}
			onClick={() => setShowPassword(true)}
		/>
	);
	return [icon, showPassword, setShowPassword];
};

export default useShowPassword;
