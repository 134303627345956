import PropTypes from "prop-types";
import React, { memo } from "react";

const CustomFormattedMessage = ({ message, values, className, children }) => {
	const interpolateMessage = (message, values) => {
		if (!message || !values) {
			return message;
		}

		// Diviser le message en parties autour des clés {key}
		const messageParts = message.split(/({[^{}]+})/g);

		// Parcourir les parties du message et insérer les valeurs
		return messageParts.map((part, index) => {
			const match = part.match(/{([^{}]+)}/);
			if (match) {
				const key = match[1].trim();
				const value = values[key];

				if (typeof value === "undefined") {
					// Si la valeur n'est pas définie, retourner la clé telle quelle
					return `{${key}}`;
				} else if (React.isValidElement(value)) {
					// Si la valeur est un élément React, le rendre tel quel
					return React.cloneElement(value, { key: index });
				} else if (Array.isArray(value)) {
					// Si la valeur est un tableau, rendre les éléments du tableau
					return value.map((item, i) =>
						React.isValidElement(item)
							? React.cloneElement(item, { key: `${index}-${i}` })
							: String(item)
					);
				}
				// Sinon, convertir la valeur en chaîne de caractères
				return String(value);
			}
			// Sinon, c'est une partie fixe du message
			return part;
		});
	};

	const renderedMessage = interpolateMessage(message, values);

	return className ? (
		<div className={className}>
			{renderedMessage}
			{children}
		</div>
	) : (
		<>
			{renderedMessage}
			{children}
		</>
	);
};

CustomFormattedMessage.propTypes = {
	message: PropTypes.string.isRequired,
	values: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.any,
};

export default memo(CustomFormattedMessage);
