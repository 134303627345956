const handleChunkLoadError = () => {
	window.location.reload();
};

const handleError = ({ error }) => {
	if (
		error === "ChunkLoadError" ||
		error?.name?.includes("ChunkLoadError") ||
		error?.message === "ChunkLoadError"
	) {
		handleChunkLoadError(error);
	}
};

export default handleError;
