/* eslint-disable no-unused-expressions */
/* eslint-disable guard-for-in */
import { STRESS_MARKETING_LEVEL } from "app/constants";
import { isEmpty } from "lodash";

/*
	demandLevelInterval 		COLUMN
	demandPercentageInterval 	ROW

	Data input is a list message LOW, MEDIUM, HIGH from sanity
	[
		...,
		{
			demandLevelInterval: "" ex: 20-40
			demandPercentageInterval: "",  ex: 40-60
			message: "",
			longMessage: "",
			shortMessage: "",
			level: LOW / MEDIUM / HIGH
		},
		...
	]

	Data output is map 2 dimensional
		messageMapping[demandPercentage][demandLevel] = {
			message,
			longMessage,
			shortMessage,
			level,
		}
*/
export const buildStressMarketingMessageMapping = cases => {
	if (!cases || cases?.length === 0) {
		return {};
	}

	const messageMapping = {};

	cases.forEach(item => {
		const {
			demandPercentageInterval,
			demandLevelInterval,
			message,
			longMessage,
			shortMessage,
			level,
		} = item;
		const [demandPercentageStart, demandPercentageEnd] = demandPercentageInterval
			?.split("-")
			?.map(Number);
		const [demandLevelStart, demandLevelEnd] = demandLevelInterval?.split("-")?.map(Number);
		for (
			let demandPercentage = demandPercentageStart;
			demandPercentage <= demandPercentageEnd;
			demandPercentage++
		) {
			for (let demandLevel = demandLevelStart; demandLevel <= demandLevelEnd; demandLevel++) {
				if (!messageMapping[demandPercentage]) {
					messageMapping[demandPercentage] = {};
				}
				messageMapping[demandPercentage][demandLevel] = {
					...(message && { message }),
					...(longMessage && { longMessage }),
					...(shortMessage && { shortMessage }),
					level,
				};
			}
		}
	});

	return messageMapping;
};

export const buildCalendarMessageMapping = (
	stressMarketingMessages = [],
	productStressMarketingData
) => {
	const calendarMessageMapping = {};
	if (stressMarketingMessages.length === 0 || isEmpty(productStressMarketingData)) {
		return {};
	}

	const messageMapping = buildStressMarketingMessageMapping(stressMarketingMessages);

	const { weeklyStats = [], topDays = {} } = productStressMarketingData;

	// build message mapping for weeklyStats
	weeklyStats?.map(weeklyStat => {
		const { weekEndDate, weekStartDate, demandLevel, demandPercentage } = weeklyStat;
		const currentDate = new Date(weekStartDate);
		const end = new Date(weekEndDate);

		while (currentDate <= end) {
			const formattedDate = currentDate.toISOString().split("T")[0];
			if (messageMapping[demandPercentage][demandLevel]) {
				calendarMessageMapping[formattedDate] =
					messageMapping[demandPercentage][demandLevel];
			}

			// Move to the next day
			currentDate.setDate(currentDate.getDate() + 1);
		}
	});

	// build message mapping for topDays
	const arrTopDays = [];
	for (const key in topDays) {
		if (topDays[key]) {
			const datesArray = topDays[key];
			arrTopDays.push(...datesArray);
		}
	}

	const stressMarketingMessageHighLevel = stressMarketingMessages.filter(
		item => item.level === STRESS_MARKETING_LEVEL.HIGH
	)[0];
	if (stressMarketingMessageHighLevel) {
		const { longMessage, shortMessage, level } = stressMarketingMessageHighLevel;
		arrTopDays?.map(day => {
			if (
				calendarMessageMapping[day] &&
				calendarMessageMapping[day]?.level === STRESS_MARKETING_LEVEL.HIGH
			) {
				calendarMessageMapping[day] = {
					...(longMessage && { longMessage }),
					...(shortMessage && { shortMessage }),
					level,
				};
			}
		});
	}

	// clear all message HIGH level except date in topdays
	for (const [date, value] of Object.entries(calendarMessageMapping)) {
		if (value?.level === STRESS_MARKETING_LEVEL.HIGH && !arrTopDays.includes(date)) {
			delete calendarMessageMapping[date];
		}
	}

	return calendarMessageMapping;
};
