import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import "./TripAdvisorReviewRatingCount.scss";

const WIDTH_NUMBER = 7.5;
const MINIMUM_LENGTH = 6;

const TripAdvisorReviewRatingCount = ({ reviewRatingCount = {} }) => {
	if (isEmpty(reviewRatingCount)) {
		return false;
	}

	const totalVote = Object.values(reviewRatingCount).reduce(
		(sum, value) => sum + parseInt(value, 10),
		0
	);

	const widthRatingCount =
		(Math.max(...Object.values(reviewRatingCount).map(value => value.toString().length)) ||
			MINIMUM_LENGTH) * WIDTH_NUMBER;

	return (
		<div
			className="tripadvisor-review-rating-count"
			data-testid="tripadvisor-review-rating-count"
		>
			{Object.keys(reviewRatingCount)
				.reverse()
				.map((index, key) => {
					const value = parseInt(reviewRatingCount[index], 10) || 0;
					const percent = value === 0 ? value : ((value / totalVote) * 100).toFixed(1);
					return (
						<div className="tripadvisor-review-rating-count__vote" key={key}>
							<p
								className="tripadvisor-review-rating-count__total"
								style={{
									width: `${widthRatingCount}px`,
								}}
							>
								<FormattedNumber value={value} styles="decimal" />
							</p>
							<div className="tripadvisor-review-rating-count__bar">
								<span
									className="tripadvisor-review-rating-count__progress"
									style={{
										width: `${percent}%`,
									}}
								/>
							</div>
							<div className="tripadvisor-review-rating-count__status">
								<FormattedMessage id={`tripadvisor.status.${index}`} />
							</div>
						</div>
					);
				})}
		</div>
	);
};

TripAdvisorReviewRatingCount.propTypes = {
	reviewRatingCount: PropTypes.object,
};

export default memo(TripAdvisorReviewRatingCount);
