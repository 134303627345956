import { createSelector } from "reselect";
import { BRANDS } from "app/constants";
import { getCurrentShop } from "app/reducers/shopSelector";
import { getShopData, isBrandWithAccount } from "app/utils/utils";

const getStrategy = state => state.partner.strategy;

export const getBrand = state => state.brand?.code;
export const getShopsList = state => state.brand?.shops;

export const isWhiteLabel = createSelector(
	[getStrategy, getBrand],
	(strategy, brand) => {
		return isBrandWithAccount({ strategy, brand });
	}
);

export const hasCustomDynamicHeader = createSelector(
	[getBrand],
	brand => {
		return brand === BRANDS.CD || brand === BRANDS.SP || brand === BRANDS.HP;
	}
);

export const getDefaultPartnerForShop = createSelector(
	[getShopsList, getCurrentShop],
	(shops = [], shop) => {
		const shopData = getShopData(shops, shop);
		const [lang] = shop.split("-");
		const defaultMarketingCodeData = shopData?.marketingCodes.find(marketingCode => {
			return marketingCode.isDefaultMarketingCodeForShop && marketingCode.locale === lang;
		});
		return defaultMarketingCodeData?.code;
	}
);

/**
 * Pour l'env preprod et canary, getDefaultPartnerForDomain renvoie le domaine BASE_URL configuré dans sanity live
 */
export const getDefaultPartnerForDomain = domain =>
	createSelector(
		[getShopsList, getCurrentShop],
		(shops = [], shop) => {
			const shopData = getShopData(shops, shop);
			const defaultMarketingCodeData = shopData?.marketingCodes.find(marketingCode => {
				return marketingCode.technicalVars.BASE_URL === domain;
			});
			return defaultMarketingCodeData;
		}
	);
