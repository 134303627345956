export const FETCH_COUPONS = "FETCH_COUPONS";
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_FAILURE = "FETCH_COUPONS_FAILURE";
export const FETCH_USABLE_CREDIT_NOTES = "FETCH_USABLE_CREDIT_NOTES";
export const FETCH_USABLE_CREDIT_NOTES_REQUEST = "FETCH_USABLE_CREDIT_NOTES_REQUEST";
export const FETCH_USABLE_CREDIT_NOTES_SUCCESS = "FETCH_USABLE_CREDIT_NOTES_SUCCESS";
export const FETCH_USABLE_CREDIT_NOTES_FAILURE = "FETCH_USABLE_CREDIT_NOTES_FAILURE";
export const FETCH_USABLE_COUPONS = "FETCH_USABLE_COUPONS";
export const FETCH_USABLE_COUPONS_REQUEST = "FETCH_USABLE_COUPONS_REQUEST";
export const FETCH_USABLE_COUPONS_SUCCESS = "FETCH_USABLE_COUPONS_SUCCESS";
export const FETCH_USABLE_COUPONS_FAILURE = "FETCH_USABLE_COUPONS_FAILURE";
export const HIDE_CREDITS_NOTIFICATION = "HIDE_CREDITS_NOTIFICATION";
export const HIDE_CREDITNOTES_NOTIFICATION = "HIDE_CREDITNOTES_NOTIFICATION";
export const FETCH_CREDIT_CAMPAIGN = "FETCH_CREDIT_CAMPAIGN";
export const FETCH_CREDIT_CAMPAIGN_REQUEST = "FETCH_CREDIT_CAMPAIGN_REQUEST";
export const FETCH_CREDIT_CAMPAIGN_SUCCESS = "FETCH_CREDIT_CAMPAIGN_SUCCESS";
export const FETCH_CREDIT_CAMPAIGN_FAILURE = "FETCH_CREDIT_CAMPAIGN_FAILURE";
