import { PRODUCTS_VISIBILITY } from "app/constants";

// Si /listing?preview dans l'url, alors on passe en mode preview ou l'on peut voir tous les produits
// Ce mode est désactivé par un firewall en production
// @see /front/server.js

export default function productsVisibilityReducer(productsVisibility = undefined) {
	const isPreview =
		productsVisibility === PRODUCTS_VISIBILITY.PREVIEW ||
		productsVisibility === "" ||
		(productsVisibility && productsVisibility !== PRODUCTS_VISIBILITY.CURRENT);
	return isPreview ? PRODUCTS_VISIBILITY.PREVIEW : PRODUCTS_VISIBILITY.CURRENT;
}
