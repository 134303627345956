import PropTypes from "prop-types";
import { isSponsorshipEnabled } from "app/pages/Header/conditionnalComponents/utils";

import "./Sponsorship.scss";

const NewBadge = ({ partnerCode }) =>
	isSponsorshipEnabled(partnerCode) && <i className="badge-sponsorship-ek" />;

NewBadge.propTypes = {
	partnerCode: PropTypes.string,
};

export default NewBadge;
