import PropTypes from "prop-types";
import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import appGlobalsContext from "app/AppGlobalsContext";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import { RESOLUTION as RESOLUTIONS } from "app/pages/.shared/responsive/responsiveReducer";
import { HTTP_STATUS_CODES } from "app/constants";
import "./SignInWithGoogleButton.scss";

/**
 * @see https://medium.com/designly/create-a-google-login-button-with-no-dependencies-in-react-next-js-7f0f025cd4b1
 * @see https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid?hl=fr
 */
const SignInWithGoogleButton = ({
	uxMode,
	source,
	googleClientId,
	authWithGoogle = () => {},
	onAuthSuccess = () => {},
	onAuthError = () => {},
	authSuccess = () => {},
}) => {
	const { shop, partnerCode, resolution } = useContext(appGlobalsContext);

	const isDesktop = resolution === RESOLUTIONS.LARGE || resolution === RESOLUTIONS.XLARGE;

	const [, setValue] = useState(false);
	const forceUpdate = useCallback(() => setValue(true), []);

	const { track } = useAnalytics();

	const locale = shop.slice(3, 5);

	const onClick = useCallback(() => {
		// nonce, state
		// eslint-disable-next-line no-console
		track("google_button_click");
	}, []);

	const onGoogleAuthSuccess = useCallback(data => {
		// clientId, client_id, credential, select_by
		authWithGoogle({
			accessToken: data.credential,
			partner: partnerCode,
			source,
		})
			.then(res => {
				if (res.status === HTTP_STATUS_CODES.OK) {
					if (res?.data?.signup) {
						track("google_auth_signup", {
							selectBy: data.select_by, // https://developers.google.com/identity/gsi/web/reference/js-reference?hl=en#select_by
						});
					} else {
						track("google_auth_signin", {
							selectBy: data.select_by,
						});
					}
					authSuccess(res);
					onAuthSuccess(res);
				} else {
					track("google_auth_error", {
						statusCode: res.status,
					});
					onAuthError();
				}
			})
			.catch(err => {
				track("google_auth_error", err);
				onAuthError();
			});
	}, []);

	const googleButtonRef = useRef();
	const googleButtonWidth = googleButtonRef?.current?.offsetWidth;

	useEffect(() => {
		if (googleClientId) {
			// We check every 300ms to see if google client is loaded
			const interval = setInterval(() => {
				if (window?.google?.accounts?.id) {
					clearInterval(interval);

					forceUpdate();

					// https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
					window.google.accounts.id.initialize({
						client_id: googleClientId,
						callback: onGoogleAuthSuccess, // Handler to process login token
						ux_mode: "popup",
						context: uxMode,
						cancel_on_tap_outside: true,
					});

					// Render the Google button
					window.google.accounts.id.renderButton(
						document.getElementById("google-login-btn"),
						{
							type: "standard",
							theme: "outline",
							size: "large",
							text: "continue_with",
							shape: "rectangular",
							locale: locale,
							logo_alignment: "left",
							click_listener: onClick,
							width: googleButtonWidth,
						}
					);

					if (isDesktop) {
						window.google.accounts.id.prompt();
					}
				}
			}, 300);
		}

		return () => {
			if (window?.google?.accounts?.id) {
				window.google.accounts.id.cancel();
			}
		};
	}, [googleClientId, isDesktop, googleButtonWidth]);

	return googleClientId ? (
		<div className="sign-in-with-google-button" ref={googleButtonRef}>
			<div id="google-login-btn" />
		</div>
	) : null;
};

SignInWithGoogleButton.propTypes = {
	source: PropTypes.string,
	googleClientId: PropTypes.string,
	uxMode: PropTypes.oneOf(["signup", "signin", "use"]),
	authWithGoogle: PropTypes.func,
	authSuccess: PropTypes.func,
	onAuthSuccess: PropTypes.func,
	onAuthError: PropTypes.func,
};

export default memo(SignInWithGoogleButton);
