import {
	BOOK,
	BOOK_EXTERNAL_PAYMENT,
	FETCH_PAYMENT_METHODS,
	UPDATE_ENCRYPTED_CARD,
	UPDATE_EXTERNAL_BOOK_STATUS,
} from "app/actionTypes";
import axios from "app/utils/http/http";
import env from "app/utils/env";
import { BOOK_STATUS } from "app/constants";
import { injectScript } from "app/utils/utils";
import { getStore } from "app/configureStore";
import { getCredentials } from "app/utils/auth";

export const fetchPaymentMethods = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_PAYMENT_METHODS,
		promise: axios.get(
			`${endpoint}/${state.productsVisibility}/resources/${shop}/paymentMethods.json`
		),
	};
};

export const book = paymentPayload => {
	const url = `${env("QUOTE_API_URL")}/book`;
	const { token } = getCredentials();
	const headers = {
		Authorization: token,
		"Content-type": "application/json",
	};
	return {
		type: BOOK,
		promise: axios.post(url, paymentPayload, { headers }),
	};
};

export const bookExternalPayment = ({ code }) => {
	const url = `${env("QUOTE_API_URL")}/bookExternalPayment`;

	return {
		type: BOOK_EXTERNAL_PAYMENT,
		promise: axios.post(
			url,
			{ code },
			{
				headers: {
					"Content-type": "application/json",
				},
			}
		),
	};
};

export const updateExternalBookStatus = bookStatus => {
	return {
		type: UPDATE_EXTERNAL_BOOK_STATUS,
		bookStatus,
	};
};

const setTimeoutPromise = delay => {
	return new Promise(resolve => {
		setTimeout(resolve, delay);
	});
};

/**
 * Recursive Promise pour la gestion des appels vers un prestataire de paiement exterieur tel que 3DS2, casino
 *
 * Tant que le statut de la response de l'API pointant vers callbackUrl n'est pas EXTERNAL_PAYMENT_STATUS_PENDING, alors on reappelle
 * recursivement cette fonction et donc l'API pointant vers callbackUrl.
 *
 * Si l'API repond RETRY, alors on reappelle l'API.
 * Si l'API repond EXTERNAL_HTML_REQUEST, alors on reinjecte htmlPayload et on attend callbackDelay avant de reappeler l'API
 *
 * @see https://medium.com/@hayavuk/perform-async-operations-sequentially-using-recursive-promises-in-javascript-c4e5ab921c37
 * @param callbackUrl
 * @param externalPaymentStatus peut valoir RETRY | EXTERNAL_HTML_REQUEST | EXTERNAL_PAYMENT_STATUS_PENDING | EXTERNAL_PAYMENT_STATUS_ERROR
 * @param callbackDelay delai avant d'appeler le callback
 * @param htmlPayload html a injecter dans le head (peut etre le script ThreatMetrix pour casino ou les iframe pour 3DS2)
 */
export const fetchExternalPaymentCallback = ({
	callbackUrl,
	externalPaymentStatus,
	callbackDelay,
	htmlPayload,
	response, // handle double redirect 3ds casino
}) => {
	if (
		externalPaymentStatus &&
		externalPaymentStatus !== BOOK_STATUS.RETRY &&
		externalPaymentStatus !== BOOK_STATUS.EXTERNAL_HTML_REQUEST
	) {
		return {
			externalPaymentStatus,
			response, // handle double redirect 3ds casino
		};
	}

	if (htmlPayload) {
		injectScript(htmlPayload);
	}

	return setTimeoutPromise(callbackDelay).then(() => {
		return (
			axios
				.get(callbackUrl)
				// eslint-disable-next-line consistent-return
				.then(response => {
					const { callbackDelay, htmlPayload, externalPaymentStatus } = response.data;
					return fetchExternalPaymentCallback({
						externalPaymentStatus,
						callbackDelay,
						callbackUrl,
						htmlPayload,
						response, // handle double redirect 3ds casino
					});
				})
		);
	});
};

export const updateEncryptedCard = encryptedCard => {
	return {
		type: UPDATE_ENCRYPTED_CARD,
		encryptedCard,
	};
};
