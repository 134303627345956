import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { merchandisingMediaShape, resolutionPropType } from "app/utils/propTypes";
import MediaCarousel from "app/pages/Merchandising/Media/MediaCarousel";
import "./MerchandisingMedias.scss";
import MediaArticle from "app/pages/Merchandising/Media/MediaArticle";

const MerchandisingMedias = ({ articles = [], resolution }) => {
	const firstArticleTitle = articles[0]?.title;

	const [selectedMediaTitle, setSelectedMediaTitle] = useState(firstArticleTitle);

	const selectedMedia = articles.find(article => article.title === selectedMediaTitle);

	const carouselRef = useRef();

	return (
		<div className="merchandising-medias">
			{selectedMedia && (
				<>
					<MediaArticle
						title={selectedMedia.title}
						descriptions={selectedMedia.description}
					/>
				</>
			)}
			<div className="merchandising-medias__caroussel">
				<MediaCarousel
					ref={carouselRef}
					mediaList={articles}
					resolution={resolution}
					setSelectedMediaTitle={setSelectedMediaTitle}
				/>
			</div>
		</div>
	);
};

MerchandisingMedias.propTypes = {
	articles: PropTypes.arrayOf(merchandisingMediaShape),
	resolution: resolutionPropType,
};

export default MerchandisingMedias;
