import { createSelector } from "reselect";
import uniq from "lodash/uniq";
import compact from "lodash/compact";
import orderBy from "lodash/orderBy";
import take from "lodash/take";
import get from "lodash/get";
import {
	calculateSDPBasePrice,
	getActiveSort,
	getOngoingSDPProducts,
	getSDPFlights,
	getSDPProductsPageSize,
	getSortUpdated,
} from "app/pages/SmartDP/smartDPSelectors";
import { FacetedClassification, FacetedQuery } from "immfacet";
import { fromJS } from "immutable";
import {
	DESTINATION_LEVELS,
	RATING_TYPE,
	SDP_PRODUCTS_FILTERS_KEY,
	PRICING_SUPPLEMENT,
	QUOTATION_ITEMS_TYPE,
} from "app/constants";
import forOwn from "lodash/forOwn";
import minBy from "lodash/minBy";
import {
	getDestinationResort,
	getFlightUpgrade,
	getLuggageTotalPrice,
	getTravellersTotalCount,
} from "app/pages/Booking/bookingSelectors";
import { buildSDPBudgetFilterValues, getAccommodationPrice } from "app/utils/smartDPUtils";
import range from "lodash/range";
import { updateSort } from "app/pages/SmartDP/Listing/SmartDPSort/sortUtils";
import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import some from "lodash/some";

export const getSDPProductsFilters = state => state.smartDPFilter;
const getQuotationItems = state => state?.smartDP?.quotationItems || [];

export const getSDPProductsRating = state =>
	state?.partner?.marketing?.productRating || RATING_TYPE.TRIP_ADVISOR;

export const checkIfSdpProductsHasOneFlashsale = createSelector(
	[getOngoingSDPProducts],
	(products = []) => {
		return products.some(product => product.isFlashsale);
	}
);

export const getOngoingSDPProductsNormalize = createSelector(
	[
		getSDPProductsRating,
		getOngoingSDPProducts,
		calculateSDPBasePrice,
		getTravellersTotalCount,
		getFlightUpgrade,
		getLuggageTotalPrice,
	],
	(
		productRating,
		products = [],
		basePrice = 0,
		travellersTotalCount,
		flightUpgradePrice = {},
		luggageUpgradePrice = 0
	) => {
		const budgetFilterValues = buildSDPBudgetFilterValues(
			products,
			basePrice,
			travellersTotalCount
		);

		const normalizedProducts = products.map(product => {
			if (productRating === RATING_TYPE.TRIP_ADVISOR) {
				/**
				 * astuce pour pouvoir filtrer par note trip advisor 3.5+, 4+, 4,5+ et 5
				 * Il fallait donc à partir de la note trip advisor constituer un tableau de notes pour que immfacet puisse l'exploiter
				 */
				const tripadvisor = get(product, "tripadvisor", {});

				// only for immfacet to be able to filter on rating +
				// 3.5 => [3.5]
				// 4 => [3.5, 4]
				// 4.5 => [3.5, 4, 4.5]
				// 5 => [3.5, 4, 4.5, 5]
				if (tripadvisor.rating >= 3.5) {
					const ratingFilterValues = range(3.5, tripadvisor.rating + 0.5, 0.5);
					product.tripadvisor = {
						...tripadvisor,
						ratingFilterValues,
					};
				}
			}

			if (productRating === RATING_TYPE.HOLIDAY_CHECK) {
				const holidaycheck = get(product, "holidaycheck", {});
				let ratingFilterValues = [];
				if (holidaycheck.recommendation >= 90) {
					ratingFilterValues = [70, 80, 90];
				} else if (holidaycheck.recommendation >= 80) {
					ratingFilterValues = [70, 80];
				} else {
					ratingFilterValues = [70];
				}
				product.holidaycheck = {
					...holidaycheck,
					ratingFilterValues,
				};
			}

			product.total = getAccommodationPrice(
				product,
				basePrice,
				travellersTotalCount,
				flightUpgradePrice?.upgradePrice,
				luggageUpgradePrice
			);
			product.budgetMinValue = get(
				budgetFilterValues.find(
					budget =>
						(product.total >= budget.min && product.total < budget.max) ||
						(product.total >= budget.min && !budget.max)
				),
				"min"
			);

			const levels = product.geography?.levels;
			if (
				levels?.length > 0 &&
				!some(levels, level => {
					return level.level === DESTINATION_LEVELS.DISTRICT;
				})
			) {
				levels.push({ level: DESTINATION_LEVELS.OTHER, label: DESTINATION_LEVELS.OTHER });
			}

			return product;
		});
		return normalizedProducts;
	}
);

export const getSDPBudgetFilterValues = createSelector(
	[getOngoingSDPProductsNormalize, calculateSDPBasePrice, getTravellersTotalCount],
	(products = [], basePrice = 0, travellersTotalCount) => {
		return buildSDPBudgetFilterValues(products, basePrice, travellersTotalCount);
	}
);

export const getSDPDestinationFilterValues = createSelector(
	[getOngoingSDPProducts, getDestinationResort],
	(products = [], selectedDestinationResort) => {
		if (!products || products.length === 0) {
			return [];
		}

		const selectedDestinationType = selectedDestinationResort?.type;

		let computedProducts = products;

		if (selectedDestinationType === DESTINATION_LEVELS.RESORT) {
			computedProducts = products.map(product => {
				const levels = product.geography?.levels;
				if (
					levels?.length > 0 &&
					!some(levels, level => {
						return level.level === DESTINATION_LEVELS.DISTRICT;
					})
				) {
					levels.push({
						level: DESTINATION_LEVELS.OTHER,
						label: DESTINATION_LEVELS.OTHER,
					});
				}

				return product;
			});
		}

		const labels = computedProducts.map(product => {
			const levels = product.geography?.levels;

			let filteredLevels = [];

			if (levels?.length > 0) {
				if (selectedDestinationType === DESTINATION_LEVELS.ZONE) {
					filteredLevels = levels.filter(level => {
						return level.level === DESTINATION_LEVELS.REGION;
					});
				} else if (selectedDestinationType === DESTINATION_LEVELS.COUNTRY) {
					filteredLevels = levels.filter(level => {
						return level.level === DESTINATION_LEVELS.RESORT;
					});
				} else if (selectedDestinationType === DESTINATION_LEVELS.REGION) {
					filteredLevels = levels.filter(level => {
						return level.level === DESTINATION_LEVELS.RESORT;
					});
				} else if (selectedDestinationType === DESTINATION_LEVELS.RESORT) {
					filteredLevels = levels.filter(level => {
						return (
							(level.level === DESTINATION_LEVELS.DISTRICT ||
								level.level === DESTINATION_LEVELS.OTHER) &&
							level.level !== DESTINATION_LEVELS.RESORT
						);
					});
				}
			}

			return filteredLevels;
		});

		return uniqBy(flatten(labels), "label");
	}
);

export const getSDPCategoriesFilterValues = createSelector(
	[getOngoingSDPProducts],
	(products = []) => {
		if (!products || products.length === 0) {
			return [];
		}

		const categories = products.map(product => {
			return Math.floor(Number(product.category));
		});

		categories.sort().reverse();

		return compact(uniq(categories));
	}
);

export const getSDPProductRatingFilterValues = createSelector(
	[getSDPProductsRating, getOngoingSDPProductsNormalize],
	(productRating, products = []) => {
		if (!products || products.length === 0) {
			return [];
		}
		let rating = [];
		if (productRating === RATING_TYPE.TRIP_ADVISOR) {
			rating = products
				.filter(product => {
					const rating = get(product, "tripadvisor.rating");
					return rating === 3.5 || rating === 4 || rating === 4.5 || rating === 5;
				})
				.map(product => {
					return get(product, "tripadvisor.rating");
				});
		}
		if (productRating === RATING_TYPE.HOLIDAY_CHECK) {
			rating = [70, 80, 90];
		}
		rating.sort().reverse();
		return compact(uniq(rating));
	}
);

export const getSDPProductsFacetQuery = createSelector(
	[getSDPProductsRating, getOngoingSDPProductsNormalize],
	(productRating, products = []) => {
		let facetCollection = new FacetedClassification(fromJS(products));

		facetCollection = facetCollection
			.addFacet(SDP_PRODUCTS_FILTERS_KEY.BUDGET, product => {
				if (!product.get("budgetMinValue") && product.get("budgetMinValue") !== 0) {
					return undefined;
				}

				return Number(product.get("budgetMinValue"));
			})
			.addFacet(SDP_PRODUCTS_FILTERS_KEY.STARS, product => {
				if (!product.get("category") && !product.get("productUrl")) {
					return undefined;
				}
				return Math.floor(Number(product.get("category")));
			})
			.addFacet(
				SDP_PRODUCTS_FILTERS_KEY.GUESTS_RATING,
				product => {
					let ratingFilterValues = [];
					if (productRating === RATING_TYPE.TRIP_ADVISOR) {
						ratingFilterValues =
							product.get("tripadvisor") &&
							product.get("tripadvisor").toJS().ratingFilterValues;
					}
					if (productRating === RATING_TYPE.HOLIDAY_CHECK) {
						ratingFilterValues =
							product.get("holidaycheck") &&
							product.get("holidaycheck").toJS().ratingFilterValues;
					}
					if (!ratingFilterValues || ratingFilterValues?.length === 0) {
						return undefined;
					}
					return ratingFilterValues.map(rating => String(rating));
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				SDP_PRODUCTS_FILTERS_KEY.BOARDS,
				product => {
					if (!product.get("accommodationItems")) {
						return undefined;
					}

					const accommodationItems = product.get("accommodationItems").toJS() || [];
					// TODO delete .codes which is replaced by .boards
					const boards = accommodationItems[0]?.boards || accommodationItems[0]?.codes;

					return boards.map(board => {
						return String(board.boardTypeCode);
					});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(
				SDP_PRODUCTS_FILTERS_KEY.DESTINATIONS,
				product => {
					if (!product.get("accommodationItems")) {
						return undefined;
					}

					const geography = product.get("geography")?.toJS() || [];
					const levels = geography?.levels;

					return levels?.map(level => {
						return String(level.label);
					});
				},
				{
					multiValue: true,
				}
			)
			.addFacet(SDP_PRODUCTS_FILTERS_KEY.FLASH_SALE, product => {
				return product.get("isFlashsale") ? "true" : "false";
			});

		return new FacetedQuery(facetCollection);
	}
);

export const getSDPProductsFacetQueryWithFilters = createSelector(
	[getSDPProductsFacetQuery, getSDPProductsFilters],
	(productsFacetQuery = [], productsFilters = {}) => {
		let facetQuery = productsFacetQuery;

		forOwn(productsFilters, (value, key) => {
			// on doit gérer les valeurs en string et boolean (pour startAt et endAt)
			if ((value && value.length > 0) || (value && typeof value === "boolean")) {
				facetQuery = facetQuery.select({
					name: key,
					values:
						typeof value === "string" || typeof value === "boolean" ? [value] : value,
				});
			}
		});

		// console.log(
		// 	"getSDPProductsFacetQueryWithFilters -> selectedFacets",
		// 	facetQuery.selectedFacets().toJS()
		// );
		// console.log(
		// 	"getSDPProductsFacetQueryWithFilters -> selectedFacetValues",
		// 	facetQuery.selectedFacetValues().toJS()
		// );
		// console.log(
		// 	"getSDPProductsFacetQueryWithFilters -> selectedItems",
		// 	facetQuery.selectedItems().toJS()
		// );

		return facetQuery;
	}
);

export const checkIfAnyFilterIsApplied = createSelector(
	[getSDPProductsFilters],
	productsFilters => {
		return (
			get(productsFilters, "stars.length") > 0 ||
			get(productsFilters, "boards.length") > 0 ||
			get(productsFilters, "budget.length") > 0 ||
			get(productsFilters, "flashsale.length") > 0 ||
			get(productsFilters, "destinations.length") > 0 ||
			Boolean(productsFilters.guestsrating)
		);
	}
);

const calculatePerTravellerPrice = (price = 0, travellersTotalCount) =>
	price > 0 ? Math.ceil(price / travellersTotalCount) : 0;

export const getVisibleSDPProducts = createSelector(
	[
		getSDPProductsFacetQueryWithFilters,
		calculateSDPBasePrice,
		getSDPProductsFilters,
		checkIfAnyFilterIsApplied,
		getTravellersTotalCount,
		getSDPFlights,
		getFlightUpgrade,
		getLuggageTotalPrice,
		getQuotationItems,
	],
	(
		facetQuery = {},
		basePrice = 0,
		productsFilters,
		isAnyFilterIsApplied,
		travellersTotalCount,
		flights = [],
		flightUpgradePrice = {},
		luggageUpgradePrice = 0,
		quotationItems = []
	) => {
		let accommodations = facetQuery.selectedItems().toJS() || [];
		accommodations = accommodations.map(accommodation => {
			const boardDescriptions = accommodation.boards;
			let accommodationToDisplay;
			let boardToDisplay;

			if (accommodation.accommodationItems) {
				const hasBoardFilterApplied =
					productsFilters.boards && productsFilters.boards.length > 0;

				if (!hasBoardFilterApplied) {
					// we display the cheapest room (assume it is the first one in the list)
					accommodationToDisplay = accommodation.accommodationItems[0];

					// the board to display is the cheapest board of the cheapest room
					// TODO delete .codes which is replaced by .boards
					const boards = accommodationToDisplay.boards || accommodationToDisplay.codes;
					boardToDisplay = boards[0] || {};
				} else {
					// here we have on or several boards to filter

					// the board to display is the cheapest one among the accommodation items
					// which has the boards type to filter
					let accommodationWithFilteredBoard = accommodation.accommodationItems.map(
						accommodationItem => {
							// TODO delete .codes which is replaced by .boards
							const boards = accommodationItem.boards || accommodationItem.codes;

							// eslint-disable-next-line max-nested-callbacks
							const boardsWithFilteredBoardType = boards.filter(board =>
								productsFilters.boards.includes(board.boardTypeCode)
							);

							if (boardsWithFilteredBoardType) {
								return {
									...accommodationItem,
									cheapestBoard: minBy(
										boardsWithFilteredBoardType,
										"upgradePrice"
									),
								};
							}

							return undefined;
						}
					);

					accommodationWithFilteredBoard = compact(accommodationWithFilteredBoard);

					accommodationToDisplay = minBy(
						accommodationWithFilteredBoard,
						"cheapestBoard.upgradePrice"
					);

					boardToDisplay = accommodationToDisplay.cheapestBoard || {};
				}

				// get the accommodation to display label
				const accommodationItemDescription = accommodation.accommodationItemDescriptions.find(
					description =>
						description.accommodationItemCode ===
						accommodationToDisplay.items[0].accommodationItemCode
				);

				if (accommodationItemDescription) {
					accommodation.accommodationLabel = accommodationItemDescription.label;
				}

				// get cheapeast board code to put in the url /sdp/booking/quote
				// for the payload of sdp/accommodationQuote
				accommodation.boardCode = boardToDisplay.boardCode;

				// get the board to display label
				const boardDescription = boardDescriptions.find(
					board => board.boardCode === boardToDisplay.boardCode
				);

				if (boardDescription) {
					accommodation.boardLabel = boardDescription.label;
				}

				const accommodationUpgradePricePerTraveller = calculatePerTravellerPrice(
					accommodationToDisplay?.upgradePrice,
					travellersTotalCount
				);

				const boardUpgradePricePerTraveller = calculatePerTravellerPrice(
					boardToDisplay?.upgradePrice,
					travellersTotalCount
				);

				const flightUpgradePricePerTraveller = calculatePerTravellerPrice(
					flightUpgradePrice?.upgradePrice,
					travellersTotalCount
				);

				const luggageUpgradePricePerTraveller = calculatePerTravellerPrice(
					luggageUpgradePrice,
					travellersTotalCount
				);

				const basePricePerTraveller =
					calculatePerTravellerPrice(basePrice, travellersTotalCount) +
					accommodationUpgradePricePerTraveller +
					boardUpgradePricePerTraveller;

				accommodation.total =
					basePricePerTraveller +
					flightUpgradePricePerTraveller +
					luggageUpgradePricePerTraveller;
				accommodation.basePrice = {
					label:
						quotationItems.find(item => item?.type === QUOTATION_ITEMS_TYPE.TRIP)
							?.description || "",
					amount: basePricePerTraveller,
				};

				accommodation.upgradePrice = Object.entries({
					...(flightUpgradePricePerTraveller > 0 && {
						[PRICING_SUPPLEMENT.FLIGHT_SUPPLEMENT]: flightUpgradePricePerTraveller,
					}),
					...(luggageUpgradePricePerTraveller > 0 && {
						[PRICING_SUPPLEMENT.BAGGAGE_SUPPLEMENT]: luggageUpgradePricePerTraveller,
					}),
				}).map(([label, amount]) => ({
					label,
					amount,
				}));
			}

			const flightDetails = flights.find(flight => flight.code === accommodation.flightCode);

			if (flightDetails) {
				accommodation.flightDetails = flightDetails;
			}

			return accommodation;
		});

		if (isAnyFilterIsApplied) {
			accommodations = accommodations.filter(accommodation => !accommodation.productUrl);
		}

		return isAnyFilterIsApplied ? orderBy(accommodations, "total", "asc") : accommodations;
	}
);

export const getSortedSDPProducts = createSelector(
	[getVisibleSDPProducts, getActiveSort, getSortUpdated],
	(visibleSDPProducts, activeSort, sortUpdated) => {
		return sortUpdated && activeSort
			? updateSort({ products: visibleSDPProducts, activeSort })
			: visibleSDPProducts;
	}
);

export const getPaginatedVisibleSDPProducts = createSelector(
	[getSortedSDPProducts, getSDPProductsPageSize],
	(sortedSDPProducts, productsPageSize) => {
		return take(sortedSDPProducts, productsPageSize);
	}
);

export const getBoardTypesDescriptions = state =>
	state.smartDP.boardTypeDescriptions.map(boardType => ({
		...boardType,
		boardTypeCode: boardType.code,
	}));
