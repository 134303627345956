import Modal from "react-modal";
import PropTypes from "prop-types";
import "./AuthModal.scss";
import IconAtolProtected from "app/pages/.shared/static/icons/IconAtolProtected";
import IconABTACertification from "app/pages/.shared/BookWithConfidence/IconABTACertification";
import AuthLayerContainer from "app/pages/Auth/AuthLayer/AuthLayerContainer";
import BackgroundGradient from "app/pages/.shared/BackgroundGradient/BackgroundGradient";
import { BG_GRADIENT_ANIMATION } from "app/constants";
import IconCrossWhite from "app/pages/.shared/static/icons/IconCrossWhite";
import HeaderContainer from "app/pages/Header/HeaderContainer";

const style = {
	overlay: {
		backgroundColor: "rgba(43, 56, 63, .9)",
		zIndex: 10,
		overflow: "auto",
	},
	content: {
		position: "static",
		right: "auto",
		bottom: "auto",
		border: "none",
		background: "none",
		padding: 0,
		overflow: "hidden",
	},
};

const IS_DEFAULT_SIGN_IN = false;

const AuthModal = ({
	onAuthSuccess,
	showAtolAbta,
	onClose,
	signinMarketingBlock,
	signupMarketingBlock,
	...props
}) => {
	return (
		<Modal
			style={style}
			{...props}
			className="auth-modal"
			onRequestClose={onClose}
			shouldCloseOnOverlayClick={true}
		>
			<div className="auth-modal__header">
				<HeaderContainer />
			</div>
			<div className="auth-modal__content">
				<div className="auth-modal__hero-imBage">
					<BackgroundGradient animation={BG_GRADIENT_ANIMATION.NONE} />
				</div>

				<div className="auth-modal__auth-layer">
					<AuthLayerContainer
						isDefaultSignin={IS_DEFAULT_SIGN_IN}
						onAuthSuccess={onAuthSuccess}
						signinMarketingBlock={signinMarketingBlock}
						signupMarketingBlock={signupMarketingBlock}
					/>
				</div>

				{showAtolAbta && (
					<div className="auth-modal__certifications">
						<IconAtolProtected height={40} width={40} />
						<IconABTACertification height={40} width={70} noColor={true} />
					</div>
				)}
				{onClose && (
					<div className="auth-modal__close-icon" onClick={onClose}>
						<IconCrossWhite />
					</div>
				)}
			</div>
		</Modal>
	);
};

AuthModal.propTypes = {
	onAuthSuccess: PropTypes.func.isRequired,
	showAtolAbta: PropTypes.bool,
	onClose: PropTypes.func,
	signupMarketingBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	signinMarketingBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default AuthModal;
