import { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./HomeLayout.scss";
import { sendTagOnSDPSearchPage } from "app/utils/analytics";
import { DynamicBlocksRendererContainer } from "app/pages/.shared/DynamicBlocks";
import { PushNotificationContainer } from "app/pages/.shared/PushNotification/PushNotificationContainer";
import SmartDPMarketingHeroImageContainer from "app/pages/SmartDP/SmartDPMarketingHeroImage/SmartDPMarketingHeroImageContainer";
import SmartDPMarketingHeadlineContainer from "app/pages/SmartDP/SmartDPMarketingHeadline/SmartDPMarketingHeadlineContainer";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import classNames from "classnames";
import SmartDPSearchHeadMetadata from "app/pages/SmartDP/Search/SmartDPSearchHeadMetadata";
import { AsyncWebsiteTunnelSwitcher } from "app/routes";

const HomeLayout = ({
	websiteTunnels = [],
	homeBlocks = [],
	isBrandWithCustomHeader,
	isTransparentHeader,
}) => {
	const smartDPSearchRef = useRef(null);

	useEffect(() => {
		sendTagOnSDPSearchPage();
	}, []);

	const containerClass = classNames({
		"home-layout__container": true,
		"home-layout__container--custom-header": isBrandWithCustomHeader || !isTransparentHeader,
	});

	return (
		<div ref={smartDPSearchRef} className="home-layout">
			<SmartDPSearchHeadMetadata />
			<HeaderContainer />
			<div className={containerClass}>
				<div className="home-layout__image">
					<div className="home-layout__image-background">
						<SmartDPMarketingHeroImageContainer />
					</div>
					<div className="home-layout__image-headline">
						<SmartDPMarketingHeadlineContainer />
						<div className="home-layout__form">
							<AsyncWebsiteTunnelSwitcher websiteTunnels={websiteTunnels} />
						</div>
					</div>
				</div>
				<div className="home-layout__content">
					<div className="home-layout__dynamic-blocks">
						<DynamicBlocksRendererContainer blocks={homeBlocks} />
					</div>
				</div>
			</div>
			<PushNotificationContainer />
		</div>
	);
};

HomeLayout.propTypes = {
	websiteTunnels: PropTypes.array,
	homeBlocks: PropTypes.array,
	isBrandWithCustomHeader: PropTypes.bool,
	isTransparentHeader: PropTypes.bool,
};

export default memo(HomeLayout);
