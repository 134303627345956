export const FETCH_LANDING_PAGES = "FETCH_LANDING_PAGES";
export const FETCH_LANDING_PAGES_REQUEST = "FETCH_LANDING_PAGES_REQUEST";
export const FETCH_LANDING_PAGES_SUCCESS = "FETCH_LANDING_PAGES_SUCCESS";
export const FETCH_LANDING_PAGES_FAILURE = "FETCH_LANDING_PAGES_FAILURE";
export const FETCH_LANDING_PAGE = "FETCH_LANDING_PAGE";
export const FETCH_LANDING_PAGE_REQUEST = "FETCH_LANDING_PAGE_REQUEST";
export const FETCH_LANDING_PAGE_SUCCESS = "FETCH_LANDING_PAGE_SUCCESS";
export const FETCH_LANDING_PAGE_FAILURE = "FETCH_LANDING_PAGE_FAILURE";
export const SET_LANDING_PAGE_PRODUCTS_FILTER_BY = "SET_LANDING_PAGE_PRODUCTS_FILTER_BY";
export const UNSET_LANDING_PAGE_PRODUCTS_FILTER_BY = "UNSET_LANDING_PAGE_PRODUCTS_FILTER_BY";
export const UPDATE_CURRENT_LANDING_PAGE_CODE = "UPDATE_CURRENT_LANDING_PAGE_CODE";
