import { memo } from "react";
import PropTypes from "prop-types";
import className from "classnames";
import IconCircleTick from "app/pages/.shared/static/icons/IconCircleTick";
import { PASSWORD_POLICY_REGEX } from "app/constants";
import { FormattedMessage } from "react-intl";

import "./PasswordPolicyValidation.scss";

const passwordPolicyLabel = {
	LIMIT_CHARS: { id: "auth.password.policy.atleast.ten.chars" },
	UPPERCASE: { id: "auth.password.policy.atleast.one.uppercase" },
	DIGIT: { id: "auth.password.policy.atleast.one.digit" },
	SPECIAL_CHAR: { id: "auth.password.policy.atleast.one.special.char" },
};

const PasswordPolicyValidation = ({ value }) => {
	const hasValue = value && value.length > 0;

	return (
		<div
			className={className("password-policy__list", {
				"password-policy__list--visible": hasValue,
			})}
		>
			<div className="password-policy__intro">
				<FormattedMessage id="auth.password.policy.label" />
			</div>

			{hasValue &&
				Object.keys(PASSWORD_POLICY_REGEX).map(key => (
					<div
						className="password-policy__item"
						key={key}
						data-testid="password-policy-item"
					>
						<span className="password-policy__icon">
							{value && value.length > 0 && PASSWORD_POLICY_REGEX[key].test(value) ? (
								<IconCircleTick
									className="password-policy__icon--valid"
									data-testid={`${key}-valid`}
								/>
							) : (
								<i
									className="icon icon--info-red password-policy__icon--invalid"
									data-testid={`${key}-invalid`}
								/>
							)}
						</span>
						<span className="password-policy__label">
							<FormattedMessage {...passwordPolicyLabel[key]} />
						</span>
					</div>
				))}
		</div>
	);
};

PasswordPolicyValidation.propTypes = {
	value: PropTypes.string,
};

export default memo(PasswordPolicyValidation);
