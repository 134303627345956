import { Form, Formik } from "formik";
import { object, string, number } from "yup";
import Button from "app/pages/.shared/form/Button";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import InputFormik from "app/pages/.shared/form/InputFormik";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { ALERT_TYPE } from "app/constants";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import "./search-booking.scss";
import { isEmpty } from "lodash";
import FloatingButton from "app/pages/.shared/Popover/FloatingButton";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const validationSchema = object().shape({
	bookingReference: number().required({ id: "error.booking.reference.required.error" }),
	email: string()
		.required({ id: "error.booking.email.required.error" })
		.email({ id: "error.email.format" }),
});

const SearchBookingForm = ({
	handleSubmit = () => {},
	disabledRecoverData = false,
	onClickRecover = () => {},
}) => {
	return (
		<div className="search-booking-form">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL2}
				isBold
				className="search-booking-form__title"
				component="h1"
			>
				<FormattedMessage id="corporate.search.booking.form.title" />
			</Typography>
			<div className="search-booking-form__title-separator" />

			<Formik
				initialValues={{
					"search-booking-reference": undefined,
					"search-booking-email": undefined,
				}}
				onSubmit={handleSubmit}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={validationSchema}
			>
				{({ errors = {}, isSubmitting, setStatus, submitCount, setFieldError }) => (
					<Form
						noValidate
						className="search-booking-form__form"
						onChange={event => {
							setStatus({});
							if (!isEmpty(errors)) {
								setFieldError(event?.target?.name);
							}
						}}
					>
						<FormErrorMessages
							errors={errors}
							isSubmitting={isSubmitting}
							submitCount={submitCount}
						/>

						<div className="search-booking-form__input">
							<InputFormik
								id="bookingReference"
								name="bookingReference"
								label={
									<FormattedMessage id="corporate.search.booking.form.reference.placeholder" />
								}
								data-testid="booking-reference-input"
								maxLength={7}
								type="number"
							/>
							<div className="search-booking-form__info-picto">
								<FloatingButton
									popoverClassName="search-booking-form__popover"
									position="top"
									floatingContent={
										<div className="search-booking-form__help">
											<FormattedMessage
												id="corporate.search.booking.form.reference.help.text"
												// eslint-disable-next-line react/no-multi-comp
												values={{ div: chunks => <div>{chunks}</div> }}
											/>
										</div>
									}
									referenceComponent={<i className="icon icon--info-white" />}
									floatingOffsetMobile={[-11, 0]}
								/>
							</div>
						</div>
						<div className="search-booking-form__input">
							<InputFormik
								id="email"
								name="email"
								label={
									<FormattedMessage id="corporate.search.booking.form.email.placeholder" />
								}
								type="email"
								data-testid="email-input"
							/>
							<div className="search-booking-form__info-picto">
								<FloatingButton
									popoverClassName="search-booking-form__popover"
									position="top"
									floatingContent={
										<div className="search-booking-form__help">
											<FormattedMessage id="corporate.search.booking.form.email.help.text" />
										</div>
									}
									referenceComponent={<i className="icon icon--info-white" />}
									floatingOffsetMobile={[-11, 0]}
								/>
							</div>
						</div>

						{!disabledRecoverData && (
							<div
								className="search-booking-form__link"
								onClick={onClickRecover}
								data-testid="missing-info"
							>
								<FormattedMessage id="corporate.search.booking.form.data.lost.label" />
							</div>
						)}

						{errors._error && (
							<AlertMessage
								alertType={ALERT_TYPE.ERROR}
								message={
									Array.isArray(errors._error) ? (
										<FormattedMessage id="error.sponsorship.friends.email.invalid" />
									) : (
										<FormattedMessage {...errors._error} />
									)
								}
								{...Array.isArray(errors._error) && {
									errors: errors._error.map((error, index) => (
										<FormattedMessage key={index} {...error} />
									)),
								}}
							/>
						)}

						<div className="search-booking-form__button">
							<Button
								submit
								variant="primary"
								loading={isSubmitting}
								data-testid="search-button"
							>
								<span style={{ textTransform: "uppercase" }}>
									<FormattedMessage id="corporate.search.booking.form.button.text" />
								</span>
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

SearchBookingForm.propTypes = {
	handleSubmit: PropTypes.func,
	onClickRecover: PropTypes.func,
	disabledRecoverData: PropTypes.bool,
};

export default SearchBookingForm;
