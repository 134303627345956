import { getStore } from "app/configureStore";
import {
	checkIfSDPPages,
	mapFicheProduitStoreToTag,
	mapPageStoreToTag,
	mapPaymentStoreToTag,
	mapPaymentTypesStoreToTag,
	mapPrePackageStoreToTag,
	mapProductToTag,
	mapQuoteStoreToTag,
	mapUserStoreToTag,
} from "app/utils/analytics-store-mapper";
import axios from "axios";
import Cookies from "js-cookie";
import { getUTCDateForAnalytics, isServerSide } from "app/utils/utils";
import get from "lodash/get";
import last from "lodash/last";
import { IS_PRODUCTION, SDP_ROTATION_DATE_FORMAT } from "app/constants";
import { format } from "date-fns";
import { datadogRum } from "@datadog/browser-rum";

const dataLayer = typeof window !== "undefined" ? window?.dataLayer : [];

export const sendDataToAnalyticsEndPoint = ({ env, data }) => {
	// @see https://console.aws.amazon.com/s3/buckets/datalayer.perfectstay.io pour voir les infos envoyés (refrech toutes les 10 minutes)

	data._ga = Cookies.get("_ga");

	const newData = {
		...data,
		originalEvent: data.event,
	};

	const analyticsEndPoint = env?.includes("live")
		? "https://x6pc9ierpi.execute-api.eu-west-1.amazonaws.com/live"
		: "https://datalayer.test.analytics.perfectstay.io";

	if (IS_PRODUCTION) {
		axios
			.post(analyticsEndPoint, JSON.stringify(data), {
				headers: { "Content-type": "text/plain" },
			})
			.catch(() => {
				newData.event = "send_tag_big_query_error";
				if (dataLayer) {
					dataLayer.push(newData);
				}
			});
	}
};

const sendTag = data => {
	const state = getStore().getState();
	const pathname =
		!isServerSide && process.env.NODE_ENV !== "test" ? window?.location?.pathname : "";
	const isSdp = checkIfSDPPages(state, pathname);

	const envVars = state.envVars;
	const env = envVars?.ENVIRONMENT;

	const tagData = {
		...data,
		brand: state.brand.code,
		shop: state.shop,
		env,
		user: mapUserStoreToTag(state),
		page: mapPageStoreToTag({ pathname, state }),
		partnerCode: get(state, "partner.code", ""),
		hotjarSessionId: get(window, "_hjSettings.hjid", ""), // id de session de recording hotjar
		isSdp: `${isSdp}`,
		isSmartDP: state.quotation?.isSmartDP,
	};

	if (dataLayer) {
		dataLayer.push(tagData);
	}

	sendDataToAnalyticsEndPoint({ data: tagData, env });

	if (env?.includes("live")) {
		const { event, ...restTagData } = tagData;

		datadogRum.addAction(event, {
			...restTagData,
		});
	}
};

export const sendTagOnUnmatchedResolution = (clientResolution, serverResolution) => {
	sendTag({
		event: "unmatched_server_client_resolution",
		clientResolution,
		serverResolution,
	});
};

export const sendTagOnEmailSignin = () => {
	sendTag({
		event: "email_signin",
	});
};

export const sendTagOnEmailSigninError = ({ status, error }) => {
	sendTag({
		event: "email_signin_error",
		status,
		error,
	});
};

export const sendTagOnEmailSignup = () => {
	const state = Object.assign({}, getStore().getState(), { type: "member" }); // variable poussée une seule fois après un signup réussi
	sendTag({
		event: "email_signup",
		user: mapUserStoreToTag(state),
	});
};

export const sendTagOnEmailSignupError = ({ status, error }) => {
	sendTag({
		event: "email_signup_error",
		status,
		error,
	});
};

export const sendTagOnFacebookSignin = () => {
	sendTag({
		event: "facebook_signin",
	});
};

export const sendTagOnFacebookSignup = () => {
	sendTag({
		event: "facebook_signup",
	});
};

export const sendTagOnFacebookReauthorize = () => {
	sendTag({
		event: "facebook_reauthorize",
	});
};

export const sendTagOnFacebookReAskDeclinedPermissions = () => {
	sendTag({
		event: "facebook_reask_declined_permissions",
	});
};

export const sendTagOnFacebookLogout = () => {
	sendTag({
		event: "facebook_logout",
	});
};

export const sendTagOnFacebookCancel = () => {
	sendTag({
		event: "facebook_cancel_or_not_fully_unauthorized",
	});
};

export const sendTagOnFacebookError = error => {
	sendTag({
		event: "facebook_error",
		error,
	});
};

export const sendTagOnListingLoad = (products = []) => {
	sendTag({
		event: "product_list",
		list: "PRODUCT_LIST",
		products: products
			.map(mapProductToTag)
			.map((product, position) => ({ ...product, position })),
	});
};

export const sendTagOnHomePageLoad = () => {
	sendTag({
		event: "homePage",
	});
};

export const sendTagOnMerchandisingButtonClick = (merchandisingCode, position, indexCarousel) => {
	sendTag({
		event: `merchandising_click`,
		code: merchandisingCode,
		position: String(position),
		indexCarousel: String(indexCarousel),
		merchCode: merchandisingCode,
	});
};

export const sendTagOnMerchandisingSwitch = merchandisingCode => {
	sendTag({
		event: `merchandising_switch`,
		code: merchandisingCode,
		merchCode: merchandisingCode,
	});
};

export const pushTagOnProductViewButtonClick = (product, position) => {
	const state = getStore().getState();

	sendTag({
		event: "view_offer",
		product: mapProductToTag(product, position),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnProductPageLoad = () => {
	const state = getStore().getState();

	sendTag({
		event: "product_page",
		product: mapFicheProduitStoreToTag(getStore().getState().ficheProduit),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnJeuConcoursPageLoad = label => {
	sendTag({
		event: "jeu_concours_page",
		label,
	});
};

export const pushTagOnOfferTypeSelect = offerType => {
	const state = getStore().getState();
	sendTag({
		event: "select_offer",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		offerType,
		merchCode: state.merchandising?.current?.code,
	});
};

export const pushTagOnDepartureCitySelect = departureCityLabel => {
	const state = getStore().getState();
	sendTag({
		event: "select_flight",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		departureCity: departureCityLabel,
		merchCode: state.merchandising?.current?.code,
	});
};

export const pushTagOnAlternativeDepartureCitySelect = departureCityLabel => {
	const state = getStore().getState();
	sendTag({
		event: "select_alternative_departure_city",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		departureCity: departureCityLabel,
		merchCode: state.merchandising?.current?.code,
	});
};

export const pushTagOnPassengerSelect = () => {
	const state = getStore().getState();
	sendTag({
		event: "select_guests",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising?.current?.code,
	});
};

export const pushTagOnRentalAccommodationSelect = () => {
	const state = getStore().getState();
	sendTag({
		event: "select_rental_accommodation",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising?.current?.code,
	});
};

export const pushTagOnDepartureDateSelect = (departureDate, checkoutDate) => {
	const state = getStore().getState();
	sendTag({
		event: "select_date",
		prePackage: mapPrePackageStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		departureDate: getUTCDateForAnalytics(departureDate),
		checkoutDate: getUTCDateForAnalytics(checkoutDate),
		merchCode: state.merchandising?.current?.code,
	});
};

export const pushTagOnDurationSelect = duration => {
	const state = getStore().getState();
	sendTag({
		event: "select_duration",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		duration: String(duration),
		merchCode: state.merchandising?.current?.code,
	});
};

export const pushTagOnSearchEngineSubmit = () => {
	const state = getStore().getState();
	sendTag({
		event: "book_now",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnVoucherCodeSubmission = () => {
	const state = getStore().getState();

	sendTag({
		event: "add_voucherCode",
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnQuotationPageLoad = quotationReloadUrl => {
	const state = getStore().getState();
	sendTag({
		event: "quotation_page",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		referrer: document.referrer || "",
		quote: {
			...mapQuoteStoreToTag(state),

			quotationReloadUrl,
		},
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnQuotationResult = ({ status, data = {} }) => {
	const state = getStore().getState();

	// undefined = we don't know because we don't have endDate to compare (PP)
	// if endDate is available, hasArrivalDayShift will be true or false
	let hasArrivalDayShift = undefined;

	// endDate is defined on sdp, not for pp quote
	if (state.booking.endDate) {
		let endDateSearch = new Date(state.booking.endDate);
		endDateSearch = endDateSearch.setDate(endDateSearch.getDate() - 1);
		// because we want to compare to landingDate which is a string yyyy-MM-dd
		endDateSearch = format(new Date(endDateSearch), SDP_ROTATION_DATE_FORMAT);

		// il faut récupérer la flight included et non pas le premier flight dans la liste "flights"
		// car il y a des cas ou le flight qui est sélectionné par défaut n'est pas le premier
		const includedFlight = data.flights?.find(flight => flight.included === true);
		const lastLegOfIncludedFlight = last(includedFlight?.inbound?.legs);
		const landingDateIncludedFlight = lastLegOfIncludedFlight?.to?.landingDate; // landingDate is a string yyyy-MM-dd
		hasArrivalDayShift = landingDateIncludedFlight !== endDateSearch;
	}

	sendTag({
		event: "quotationPage_result",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		sortBy: state.smartDP?.sortedBy,
		prePackage: mapPrePackageStoreToTag(state),
		quote: {
			httpStatusCode: String(status),
			status: data.status,
			hasArrivalDayShift,
			connectivitySessionId: data.code,
			flightCount: data.flights ? String(data.flights.length) : "",
			accommodationCount:
				data.accommodations && data.accommodations.accommodationItems
					? String(data.accommodations.accommodationItems.length)
					: "",
			boardCount:
				data.accommodations && data.accommodations.boards
					? String(data.accommodations.boards.length)
					: "",
			transferCount: data.transfers ? String(data.transfers.length) : "",
			activityCount: data.activities ? String(data.activities.length) : "",
			quotePrice:
				data.quotationItems && data.quotationItems[0] && data.quotationItems[1]
					? String(data.quotationItems[0].price + data.quotationItems[1].price)
					: "",
		},
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnQuotationFail = httpStatus => {
	const state = getStore().getState();
	sendTag({
		event: "quotationPage_fail",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		httpStatus: String(httpStatus),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnQuotationFlex = ({ flex = {} }) => {
	const state = getStore().getState();
	sendTag({
		event: `quotationPage_flex_overlay`,
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: {
			status: state.quotation.status,
			flightCount: state.quotation.flights ? String(state.quotation.flights.length) : "",
			accommodationCount: state.quotation.accommodations
				? String(state.quotation.accommodations.length)
				: "",
			boardCount: state.quotation.boards ? String(state.quotation.boards.length) : "",
			transferCount: state.quotation.transfers
				? String(state.quotation.transfers.length)
				: "",
			activityCount: state.quotation.activities
				? String(state.quotation.activities.length)
				: "",
			quotePrice:
				state.quotation.quotationItems &&
				state.quotation.quotationItems[0] &&
				state.quotation.quotationItems[1]
					? String(
							state.quotation.quotationItems[0].price +
								state.quotation.quotationItems[1].price
					  )
					: "",
		},
		merchCode: state.merchandising?.current?.code,
		flex: {
			standard_price: String(flex.standard_price),
			flex_price: String(flex.flex_price),
		},
	});
};

export const sendTagOnQuotationFlexSelect = ({ flex = {} }) => {
	const state = getStore().getState();
	sendTag({
		event: `quotationPage_flex_select`,
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: {
			status: state.quotation.status,
			flightCount: state.quotation.flights ? String(state.quotation.flights.length) : "",
			accommodationCount: state.quotation.accommodations
				? String(state.quotation.accommodations.length)
				: "",
			boardCount: state.quotation.boards ? String(state.quotation.boards.length) : "",
			transferCount: state.quotation.transfers
				? String(state.quotation.transfers.length)
				: "",
			activityCount: state.quotation.activities
				? String(state.quotation.activities.length)
				: "",
			quotePrice:
				state.quotation.quotationItems &&
				state.quotation.quotationItems[0] &&
				state.quotation.quotationItems[1]
					? String(
							state.quotation.quotationItems[0].price +
								state.quotation.quotationItems[1].price
					  )
					: "",
		},
		merchCode: state.merchandising?.current?.code,
		flex: {
			standard_price: String(flex.standard_price),
			flex_price: String(flex.flex_price),
			flex_selected: String(flex.flex_selected),
		},
	});
};

export const pushTagQuotationSubmit = () => {
	const state = getStore().getState();
	sendTag({
		event: "proceed_payment",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnGoToPaymentPageClick = () => {
	const state = getStore().getState();
	sendTag({
		event: "goToPaymentPageClick",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnQuotationClick = () => {
	const state = getStore().getState();
	sendTag({
		event: "goToPaymentPageClick",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnFlightOptionsFail = () => {
	const state = getStore().getState();
	sendTag({
		event: "flightOptions_fail",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnPrebookFail = () => {
	const state = getStore().getState();
	sendTag({
		event: "prebook_fail",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: state.merchandising?.current?.code,
	});
};

export const sendTagOnPaymentInputBlur = inputName => {
	const state = getStore().getState();

	sendTag({
		event: "payment_input_blur",
		inputName,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: state.merchandising?.current?.code,
	});
};

/*
 * Cette fonction lie au tag ezakus sur toutes nos marques (AirFrance, Emirates, Transavia, American Express).
 * Ce tag est remonte lors de l'affichage de la page /payment, ce qui trigger l'envoi des emails de remarketing Bail Out.
 * remarketing Bail Out => https://docs.google.com/document/d/1PAPolrNQ37BD4sR6iaYMzpj4YxqrtIQylAZBlqAV7cA/edit#
 */
export const sendTagOnPaymentPageLoad = () => {
	const state = getStore().getState();
	const {
		ficheProduit,
		merchandising: { current },
		payment: { paymentTypes },
	} = state;

	sendTag({
		event: "payment_page",
		product: mapFicheProduitStoreToTag(ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: current?.code,
		paymentTypes: mapPaymentTypesStoreToTag(paymentTypes),
	});
};

export const pushTagOnPaymentSubmit = paymentData => {
	sendTag(buildPaymentTag(paymentData));
};

export const buildPaymentTag = paymentData => {
	const state = getStore().getState();
	return {
		event: "complete_payment",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		payment: mapPaymentStoreToTag(paymentData),
		merchCode: get(state, "merchandising.current.code"),
	};
};

export const sendTagOnPaymentModeSelect = paymentMode => {
	const state = getStore().getState();
	sendTag({
		event: "payment_mode_select",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		paymentModeUpgrade: String(paymentMode),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnPaymentTypeSelect = paymentType => {
	const state = getStore().getState();
	sendTag({
		event: "payment_type_select",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		paymentTypeUpgrade: String(paymentType),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnConfirmationPageLoad = paymentData => {
	const state = Object.assign({}, getStore().getState(), { status: "client" }); // variable poussée une seule fois après un signup réussi
	sendTag({
		event: "payment_confirmation",
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		payment: mapPaymentStoreToTag(paymentData),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnShowMap = () => {
	const state = getStore().getState();

	sendTag({
		event: "show_map",
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnInsuranceSelect = ({ code, name, price }) => {
	const state = getStore().getState();

	sendTag({
		event: "insurance",
		quote: mapQuoteStoreToTag(state),
		insurance: {
			code: `${code}`,
			name: `${name}`,
			price: `${price}`,
		},
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnError = errorKey => {
	const state = getStore().getState();

	sendTag({
		event: "error",
		error: errorKey,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnApiCallbackError = (apiUrl, error = {}) => {
	const state = getStore().getState();

	sendTag({
		event: "api_callback_error",
		apiUrl,
		errorMessage: error.message || "",
		errorStack: error.stack || "",
		quote: mapQuoteStoreToTag(state),
		prePackage: mapPrePackageStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
	});
};

export const sendTagOnTokenRenewByPassed = () => {
	sendTag({
		event: "token_renew_bypassed",
	});
};

export const sendTagOnTokenRenewSuccess = () => {
	sendTag({
		event: "token_renew_success",
	});
};

export const sendTagOnTokenRenewError = error => {
	sendTag({
		event: "token_renew_error",
		error: error || "",
	});
};

export const sendTagOnTokenDeleteError = error => {
	sendTag({
		event: "token_delete_error",
		error: error || "",
	});
};

/*
 * @params status voir https://developers.google.com/maps/documentation/javascript/places#place_details_responses
 * @params type  getPredictions | getDetails
 */
export const sendTagOnGoogleAutoCompleteError = (type, status) => {
	sendTag({
		event: "google_autocomplete_error",
		type,
		status,
	});
};

export const sendTagOnGoogleAutocompleteSelect = address => {
	sendTag({
		event: "google_autocomplete_address_selected",
		address,
	});
};

export const sendTagOnContactCall = () => {
	const state = getStore().getState();

	sendTag({
		event: "contact_call",
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnSubscriptionUpdate = ({ frequency, remarketing }) => {
	sendTag({
		event: "subscription_update",
		frequency,
		remarketing: `${remarketing}`,
	});
};

export const sendTagOnPriceChanged = (newPrice, oldPrice) => {
	const state = getStore().getState();
	sendTag({
		event: "price_changed",
		newPrice,
		oldPrice,
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnProductsFilterOpen = filter => {
	sendTag({
		event: `listingPage_filter_by_${filter}_open`,
	});
};

export const sendTagOnProductsFilterRemove = filter => {
	sendTag({
		event: `listingPage_filter_by_${filter}_remove`,
	});
};

export const sendTagOnProductsFilter = (filter, value) => {
	sendTag({
		event: `listingPage_filter_by_${filter}`,
		value,
	});
};

export const sendTagOnMerchandisingProductsFilterRemove = value => {
	const state = getStore().getState();

	sendTag({
		event: `merchandisingPage_filter_remove`,
		value,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnMerchandisingProductsFilter = value => {
	const state = getStore().getState();

	sendTag({
		event: `merchandisingPage_filter`,
		value,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFlightFilter = (filter, value) => {
	const state = getStore().getState();

	sendTag({
		event: `flight_filter_${filter}`,
		value,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFlightResetFilter = () => {
	const state = getStore().getState();

	sendTag({
		event: `flight_filter_reset`,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFlightShowMore = (
	visibleFlightCount,
	filteredFlightCount,
	allFlightCount
) => {
	const state = getStore().getState();

	sendTag({
		event: `flight_show_more`,
		visibleFlightCount,
		filteredFlightCount,
		allFlightCount,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnAccommodationsShowMore = () => {
	const state = getStore().getState();

	sendTag({
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		event: `accommodations_show_more`,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnExternalPayment = status => {
	const state = getStore().getState();

	sendTag({
		event: `book_external_payment_status`,
		status,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnPaymentFailed = (status, paymentType) => {
	const state = getStore().getState();

	const tagData = {
		event: `payment_failure`,
		status,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	};
	if (paymentType) {
		tagData.paymentType = paymentType;
	}
	sendTag(tagData);
};

export const sendTagOnMyBookingsPaymentFailed = status => {
	sendTag({
		event: `my_bookings_payment_failure`,
		status,
	});
};

export const sendTagOn3DSRedirect = () => {
	const state = getStore().getState();
	sendTag({
		event: `3ds_redirect`,
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		prePackage: mapPrePackageStoreToTag(state),
		quote: mapQuoteStoreToTag(state),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnLogout = () => {
	sendTag({
		event: "logout",
	});
};

export const sendTagOnSocialShare = channel => {
	const state = getStore().getState();
	sendTag({
		event: `share_product_page`,
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		channel,
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnLogoutFromDevice = tokenId => {
	sendTag({
		event: "logout_from_device",
		tokenId: tokenId || "",
	});
};

export const sendTagOnLogoutFromAllDevice = () => {
	sendTag({
		event: "logout_from_all_device",
	});
};

export const sendTagOnSecurityPageLoad = () => {
	sendTag({
		event: "security_page",
	});
};

export const sendTagOnTeasingPageLoad = () => {
	sendTag({
		event: "teasing_page",
	});
};

export const checkOurNewOffer = () => {
	sendTag(Object.assign({}, { event: "check_our_new_offer" }, {}));
};

export const sendTagOnSearchBookingPageLoad = () => {
	sendTag({
		event: `site-institutionnel-page`,
	});
};

export const sendTagOnRecoverBookingDataPageLoad = () => {
	sendTag({
		event: `recover-booking-data-page`,
	});
};

export const sendTagOnBookingSearch = ({ bookingReference, credential }) => {
	sendTag({
		event: `my-booking-search`,
		bookingReference,
		credential,
	});
};

export const sendTagOnRecoverBookingData = ({ phone }) => {
	sendTag({
		event: `recover-booking-data`,
		phone,
	});
};

export const sendTagOnSponsorshipSubscribeSuccess = ({ parentSponsorId, sponsored }) => {
	sendTag({
		event: `sponsorship_subscribe_success`,
		sponsorid: parentSponsorId,
		email: sponsored,
	});
};

export const sendTagOnSponsorshipSubscribeFailed = ({ status, sponsored }) => {
	sendTag({
		event: `sponsorship_subscribe_failed`,
		status,
		email: sponsored,
	});
};

export const sendTagOnCreditsBurned = ({ usableCredits, totalCredits }) => {
	const state = getStore().getState();

	sendTag({
		event: `sponsorship_credits_burned`,
		usableCredits,
		totalCredits,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
	});
};

export const sendTagOnFetchCreditsFailed = ({ status }) => {
	const state = getStore().getState();
	sendTag({
		event: `sponsorship_fetch_credits_failed`,
		quote: mapQuoteStoreToTag(state),
		product: mapFicheProduitStoreToTag(state.ficheProduit),
		merchCode: get(state, "merchandising.current.code"),
		status,
	});
};

export const sendTagOnSponsorshipPageLoad = () => {
	sendTag({
		event: "sponsorship_page",
	});
};

export const sendTagOnMyCreditsPageLoad = () => {
	sendTag({
		event: "my_credits_page",
	});
};

export const sendTagOnMyCreditNotesPageLoad = () => {
	sendTag({
		event: "my_credit_notes_page",
	});
};

export const sendTagOnSponsorshipInvite = (channel, sponsorId) => {
	sendTag({
		event: "sponsorship_invite_social",
		channel,
		sponsorId,
	});
};

export const sendTagOnSponsorshipInviteByEmail = ({ sponsorId, firstName, lastName, emails }) => {
	sendTag({
		event: "sponsorship_invite_mail",
		sponsorId,
		firstName,
		lastName,
		emails: JSON.stringify(emails),
	});
};

export const sendTagOnOauthAccessTokenFailed = () => {
	sendTag({
		event: "get_oauth_access_token_failed",
	});
};

export const sendTagOnPageNotFound = () => {
	sendTag({
		event: "page_not_found",
		askedUrl: window.location.href || "",
		referrer: document.referrer || "",
	});
};

export const sendTagOnChangeLanguage = language => {
	sendTag({
		event: "change_language",
		language,
	});
};

export const sendTagOnClickFilterMobile = () => {
	sendTag({
		event: "listingPage_filter_mobile_open",
	});
};

export const sendDataOnClickCollectionPermanente = () => {
	sendTag({
		event: "listingPage_collection_permanente_click",
	});
};

export const sendTagOnException = ({ error = {}, info = {} }) => {
	const state = getStore().getState();

	sendTag({
		event: "exception_front",
		user: mapUserStoreToTag(state),
		errorName: error.name,
		errorMessage: error.message,
		errorStackTrace: error.stack,
		info: info.componentStack,
	});
};

export const sendTagOnNavlogClick = ({ productUri = "", quotationURL = "" }) => {
	sendTag({
		event: "listingPage_navlog_click",
		productUri,
		quotationURL,
	});
};

export const sendTagOnLookAlikeProductClick = ({ productUri = "", position }) => {
	sendTag({
		event: "lookalike_Product_click",
		position: `${position}`,
		productUri,
	});
};

export const sendTagOnNavlogPrevious = () => {
	sendTag({
		event: "last_seen_products_previous_click",
	});
};

export const sendTagOnNavlogProductClick = product => {
	sendTag({
		event: "last_seen_product_click",
		product,
	});
};

export const sendTagOnNavlogNext = nextProduct => {
	sendTag({
		event: "last_seen_products_next_click",
		product: nextProduct,
	});
};

export const sendTagOnNavlogSeenProducts = products => {
	sendTag({
		event: "last_seen_products_view",
		products,
	});
};

export const sendTagOnLogoRedirect = url => {
	sendTag({
		event: "brand_logo_header_click",
		url,
	});
};

export const sendTagOnBackButton = url => {
	sendTag({
		event: "back_button_header_click",
		url,
	});
};

export const sendTagOnOpenProductCarouselDesktop = () => {
	sendTag({
		event: "open_photos_carousel_desktop_click",
	});
};

export const sendTagOnSwipeProductCarouselMobile = () => {
	sendTag({
		event: "photos_carousel_mobile_swipe",
	});
};

export const sendTagOnMediaClickDesktop = title => {
	sendTag({
		event: "media_click_article_desktop",
		title,
	});
};

export const sendTagOnMediaClickMobile = () => {
	sendTag({
		event: "media_click_article_mobile",
	});
};

export const sendTagOnMediaCloseArticle = () => {
	sendTag({
		event: "media_close_article",
	});
};

export const sendTagOnMediaViewOffers = () => {
	sendTag({
		event: "media_view_offers",
	});
};

export const sendTagOnSelectPricedInsurance = code => {
	sendTag({
		event: "insurance_click_add",
		code,
	});
};

export const sendTagOnSelectWithoutInsurance = () => {
	sendTag({
		event: "insurance_click_without_insurance",
	});
};

export const sendTagOnCompareInsurance = () => {
	sendTag({
		event: "insurance_click_compare",
	});
};

export const sendTagOnClickInsuranceDetailsLink = () => {
	sendTag({
		event: "insurance_click_more_details",
	});
};
export const sendTagOnSearchPageViewed = () => {
	sendTag({
		event: "search_page",
	});
};

export const sendTagDepositNoteViewed = () => {
	sendTag({
		event: "Search_deposit_available",
	});
};

export const sendTagDepositOnQuotePage = () => {
	sendTag({
		event: "Quote_deposit_available",
	});
};

export const sendTagOnCouponBannerClick = () => {
	sendTag({ event: "banner_refund_click" });
};

export const sendTagOnCGUCreditClick = () => {
	sendTag({ event: "more_informations_credit" });
};

export const sendTagOnCGUCreditNoteClick = () => {
	sendTag({ event: "more_informations_refund" });
};

export const sendTagOnCreditTravelBackGoToListingClick = () => {
	sendTag({ event: "credit_travel_back_listing_click" });
};

export const sendTagOnCreditGoToSponsorshipClick = () => {
	sendTag({ event: "credit_go_to_sponsorship_click" });
};

export const sendTagOnMoreDetailCreditClick = () => {
	sendTag({ event: "more_details_credit" });
};

export const sendTagOnMoreDetailCreditNoteClick = () => {
	sendTag({
		event: "more_details_refund",
	});
};

export const sendTagOnCouponChange = couponType => {
	sendTag({
		event: `${couponType}_click`,
	});
};

/**
 * @param suffix "refund" or "credit"
 * @param notApplicableCoupons
 */
export const sendTagOnOnlyNonApplicableCouponMessageShow = (suffix, notApplicableCoupons = []) => {
	const reasons = notApplicableCoupons.map(notApplicableCoupon => {
		return `${notApplicableCoupon.title}${
			notApplicableCoupon.conditions ? " - " + notApplicableCoupon.conditions : ""
		}`;
	});

	sendTag({
		event: `not_applicable_${suffix}`,
		notApplicableCoupons: reasons.join(","),
	});
};

export const sendTagOnShowCreditHistoryClick = couponType => {
	sendTag({
		event: `show_credit_${couponType}_history`,
	});
};

/**
 *
 * @param couponType "sponsorship", "travelback", "generic", "refund"
 */
export const sendTagOnHideCreditHistoryClick = couponType => {
	sendTag({
		event: `hide_credit_${couponType}_history`,
	});
};

export const sendTagOnTotalToPayZero = () => {
	sendTag({
		event: "total_to_pay_zero",
	});
};

export const sendTagOnShowCouponUseDetailClick = () => {
	sendTag({
		event: `show_coupon_use_details`,
	});
};

export const sendTagOnHideCouponUseDetailClick = () => {
	sendTag({
		event: `hide_coupon_use_details`,
	});
};

export const sendTagOnRefundGoToListingClick = () => {
	sendTag({
		event: `use_refund_click`,
	});
};

export const sendTagOnActivityGuestChange = (activityName, guestCode, guestCount) => {
	sendTag({
		event: `activity_guest_update`,
		activityName,
		guestCode,
		guestCount: `${guestCount}`,
	});
};

export const sendTagOnActivityDateChange = () => {
	sendTag({
		event: `activity_date_select`,
	});
};

export const sendTagOnActivityMoreDetails = () => {
	sendTag({
		event: `activity_more_details`,
	});
};

export const sendTagOnActivityLessDetails = () => {
	sendTag({
		event: `activity_less_details`,
	});
};

export const sendTagOnActivityShowMore = () => {
	sendTag({
		event: `activity_show_more`,
	});
};

export const sendTagOnActivityDuplicateDateError = () => {
	sendTag({
		event: `activity_date_error`,
	});
};

export const sendTagOnStaticPage = slug => {
	sendTag({
		event: `static_page`,
		slug,
	});
};

export const sendTagOnPriceCalendarSidePanelOpened = () => {
	sendTag({
		event: `price_calendar_side_panel_opened`,
	});
};

export const sendTagOnPriceCalendarSidePanelClosedButtonClicked = () => {
	sendTag({
		event: `price_calendar_side_panel_close_button_clicked`,
	});
};

export const sendTagOnPriceCalendarModalOpened = () => {
	sendTag({
		event: `price_calendar_modal_opened`,
	});
};

export const sendTagOnPriceCalendarModalHidden = () => {
	sendTag({
		event: `price_calendar_modal_hidden`,
	});
};

export const sendTagOnPriceCalendarNextMonth = () => {
	sendTag({
		event: `price_calendar_next_month_clicked`,
	});
};

export const sendTagOnPriceCalendarPreviousMonth = () => {
	sendTag({
		event: `price_calendar_previous_month_clicked`,
	});
};

export const sendTagOnViewAllDurationClicked = durationsCount => {
	sendTag({
		event: `view_all_durations_clicked`,
		durationsCount: `${durationsCount}`,
	});
};

export const sendTagOnHideDurationClicked = durationsCount => {
	sendTag({
		event: `hide_durations_clicked`,
		durationsCount: `${durationsCount}`,
	});
};

export const sendTagOnPriceCalendarDateClicked = value => {
	sendTag({
		event: `price_calendar_date_clicked`,
		day: `${value}`,
	});
};

export const sendTagOnPriceCalendarValidationClicked = ({ departureDate, endDate, price }) => {
	sendTag({
		event: `price_calendar_validation_clicked`,
		price: `${price}`,
		departureDate: `${new Date(departureDate)}`,
		endDate: `${new Date(endDate)}`,
	});
};

export const sendTagOnFaqHomePage = () => {
	sendTag({
		event: `faq_home_page`,
	});
};

export const sendTagOnFaqArticlePage = articleTitle => {
	sendTag({
		event: `faq_article_page`,
		name: `${articleTitle}`,
	});
};

export const sendTagOnFaqSpotlightArticleClick = articleTitle => {
	sendTag({
		event: `faq_spotlight_article_click`,
		name: `${articleTitle}`,
	});
};

export const sendTagOnFaqFeaturedArticleClick = articleTitle => {
	sendTag({
		event: `faq_featured_article_click`,
		name: `${articleTitle}`,
	});
};

export const sendTagOnFaqCategoryPage = categoryTitle => {
	sendTag({
		event: `faq_category_page`,
		name: `${categoryTitle}`,
	});
};

export const sendTagOnFaqSubCategoryPage = subCategoryTitle => {
	sendTag({
		event: `faq_subcategory_page`,
		name: `${subCategoryTitle}`,
	});
};

export const sendTagOnFaqShowPhoneButtonClick = () => {
	sendTag({
		event: `faq_show_phones_button_click`,
	});
};

export const sendTagOnFaqWriteUsButtonClick = () => {
	sendTag({
		event: `faq_write_us_button_click`,
	});
};

export const sendTagOnFaqAsideMobileNavOpen = () => {
	sendTag({
		event: `faq_aside_mobile_nav_open`,
	});
};

export const sendTagOnSDPSearchPage = () => {
	sendTag({
		event: `sdp_search_page`,
	});
};

export const sendTagOnSDPSearch = payload => {
	const data = {
		...payload,
		duration: `${payload.duration}`,
		departureDate: `${payload.departureDate}`,
		occupancies: payload.occupancies.map(occupancy => {
			const childrenBirthdates =
				occupancy.childrenBirthdates &&
				occupancy.childrenBirthdates.map(birthdate => {
					return {
						day: `${birthdate.day}`,
						month: `${birthdate.month}`,
						year: `${birthdate.year}`,
					};
				});

			return {
				...occupancy,
				adults: `${occupancy.adults}`,
				children: `${occupancy.children}`,
				childrenBirthdates,
			};
		}),
	};

	sendTag({
		event: `sdp_search_submit`,
		...data,
	});
};

export const sendTagOnFetchSDPProduct = ({
	httpStatusCode,
	status,
	productCount,
	queryParams,
	specialOffer,
}) => {
	sendTag({
		event: `sdp_fetch_products`,
		httpStatusCode: `${httpStatusCode}`,
		status: `${status}`,
		productCount: `${productCount}`,
		queryParams,
		specialOffer: `${specialOffer}`,
	});
};

export const sendTagOnShowMapClicked = () => {
	sendTag({
		event: `show_map_clicked`,
	});
};

export const sendTagOnShowListClicked = () => {
	sendTag({
		event: `show_list_clicked`,
	});
};

export const sendTagOnMapDisplayed = ({ zoom, itemCount }) => {
	sendTag({
		event: `map_displayed`,
		itemCount: `${itemCount}`,
		zoom: `${zoom}`,
	});
};

export const sendTagOnMapPinClicked = name => {
	sendTag({
		event: `map_pin_clicked`,
		name,
	});
};

export const sendTagOnMapProductSwiped = ({ index, name }) => {
	sendTag({
		event: `map_product_swiped`,
		name,
		index: `${index + 1}`,
	});
};

export const sendTagOnMapProductHovered = ({ index, name }) => {
	sendTag({
		event: `map_product_hovered`,
		name,
		index: `${index + 1}`,
	});
};

export const sendTagOnMenuGoToSdpSearch = () => {
	sendTag({
		event: `menu_go_to_sdp_search`,
	});
};

export const sendTagOnMenuGoToAuth = () => {
	sendTag({
		event: `menu_go_to_auth`,
	});
};

export const sendTagOnMenuGoToSearchBooking = () => {
	sendTag({
		event: `menu_go_to_search_booking`,
	});
};

export const sendTagOnMenuGoToHelp = () => {
	sendTag({
		event: `menu_go_to_help`,
	});
};

export const sendTagOnGeolocationError = ({ code }) => {
	// for 1 2 and 3, @see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
	const GEOLOCATION_POSITION_ERRORS = {
		1: "PERMISSION_DENIED",
		2: "POSITION_UNAVAILABLE",
		3: "TIMEOUT",
		4: "API_NOT_SUPPORTED",
	};

	sendTag({
		event: `geolocation_permission_error`,
		code: GEOLOCATION_POSITION_ERRORS[code],
	});
};

export const sendTagOnGoogleApiLoad = ({ error }) => {
	sendTag({
		event: `google_api_load_error`,
		error,
	});
};
