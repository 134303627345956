import _forOwn from "lodash/forOwn";
import _isUndefined from "lodash/isUndefined";
import _isNull from "lodash/isNull";
import _isNaN from "lodash/isNaN";
import _isEmpty from "lodash/isEmpty";
import _isObject from "lodash/isObject";
import _isArray from "lodash/isArray";
import _pull from "lodash/pull";
import _cloneDeep from "lodash/cloneDeep";

export default function pruneEmpty(obj) {
	return (function prune(current) {
		_forOwn(current, function(value, key) {
			if (
				_isUndefined(value) ||
				_isNull(value) ||
				_isNaN(value) ||
				(_isObject(value) && _isEmpty(prune(value)))
			) {
				delete current[key];
			}
		});
		// remove any leftover undefined values from the delete
		// operation on an array
		if (_isArray(current)) {
			_pull(current, undefined);
		}
		return current;
	})(_cloneDeep(obj)); // Do not modify the original object, create a clone instead
}
