import {
	AUTH_SUCCESS,
	FETCH_PROFILE_FAILURE,
	FETCH_PROFILE_REQUEST,
	FETCH_PROFILE_SUCCESS,
	LOGOUT,
	RESTORE_BOOKING_SESSION_REQUEST,
	RESTORE_BOOKING_SESSION_SUCCESS,
	SAVE_NEWSLETTER_PREFERENCES_SUCCESS,
	SAVE_PROFILE_REQUEST,
	SAVE_PROFILE_SUCCESS,
} from "app/actionTypes";

import { PROFILE_CODE_STATUS } from "app/constants";
import { SET_NEWSLETTER_FREQUENCY_MODAL_VIEWED } from "app/pages/Account/Subscriptions/subscriptionsActionTypes";

const defaultProfile = {
	status: undefined,
	name: {},
	address: {},
	passport: {},
	newsletterPreference: {},
	deleted: false,
};

export default function profileReducer(profileState = defaultProfile, action) {
	switch (action.type) {
		case RESTORE_BOOKING_SESSION_REQUEST:
		case FETCH_PROFILE_REQUEST:
			return {
				...defaultProfile,
				status: PROFILE_CODE_STATUS.LOADING,
			};
		case FETCH_PROFILE_FAILURE:
			return {
				...defaultProfile,
				status: PROFILE_CODE_STATUS.FAILURE,
			};
		case FETCH_PROFILE_SUCCESS: {
			const newProfile = {
				status: PROFILE_CODE_STATUS.SUCCESS,
				...action.profile,
			};

			return {
				...profileState,
				...newProfile,
			};
		}
		case RESTORE_BOOKING_SESSION_SUCCESS: {
			const { bookRequest = {} } = action.res.data;
			const { customer = {} } = bookRequest;

			const newProfile = {
				name: {
					lastName: customer.lastName,
					gender: customer.civility,
					firstName: customer.firstName,
				},
				email: customer.email,
				address: {
					phone: customer?.phone,
					postCode: customer?.address?.postCode,
					line1: customer?.address?.address1,
					line2: customer?.address?.address2,
					country: customer?.address?.country,
					city: customer?.address?.city,
				},
			};
			return {
				...profileState,
				...newProfile,
				status: PROFILE_CODE_STATUS.SUCCESS,
			};
		}
		case SAVE_PROFILE_REQUEST:
			return Object.assign({}, profileState, { status: undefined });
		case SAVE_PROFILE_SUCCESS: {
			const profile = action.res.data;
			profile.status = PROFILE_CODE_STATUS.SUCCESS;
			return Object.assign({}, profileState, profile);
		}
		case SAVE_NEWSLETTER_PREFERENCES_SUCCESS: {
			const newsletterPreference = action.res.data.newsletterPreference;
			return Object.assign({}, profileState, {
				status: PROFILE_CODE_STATUS.SUCCESS,
				newsletterPreference: {
					...profileState.newsletterPreference,
					...newsletterPreference,
					shouldDisplayFrequencyModal: false,
				},
			});
		}
		case SET_NEWSLETTER_FREQUENCY_MODAL_VIEWED: {
			return {
				...profileState,
				newsletterPreference: {
					...profileState.newsletterPreference,
					shouldDisplayFrequencyModal: false,
				},
			};
		}
		case AUTH_SUCCESS: {
			return {
				...profileState,
				newsletterPreference: {
					...profileState.newsletterPreference,
					shouldDisplayFrequencyModal: action.isSignup,
				},
			};
		}
		case LOGOUT:
			return defaultProfile;
		default:
			return profileState;
	}
}
