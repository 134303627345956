import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { SDP_HERO_TEXT_ALIGN } from "app/constants";
import "./SmartDPMarketingHeadline.scss";
import { memo } from "react";
import { messagePropType } from "app/utils/propTypes";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const SmartDPMarketingHeadline = ({ title, subtitle, textAlign }) => {
	let align = "center";
	if (textAlign === SDP_HERO_TEXT_ALIGN.LEFT) {
		align = "flex-start";
	} else if (textAlign === SDP_HERO_TEXT_ALIGN.RIGHT) {
		align = "flex-end";
	}

	return (
		<h1
			className="smartdp-marketing-headline"
			style={{
				alignItems: align,
			}}
		>
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL6}
				variantMD={TYPOGRAPHY_VARIANTS.XL9}
				isBold
				className="smartdp-marketing-headline__title"
				component="div"
			>
				<Paragraphs paragraphs={title} enableHTMLFormatting />
			</Typography>{" "}
			{/* keep this space for H1 title and subtitle being separated*/}
			<Typography
				variant={TYPOGRAPHY_VARIANTS.REGULAR}
				variantMD={TYPOGRAPHY_VARIANTS.XL3}
				isBold
				className="smartdp-marketing-headline__subtitle"
				component="div"
			>
				<Paragraphs paragraphs={subtitle} enableHTMLFormatting />
			</Typography>
		</h1>
	);
};

SmartDPMarketingHeadline.propTypes = {
	title: messagePropType,
	subtitle: messagePropType,
	textAlign: PropTypes.string,
};

export default memo(SmartDPMarketingHeadline);
