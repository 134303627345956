import get from "lodash/get";
import {
	FETCH_USABLE_COUPONS_REQUEST,
	FETCH_USABLE_COUPONS_SUCCESS,
	FETCH_USABLE_CREDIT_NOTES_REQUEST,
	FETCH_USABLE_CREDIT_NOTES_SUCCESS,
} from "app/pages/Account/MyCoupons/couponActionTypes";
import { USABLE_CREDITS_STATUS } from "app/constants";
import { LOGOUT } from "app/actionTypes";

const defaultState = {
	status: undefined,
	sponsorship: {},
	generic: {},
	creditNote: {},
	travelback: {},
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case FETCH_USABLE_COUPONS_REQUEST: {
			return {
				...defaultState,
				status: USABLE_CREDITS_STATUS.LOADING_COUPONS,
			};
		}
		case FETCH_USABLE_CREDIT_NOTES_REQUEST: {
			return {
				...state,
				creditNote: {},
				status: USABLE_CREDITS_STATUS.LOADING_CREDIT_NOTES,
			};
		}
		case FETCH_USABLE_CREDIT_NOTES_SUCCESS:
		case FETCH_USABLE_COUPONS_SUCCESS: {
			const status = get(action, "res.data.status");
			const sponsorship = get(action, "res.data.credit.sponsorship", {});
			const generic = get(action, "res.data.credit.generic", {});
			const travelback = get(action, "res.data.credit.travelback", {});
			const creditNote = get(action, "res.data.creditNote", {});

			return {
				...state,
				status,
				sponsorship,
				generic,
				travelback,
				creditNote,
			};
		}
		case LOGOUT:
			return defaultState;
		default:
			return state;
	}
};
