import {
	HIDE_ASIDE_MENU,
	HIDE_ASIDE_PRODUCT_FILTERS,
	SET_LISTING_FILTER_STICKYNESS,
	TOGGLE_ASIDE_MENU,
	TOGGLE_ASIDE_PRODUCT_FILTERS,
} from "app/actionTypes";

export default (
	state = {
		isListingFilterSticky: false,
		isAsideMenuOpened: false,
		isAsideProductFilterOpened: false, //Affichage des filtres sur mobile dans le menu latéral
	},
	action
) => {
	switch (action.type) {
		case SET_LISTING_FILTER_STICKYNESS:
			return Object.assign({}, state, {
				isListingFilterSticky: action.isSticky,
			});
		case TOGGLE_ASIDE_MENU:
			return Object.assign({}, state, {
				isAsideMenuOpened: !state.isAsideMenuOpened,
			});
		case HIDE_ASIDE_MENU:
			return Object.assign({}, state, {
				isAsideMenuOpened: false,
			});
		case TOGGLE_ASIDE_PRODUCT_FILTERS:
			return {
				...state,
				...{ isAsideProductFilterOpened: !state.isAsideProductFilterOpened },
			};
		case HIDE_ASIDE_PRODUCT_FILTERS:
			return {
				...state,
				...{ isAsideProductFilterOpened: false },
			};
		default:
			return state;
	}
};
