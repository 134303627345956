import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import "./MarketingBanner.scss";
import { useLocation, useParams } from "react-router-dom";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { isServerSide, isUserMatchWithType } from "app/utils/utils";
import { useFlagship, useFsFlag } from "@flagship.io/react-sdk";
import RelativeLink from "app/pages/.shared/RelativeLink";
import className from "classnames";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const MarketingBanner = ({ banners = [], userIsConnected }) => {
	const fsShowMarketingBanner = useFsFlag("marketing_banner_show", true);
	const { status: fsStatus } = useFlagship();

	let { productUri } = useParams();
	const isFicheProduitPage = Boolean(productUri);
	const location = useLocation();

	const shouldShowBanner = useCallback(
		banner => {
			const { message, pages = [], usersFilter = [] } = banner;
			const checkUserType = isUserMatchWithType(usersFilter, userIsConnected);
			const isValidPage = pages.find(page => {
				return (
					location.pathname === page ||
					(page === "/:productUri" && isFicheProduitPage) ||
					(page === "/sdp/listing" && location.pathname.includes(page))
				);
			});
			return (
				checkUserType &&
				isValidPage &&
				(fsStatus.isSdkReady || (!isServerSide && window.Cypress)) &&
				fsShowMarketingBanner.getValue() &&
				message
			);
		},
		[location, userIsConnected, fsStatus.isSdkReady]
	);

	if (banners.length === 0) {
		return null;
	}

	return banners
		.filter(shouldShowBanner)
		.map(
			(
				{
					message,
					backgroundColor,
					textColor,
					promoCode,
					icon,
					displayIconEnd,
					redirectUrl,
				},
				index
			) => {
				const banner = (
					<Typography
						variant={TYPOGRAPHY_VARIANTS.REGULAR}
						variantMD={TYPOGRAPHY_VARIANTS.LARGE}
					>
						<div
							className={className({
								"marketing-banner": true,
								[`marketing-banner--media-${
									displayIconEnd ? "end" : "start"
								}`]: icon,
							})}
							data-testid="marketing-banner"
							style={{ background: backgroundColor?.hex, color: textColor?.hex }}
							key={index}
						>
							{promoCode && (
								<span
									className="marketing-banner__promocode"
									data-testid="marketing-banner-promocode"
								>
									{promoCode}
								</span>
							)}
							<Paragraphs paragraphs={message} enableHTMLFormatting />

							{icon && (
								<img
									className="marketing-banner__icon"
									data-testid="marketing-banner-icon"
									src={icon}
								/>
							)}
						</div>
					</Typography>
				);
				return redirectUrl ? (
					<RelativeLink
						className="marketing-banner__link"
						rel="noopener noreferrer"
						target="_blank"
						to={{ pathname: redirectUrl }}
					>
						{banner}
					</RelativeLink>
				) : (
					banner
				);
			}
		);
};

MarketingBanner.propTypes = {
	banners: PropTypes.array,
	userIsConnected: PropTypes.bool,
};

export default memo(MarketingBanner);
