import { FETCH_CONTEXTUALIZED_SIGNUP_PAGES_AUTH_SUCCESS } from "app/pages/SignupPage/Auth/SignupAuthActionTypes";

const INITIAL_STATE = {
	signupPages: [],
	activeSignupPageCode: undefined,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_CONTEXTUALIZED_SIGNUP_PAGES_AUTH_SUCCESS: {
			return { ...state, signupPages: action.res.data };
		}
		default: {
			return state;
		}
	}
};
