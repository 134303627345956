import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPartnerCode } from "app/reducers/partnerSelector";
import { getMyBookingsCustomerRequestsCount } from "app/pages/Account/MyBookings/myBookingsSelector";
import {
	getAvailableCreditNotesTotal,
	getAvailableCreditsTotal,
	hasAnyCreditNotes,
} from "app/pages/Account/MyCoupons/couponSelector";
import { logout } from "app/pages/Auth/AuthActions";
import { isWhiteLabel } from "app/reducers/brandSelector";
import AccountMenuAuthenticated from "app/pages/Header/AccountMenu/AccountMenuAuthenticated/AccountMenuAuthenticated";
import { isIdentified } from "app/pages/Auth/authSelectors";

const mapStateToProps = state => {
	return {
		brand: state.brand,
		enableSponsorship: state.partner.enableSponsorship,
		partnerCode: getPartnerCode(state),
		isWhiteLabel: isWhiteLabel(state),
		enableCredits: state.partner.enableCredits,
		showCustomerRequestNotification: getMyBookingsCustomerRequestsCount(state) > 0,
		shouldDisplayCreditNotesEntry: hasAnyCreditNotes(state),
		creditsAmount: getAvailableCreditsTotal(state),
		creditNotesAmount: getAvailableCreditNotesTotal(state),
		isIdentified: isIdentified(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ logout }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountMenuAuthenticated);
