import { createSelector } from "reselect";
import { DESTINATION_USA_CODE, MEDIA_TYPE } from "app/constants";

const getFicheProduit = state => state.ficheProduit;
const getMultimediaPhotos = state => state.ficheProduit.multimedia;
const getPhotos = state => state.ficheProduit.photos;

export const getSpecialOffer = createSelector(
	[getFicheProduit],
	(ficheProduit = {}) => {
		return ficheProduit.specialOffer;
	}
);

export const getVideos = createSelector(
	[getMultimediaPhotos],
	(multimediaPhotos = []) => {
		const onlyVideo = multimediaPhotos.filter(media => {
			return media.type === MEDIA_TYPE.VIDEO;
		});
		return onlyVideo;
	}
);

export const getDesktopSlideShowPhotos = createSelector(
	[getPhotos, getVideos],
	(photos = [], videos = []) => {
		const photosBeforeVideos = photos.length > 0 ? [photos[0]] : [];

		return photosBeforeVideos.concat(videos).concat(photos.slice(1, photos.length));
	}
);

export const getProductCountry = createSelector(
	[getFicheProduit],
	(ficheProduit = {}) => {
		return ficheProduit.country;
	}
);

export const getCurrentProductURI = createSelector(
	[getFicheProduit],
	(ficheProduit = {}) => {
		return ficheProduit.uri;
	}
);

export const isProductExpired = createSelector(
	[getFicheProduit],
	(ficheProduit = {}) => {
		return ficheProduit.expiration?.hour === 0 && ficheProduit.expiration?.day === 0;
	}
);

export const isUsaDestination = createSelector(
	[getFicheProduit],
	(ficheProduit = {}) => {
		const geoCodes = ficheProduit?.geography?.levels?.map(level => level?.code) || [];
		return geoCodes?.length === 0
			? false
			: geoCodes.some(code => DESTINATION_USA_CODE.includes(code));
	}
);

export const isFlashsale = createSelector(
	[getFicheProduit],
	(ficheProduit = {}) => {
		return ficheProduit.isFlashsale === true;
	}
);
