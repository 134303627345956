import {
	AUTH_SUCCESS,
	FETCH_PROFILE_SUCCESS,
	FETCH_SEARCH_MY_BOOKING_SUCCESS,
	LOGOUT,
	SET_EMAIL,
} from "app/actionTypes";
import get from "lodash/get";

export default function emailReducer(emailState = null, action) {
	switch (action.type) {
		case SET_EMAIL:
			return action.email || emailState;
		case AUTH_SUCCESS:
			return action.email || emailState;
		case FETCH_SEARCH_MY_BOOKING_SUCCESS: {
			return get(action, "res.data.booking.email") || emailState;
		}
		case FETCH_PROFILE_SUCCESS: {
			const profile = action.profile;
			return profile.email || emailState;
		}
		case LOGOUT:
			return null;
		default:
			return emailState;
	}
}
