import {
	FETCH_MERCHANDISING_LIGHT_SUCCESS,
	FETCH_MERCHANDISING_SUCCESS,
	FETCH_MERCHANDISINGS_SUCCESS,
	SET_MERCH_PRODUCTS_FILTER_BY,
	UNSET_MERCH_PRODUCTS_FILTER_BY,
	UPDATE_CURRENT_MERCH_CODE,
} from "app/pages/Merchandising/merchandisingActionTypes";
import { computedProductDates } from "app/utils/utils";
import { MAX_PRODUCTS_TO_RENDER_SERVER_SIDE } from "app/constants";

const initialState = {
	current: {},
	products: [],
	catalogProducts: [],
	list: [],
	activeFilter: undefined,
	previewStartDate: undefined,
};

export default (merchandising = initialState, action) => {
	switch (action.type) {
		case FETCH_MERCHANDISINGS_SUCCESS: {
			const data = action?.res?.data?.merchandisings || [];

			return {
				...initialState,
				list: data,
				activeFilter: undefined,
				previewStartDate: merchandising.previewStartDate,
			};
		}
		case FETCH_MERCHANDISING_SUCCESS: {
			const {
				merchandising: merchandisingData = {},
				merchandisings = [],
				products = [],
				catalogProducts = [],
			} = action.res.data;

			const newProductList = computedProductDates(products);
			const newCatalogProductList = computedProductDates(catalogProducts);

			return {
				loading: false,
				current: merchandisingData,
				list: merchandising.list, // liste des merchs avec images et tous les infos, destinés uniquement pour la page listing
				lightList: merchandisings, // liste des merchs avec le minimum de data, uniquement pour la page merchandising pour le composant merch switcher
				products: newProductList,
				catalogProducts: newCatalogProductList,
				activeFilter: undefined,
				previewStartDate: merchandising.previewStartDate,
			};
		}
		case FETCH_MERCHANDISING_LIGHT_SUCCESS: {
			const {
				merchandising: merchandisingData = {},
				merchandisings = [],
				products = [],
			} = action.res.data;

			const newProductList = computedProductDates(products).slice(
				0,
				MAX_PRODUCTS_TO_RENDER_SERVER_SIDE
			);

			return {
				loading: false,
				current: merchandisingData,
				list: merchandising.list, // liste des merchs avec images et tous les infos, destinés uniquement pour la page listing
				lightList: merchandisings, // liste des merchs avec le minimum de data, uniquement pour la page merchandising pour le composant merch switcher
				products: newProductList,
				activeFilter: undefined,
				previewStartDate: merchandising.previewStartDate,
			};
		}
		case SET_MERCH_PRODUCTS_FILTER_BY: {
			return { ...merchandising, activeFilter: action.filterBy };
		}
		case UNSET_MERCH_PRODUCTS_FILTER_BY: {
			return { ...merchandising, activeFilter: undefined };
		}
		case UPDATE_CURRENT_MERCH_CODE: {
			return {
				...merchandising,
				current: { code: action.code },
			};
		}
		default:
			return merchandising;
	}
};
