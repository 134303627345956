import * as React from "react";
import { useCallback } from "react";
import PropTypes from "prop-types";
import SignupContainer from "app/pages/Auth/Signup/SignupContainer";
import LoginContainer from "app/pages/Auth/Signin/LoginContainer";
import "./AuthLayer.scss";
import AuthFooter from "@brand/Auth/AuthFooter";
import { BRANDS } from "app/constants";
import { brandPropTypes } from "app/utils/propTypes";
import SearchBookingContainer from "app/pages/CorporateSite/SearchBooking/SearchBookingContainer";
import Flipper from "app/pages/.shared/Animations/Flipper";
import RecoverBookingDataContainer from "app/pages/CorporateSite/RecoverBookingData/RecoverBookingDataContainer";
import {
	sendTagOnRecoverBookingDataPageLoad,
	sendTagOnSearchBookingPageLoad,
} from "app/utils/analytics";
import IconCrossWhite from "app/pages/.shared/static/icons/IconCrossWhite";

const AuthLayer = ({
	isDefaultSignin,
	onAuthSuccess,
	signinMarketingBlock,
	signupMarketingBlock,
	showMarketingBlock,
	signupFooter,
	signinFooter,
	brand,
	onClose,
}) => {
	const [showSignIn, setShowSignIn] = React.useState(isDefaultSignin);
	const [isSearch, setSearch] = React.useState(true);

	const isBrandXX = brand === BRANDS.XX;

	const onClickShowsignIn = React.useCallback(() => {
		setShowSignIn(false);
	}, []);

	const onClickShowSignUp = React.useCallback(() => {
		setShowSignIn(true);
	}, []);

	const onClickRecover = useCallback(() => {
		sendTagOnRecoverBookingDataPageLoad();
		setSearch(false);
	}, [isSearch]);

	const onClickCross = useCallback(() => {
		sendTagOnSearchBookingPageLoad();
		setSearch(true);
	}, [isSearch]);

	const renderFrontForm = useCallback(props => {
		return (
			<div {...props}>
				<SearchBookingContainer onClickRecover={onClickRecover} />
			</div>
		);
	}, []);

	const renderBackForm = useCallback(props => {
		return (
			<div {...props}>
				<i className="icon icon--cross" onClick={onClickCross} />
				<RecoverBookingDataContainer onRecoverSuccess={onClickCross} />
			</div>
		);
	}, []);

	return (
		<div className="auth-layer" data-testid="auth-layer">
			{showMarketingBlock && (
				<div className="auth-layer__marketing" data-testid="auth-marketing-text">
					{showSignIn ? signinMarketingBlock : signupMarketingBlock}
				</div>
			)}
			{isBrandXX ? (
				<div className="auth-layer__auth-form auth-layer__auth-form--darksite">
					<Flipper
						isFront={isSearch}
						frontComponent={renderFrontForm}
						backComponent={renderBackForm}
					/>
				</div>
			) : (
				<div className="auth-layer__auth-form">
					{showSignIn ? (
						<LoginContainer
							headline={false}
							footer={signinFooter}
							onAuthSuccess={onAuthSuccess}
							onToogleSignupForm={onClickShowsignIn}
						/>
					) : (
						<SignupContainer
							headline={false}
							footer={signupFooter}
							onAuthSuccess={onAuthSuccess}
							onToogleSigninForm={onClickShowSignUp}
						/>
					)}
				</div>
			)}
			{onClose && (
				<div className="auth-layer__close-icon" onClick={onClose}>
					<IconCrossWhite />
				</div>
			)}
		</div>
	);
};

AuthLayer.defaultProps = {
	isDefaultSignin: false,
	showMarketingBlock: true,
	signupFooter: <AuthFooter />,
	signinFooter: <AuthFooter />,
};
AuthLayer.propTypes = {
	signupFooter: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
	signinFooter: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
	isDefaultSignin: PropTypes.bool,
	showMarketingBlock: PropTypes.bool,
	signinMarketingBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	signupMarketingBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onAuthSuccess: PropTypes.func.isRequired,
	brand: brandPropTypes,
	brandDisplayName: PropTypes.string,
	onClose: PropTypes.func,
};

export default AuthLayer;
