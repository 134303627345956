import { memo } from "react";
import PropTypes from "prop-types";
import "./AuthLayerMobile.scss";
import IconAtolProtected from "app/pages/.shared/static/icons/IconAtolProtected";
import IconABTACertification from "app/pages/.shared/BookWithConfidence/IconABTACertification";
import FooterContainer from "app/pages/Footer/FooterContainer";
import AuthLayerContainer from "app/pages/Auth/AuthLayer/AuthLayerContainer";
import ReasonToBookBlockContainer from "app/pages/.shared/ReasonsToBook/ReasonToBookBlock/ReasonToBookBlockContainer";
import BackgroundGradient from "app/pages/.shared/BackgroundGradient/BackgroundGradient";
import LogoHeader from "app/pages/Header/LogoHeader/LogoHeader";

const IS_DEFAULT_SIGN_IN = false;

const AuthLayerMobile = ({
	onAuthSuccess,
	showAtolAbta,
	signinMarketingBlock,
	signupMarketingBlock,
	onClose,
}) => {
	return (
		<div className="auth-layer-mobile">
			<div className="auth-layer-mobile__wrapper">
				<BackgroundGradient />

				<LogoHeader isWhite={true} showSpanLogo={true} />
				<div className="auth-layer__main">
					<AuthLayerContainer
						isDefaultSignin={IS_DEFAULT_SIGN_IN}
						onAuthSuccess={onAuthSuccess}
						signinMarketingBlock={signinMarketingBlock}
						signupMarketingBlock={signupMarketingBlock}
						onClose={onClose}
					/>
				</div>
				{showAtolAbta && (
					<div className="auth-layer-mobile__certifications">
						<IconAtolProtected height={40} width={40} />
						<IconABTACertification height={40} width={70} noColor={true} />
					</div>
				)}
			</div>
			<div className="auth-layer-mobile__rtb">
				<ReasonToBookBlockContainer />
			</div>
			<FooterContainer />
		</div>
	);
};

AuthLayerMobile.propTypes = {
	onAuthSuccess: PropTypes.func,
	showAtolAbta: PropTypes.bool,
	onClose: PropTypes.func,
	signupMarketingBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	signinMarketingBlock: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default memo(AuthLayerMobile);
