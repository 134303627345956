import { UPDATE_MARKETING_DATA } from "app/actionTypes";

const initialState = {
	source: undefined,
	marketingOperationCode: undefined,
	utmMedium: undefined,
	utmSource: undefined,
	utmCampaign: undefined,
	utmContent: undefined,
	utmTerm: undefined,
};

export default (marketing = initialState, action) => {
	switch (action.type) {
		case UPDATE_MARKETING_DATA: {
			let marketingData = {};

			if (action?.data?.source) {
				marketingData.source = action.data.source;
			}

			if (action?.data?.marketingOperationCode) {
				marketingData.marketingOperationCode = action.data.marketingOperationCode;
			}
			if (action?.data?.utmMedium) {
				marketingData.utmMedium = action.data.utmMedium;
			}
			if (action?.data?.utmSource) {
				marketingData.utmSource = action.data.utmSource;
			}
			if (action?.data?.utmCampaign) {
				marketingData.utmCampaign = action.data.utmCampaign;
			}
			if (action?.data?.utmContent) {
				marketingData.utmContent = action.data.utmContent;
			}
			if (action?.data?.utmTerm) {
				marketingData.utmTerm = action.data.utmTerm;
			}

			return {
				...marketing,
				...marketingData,
			};
		}
		default:
			return marketing;
	}
};
