import { parse } from "qs";

export const parseQueries = search => {
	return parse(search, { ignoreQueryPrefix: true }) || {};
};

export const getParentPath = (url, keyword) => {
	if (keyword) {
		return url.slice(0, url.indexOf(keyword) - 1);
	}
	return url.slice(0, url.lastIndexOf("/"));
};
