import { useEffect } from "react";
import markerSDK from "@marker.io/browser";

export default ({ env }) => {
	useEffect(() => {
		if (env.includes("test") || env.includes("canary") || env.includes("preprod")) {
			markerSDK.loadWidget({
				project: "64103aee817792df3894a461",
				silent: true,
			});
		} /*else if (env.includes("live")) {
			markerSDK.loadWidget({
				project: "64131ed6672d277c1a86bcf5",
				silent: true,
			});
		}*/
	}, [env]);
};
