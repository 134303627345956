import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import TripAdvisorRatingImage from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorRatingImage/TripAdvisorRatingImage";
import "./TripAdvisorRating.scss";

const getTripAdvisorStatusFromRating = rating => {
	if (rating >= 0 && rating <= 1) {
		return 1;
	} else if (rating > 1 && rating <= 2) {
		return 2;
	} else if (rating > 2 && rating <= 3) {
		return 3;
	} else if (rating > 3 && rating <= 4) {
		return 4;
	} else if (rating > 4 && rating <= 5) {
		return 5;
	}
	return 0;
};

const TripAdvisorRating = ({ rating = 0, ranking_string, reviewsCount, showRanking = false }) => {
	const ratingValueString =
		rating.toString().indexOf(".") === -1
			? rating + ",0"
			: rating.toString().replaceAll(".", ",");

	return (
		<div className="tripadvisor-rating" data-testid="tripadvisor-note">
			<div className="tripadvisor-rating__infos">
				<div className="tripadvisor-rating__note">
					{rating >= 0 && rating <= 5 ? ratingValueString : "0,0"}
				</div>
				<div className="tripadvisor-rating__status">
					<FormattedMessage
						id={`tripadvisor.status.${getTripAdvisorStatusFromRating(rating)}`}
					/>
				</div>
				<TripAdvisorRatingImage rating={rating} reviewsCount={reviewsCount} />
			</div>
			{showRanking && <p className="tripadvisor-rating__ranking">{ranking_string}</p>}
		</div>
	);
};

TripAdvisorRating.propTypes = {
	rating: PropTypes.number,
	ranking_string: PropTypes.string,
	reviewsCount: PropTypes.number,
	showRanking: PropTypes.bool,
};

export default memo(TripAdvisorRating);
