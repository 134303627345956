import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import "./MediaCarouselItem.scss";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import SimpleProgressiveImg from "app/pages/.shared/SimpleProgressiveImg";
import VideoSwitchThumbnail from "app/pages/.shared/Video/VideoSwitchThumbnail";

const IMG_SIZES_LARGE = {
	large: { width: 1200 },
	medium: { width: 1200 },
	xlarge: { width: 2000 },
};

const IMG_SIZES_SMALL = {
	small: { width: 500 },
	medium: { width: 500 },
};

const CLOUDINARY_OPTIONS = ["c_fill", "g_center"];

const MediaCarouselItem = ({
	photoUrl,
	resolution,
	videoPreviewUrl,
	title,
	isActive,
	author,
	videoThumbnail,
	videoUrl,
	videoType,
	photoThumbnail,
	onClick = () => {},
}) => {
	const [showAuthor, setShowAuthor] = useState(true);
	const handleClick = useCallback(() => {
		onClick(title);
	}, [title, onClick]);

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const onVideoStart = useCallback(() => {
		setShowAuthor(false);
	}, []);
	return (
		<div className="media-carousel-item">
			<div className="media-carousel-item__photo" onClick={handleClick}>
				{videoUrl ? (
					<VideoSwitchThumbnail
						resolution={resolution}
						onVideoStart={onVideoStart}
						sizes={isMobile ? IMG_SIZES_SMALL : IMG_SIZES_LARGE}
						videoType={videoType}
						videoUrl={videoUrl}
						videoPreviewUrl={photoUrl ? photoUrl : videoPreviewUrl}
						videoThumbnail={photoThumbnail ? photoThumbnail : videoThumbnail}
						isActive={isActive}
					/>
				) : (
					<SimpleProgressiveImg
						sizes={isMobile ? IMG_SIZES_SMALL : IMG_SIZES_LARGE}
						cloudinaryOptions={CLOUDINARY_OPTIONS}
						src={photoUrl}
						resolution={resolution}
						quality="q_auto:best"
					/>
				)}

				{!isActive && title && (
					<div className="media-carousel-item__footer">
						<Typography
							variant={TYPOGRAPHY_VARIANTS.XL2}
							isBold
							className="media-carousel-item__title"
						>
							{title}
						</Typography>
					</div>
				)}

				{author && isActive && showAuthor && (
					<div className="media-carousel-item__footer">
						<div className="media-carousel-item__copyright">© {author}</div>
					</div>
				)}
			</div>
		</div>
	);
};

MediaCarouselItem.propTypes = {
	videoPreviewUrl: PropTypes.string,
	photoUrl: PropTypes.string,
	title: PropTypes.string,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
	resolution: resolutionPropType,
	author: PropTypes.string,
	videoThumbnail: PropTypes.string,
	videoUrl: PropTypes.string,
	videoType: PropTypes.string,
	photoThumbnail: PropTypes.string,
};

export default memo(MediaCarouselItem);
