import { getStore } from "app/configureStore";
import env from "app/utils/env";

export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const getProfileApiUrl = () => {
	const state = getStore().getState();
	const shop = state.shop;
	return `${env("USER_AUTH_API_URL")}/${shop}`;
};

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 3;
export const THIRD_MERCHANDISING_POSITION = 7;
export const FOURTH_MERCHANDISING_POSITION = 11;

export const OAUTH_PARAMS = {};
