import { FETCH_PROFILE_SUCCESS, SET_PARTNER_DATA } from "app/actionTypes";
import isEmpty from "lodash/isEmpty";

export default (partnerData = {}, action) => {
	switch (action.type) {
		case SET_PARTNER_DATA:
			return { ...partnerData, ...action.data };
		case FETCH_PROFILE_SUCCESS: {
			const fetchedPartnerData = action.profile.partnerData;
			if (!isEmpty(fetchedPartnerData)) {
				return { ...partnerData, ...action.profile.partnerData };
			}
			return partnerData;
		}
		default:
			return partnerData;
	}
};
