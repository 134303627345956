import { memo } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";
import IconArrowLeft from "app/pages/.shared/static/icons/IconArrowLeft";
import IconArrowRight from "app/pages/.shared/static/icons/iconArrowRight";

const CarouselArrow = ({ orientation, className = "images-carousel" }) => {
	const isLeft = orientation === "left";

	return (
		<div className={classNames(`${className}__arrow`, `${className}__arrow-${orientation}`)}>
			{isLeft ? <IconArrowLeft /> : <IconArrowRight />}
		</div>
	);
};

CarouselArrow.propTypes = {
	orientation: PropTypes.string,
	className: PropTypes.string,
};

export default memo(CarouselArrow);
