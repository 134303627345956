export const FETCH_MERCHANDISINGS = "FETCH_MERCHANDISINGS";
export const FETCH_MERCHANDISINGS_REQUEST = "FETCH_MERCHANDISINGS_REQUEST";
export const FETCH_MERCHANDISINGS_SUCCESS = "FETCH_MERCHANDISINGS_SUCCESS";
export const FETCH_MERCHANDISINGS_FAILURE = "FETCH_MERCHANDISINGS_FAILURE";
export const FETCH_MERCHANDISING = "FETCH_MERCHANDISING";
export const FETCH_MERCHANDISING_REQUEST = "FETCH_MERCHANDISING_REQUEST";
export const FETCH_MERCHANDISING_SUCCESS = "FETCH_MERCHANDISING_SUCCESS";
export const FETCH_MERCHANDISING_FAILURE = "FETCH_MERCHANDISING_FAILURE";
export const FETCH_MERCHANDISING_LIGHT = "FETCH_MERCHANDISING_LIGHT";
export const FETCH_MERCHANDISING_LIGHT_REQUEST = "FETCH_MERCHANDISING_LIGHT_REQUEST";
export const FETCH_MERCHANDISING_LIGHT_SUCCESS = "FETCH_MERCHANDISING_LIGHT_SUCCESS";
export const FETCH_MERCHANDISING_LIGHT_FAILURE = "FETCH_MERCHANDISING_LIGHT_FAILURE";
export const SET_MERCH_PRODUCTS_FILTER_BY = "SET_MERCH_PRODUCTS_FILTER_BY";
export const UPDATE_CURRENT_MERCH_CODE = "UPDATE_CURRENT_MERCH_CODE";
export const UNSET_MERCH_PRODUCTS_FILTER_BY = "UNSET_MERCH_PRODUCTS_FILTER_BY";
