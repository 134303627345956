import { combineValidators, hasLengthGreaterThan, isRequiredIf } from "revalidate";
import { number, object, string } from "yup";
import { LIB_PHONE_NUMBER_TYPES } from "app/constants";
/* .../max is required to use the most accurate validation available */
import { validatePhoneIntl } from "app/utils/utils";
import { NAME_POLICY_REGEX } from "app/constants";

export const validatePhoneNumberType = (phoneNumberIntl, possibleTypes) => {
	if (typeof phoneNumberIntl !== "string") {
		return true; // if user wants to empty his number input it can be undefined
	}
	const isMatching = validatePhoneIntl(phoneNumberIntl, possibleTypes);

	return isMatching;
};

export const fields = [
	"name.firstName",
	"name.lastName",
	"name.gender",
	"birthDate.day",
	"birthDate.month",
	"birthDate.year",
	"nationality",
	"address.line1",
	"address.line2",
	"address.postCode",
	"address.city",
	"address.country",
	"address.phone",
	"passport.passportNumber",
	"passport.countryIssue",
	"passport.passportExpirationDate",
];

export const validate = combineValidators({
	name: {
		firstName: hasLengthGreaterThan(1)({
			message: { id: "error.firstName.minlength" },
		}),
		lastName: hasLengthGreaterThan(1)({
			message: { id: "error.lastName.minlength" },
		}),
	},
	birthDate: {
		day: isRequiredIf(values => values && (values.birthDate.month || values.birthDate.year))({
			message: { id: "error.birthdate.day" },
		}),
		month: isRequiredIf(values => values && (values.birthDate.day || values.birthDate.year))({
			message: { id: "error.birthdate.month" },
		}),
		year: isRequiredIf(values => values && (values.birthDate.day || values.birthDate.month))({
			message: { id: "error.birthdate.year" },
		}),
	},
});

export const defaultValues = {
	"honorific-prefix": "",
	"family-name": "",
	"given-name": "",
	birthdateDay: "",
	birthdateMonth: "",
	birthdateYear: "",
	nationality: "",
	"address-line1": "",
	"address-line2": "",
	"postal-code": "",
	"address-level2": "", // city
	"country-name": "",
	phone: "",
	// TODO SMS-OPTIN
	// phonemobile: "",
};

export const validationSchema = object().shape({
	"family-name": string()
		.min(2, { id: "error.lastName.minlength" })
		.test("nameRegex", { id: "error.lastName.invalid" }, value => {
			if (value && value.length > 0) {
				return (
					!NAME_POLICY_REGEX.SPECIAL_CHAR.test(value) &&
					!NAME_POLICY_REGEX.DIGIT.test(value)
				);
			}
			return true;
		}),
	"given-name": string()
		.min(2, { id: "error.firstName.minlength" })
		.test("nameRegex", { id: "error.firstName.invalid" }, value => {
			if (value && value.length > 0) {
				return (
					!NAME_POLICY_REGEX.SPECIAL_CHAR.test(value) &&
					!NAME_POLICY_REGEX.DIGIT.test(value)
				);
			}
			return true;
		}),
	birthdateDay: number().test("birthdateDay", { id: "error.birthdate.day" }, function(value) {
		const { birthdateMonth, birthdateYear } = this.parent;
		if (birthdateMonth !== undefined || birthdateYear !== undefined) {
			return value !== undefined;
		}
		return true;
	}),
	birthdateMonth: number().test("birthdateMonth", { id: "error.birthdate.month" }, function(
		value
	) {
		const { birthdateDay, birthdateYear } = this.parent;
		if (birthdateDay !== undefined || birthdateYear !== undefined) {
			return value !== undefined;
		}
		return true;
	}),
	birthdateYear: number().test("birthdateYear", { id: "error.birthdate.year" }, function(value) {
		const { birthdateDay, birthdateMonth } = this.parent;
		if (birthdateDay !== undefined || birthdateMonth !== undefined) {
			return value !== undefined;
		}
		return true;
	}),
	phone: string()
		.test("phone (mobile or a fixed-line number)", { id: "error.phone.invalid" }, numberIntl =>
			validatePhoneNumberType(numberIntl)
		)
		.when("sms", {
			is: true,
			then: schema =>
				schema
					.required({
						id: "error.phone.when.sms.is.activated",
					})
					.test(
						"should be a mobile phone number",
						{ id: "error.phone.when.sms.is.activated" },
						numberIntl =>
							validatePhoneNumberType(numberIntl, [
								LIB_PHONE_NUMBER_TYPES.PERSONAL_NUMBER,
								LIB_PHONE_NUMBER_TYPES.MOBILE,
							])
					),
		}),
});
