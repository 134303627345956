import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DontLeave from "app/pages/.shared/DontLeave";
import RelativeLink from "app/pages/.shared/RelativeLink";
import "./TechnicalErrorDefault.scss";
import AlertMessage from "app/pages/.shared/AlertMessage";

const TechnicalErrorDefault = ({ brandDisplayName, errorMessage, fallbackRedirectUrl }) => {
	return (
		<div className="technical-error">
			<div className="technical-error__intro">
				{errorMessage && <AlertMessage message={errorMessage} />}

				<FormattedMessage
					id="technical.error.default.message"
					values={{ brandName: brandDisplayName }}
				/>

				<DontLeave>
					<RelativeLink
						to={{ pathname: fallbackRedirectUrl }}
						variant="primary"
						enableLoading
						data-testid={"go-to-fallback-url-button"}
					>
						<FormattedMessage id="confirmation.leave.button.label" />
					</RelativeLink>
				</DontLeave>
			</div>
		</div>
	);
};

TechnicalErrorDefault.propTypes = {
	brandDisplayName: PropTypes.string,
	errorMessage: PropTypes.string,
	fallbackRedirectUrl: PropTypes.string,
};

export default TechnicalErrorDefault;
