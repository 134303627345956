import { FETCH_NAVLOGS_SUCCESS } from "app/pages/Listing/NavLogs/navLogActionTypes";
import { computedProductDates } from "app/utils/utils";
import { LOGOUT } from "app/actionTypes";

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_NAVLOGS_SUCCESS: {
			const navLog = action.res.data;
			let newNavLogs = [];

			if (navLog && navLog.length > 0) {
				newNavLogs = computedProductDates(navLog);
			}
			return newNavLogs;
		}
		case LOGOUT:
		default: {
			return state;
		}
	}
};
