import { connect } from "react-redux";
import SignInWithGoogleButton from "app/pages/Auth/Google/SignInWithGoogleButton";
import { bindActionCreators } from "redux";
import { authSuccess, authWithGoogle } from "app/pages/Auth/AuthActions";

const mapStateToProps = state => {
	const googleClientId = state?.envVars?.GOOGLE_CLIENT_ID;
	return {
		source: state.marketing.source,
		googleClientId,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			authWithGoogle,
			authSuccess,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignInWithGoogleButton);
