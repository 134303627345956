import {
	CHECK_AVAILABILITIES_REQUEST,
	CHECK_AVAILABILITIES_SUCCESS,
	CLEAR_BOOKING,
	CLEAR_BOOKING_QUOTATION_PAYMENT,
	FETCH_FLIGHT_OPTIONS_REQUEST,
	FETCH_FLIGHT_OPTIONS_SUCCESS,
	FETCH_PROFILE_REQUEST,
	PRE_BOOK_REQUEST,
	RESTORE_BOOKING_SESSION_REQUEST,
	RESTORE_BOOKING_SESSION_SUCCESS,
	SEND_PROMOTION_CODE_SUCCESS,
	UPDATE_BOOKING,
	UPDATE_BOOKING_ACTIVITY,
	UPDATE_BOOKING_ACTIVITY_DATE,
	UPDATE_BOOKING_CHILDREN_BIRTHDATES,
	UPDATE_BOOKING_INFANTS_BIRTHDATES,
	UPDATE_BOOKING_PASSENGER,
	UPDATE_SDP_FORM_DESTINATION,
} from "app/actionTypes";
import {
	OFFER_TYPES,
	PASSENGER_TYPE,
	PAYMENT_COUPON_TYPE,
	PAYMENT_METHODS,
	PAYMENT_TERMS,
	QUOTATION_PAYLOAD_PATHS,
	QUOTATION_STATUS_VALIDE,
	STOPOVER_DIRECTIONS,
} from "app/constants";

import { SAVE_QUOTATION_PAYLOAD } from "app/pages/Booking/bookingActionTypes";
import get from "lodash/get";
import set from "lodash/set";
import remove from "lodash/remove";
import { FETCH_USABLE_COUPONS_SUCCESS } from "app/pages/Account/MyCoupons/couponActionTypes";
import { calculateUsableCreditsTotal } from "app/pages/Account/MyCoupons/couponSelector";
import {
	CHECK_SDP_AVAILABILITIES_REQUEST,
	CHECK_SDP_AVAILABILITIES_SUCCESS,
	FETCH_SDP_QUOTE_REQUEST,
	FETCH_SDP_QUOTE_SUCCESS,
	SAVE_SELECTED_SDP_PRODUCT,
} from "app/pages/SmartDP/smartDPActionTypes";
import {
	CHECK_STOPOVER_AVAILABILITIES_SUCCESS,
	FETCH_STOPOVER_PACKAGE_REQUEST,
	FETCH_STOPOVER_PACKAGE_SUCCESS,
	FETCH_STOPOVER_QUOTE_REQUEST,
	FETCH_STOPOVER_QUOTE_SUCCESS,
} from "app/pages/Stopover/stopoverActionTypes";

export const INITIAL_BOOKING = {
	offer: {},
	departureCity: {},
	departureDate: undefined,
	endDate: undefined,
	price: undefined,
	publicPrice: undefined,
	adults: 2,
	children: 0,
	infants: 0,
	duration: {},
	transfer: {},
	hasSpecialOffer: true,
	accommodation: {},
	rentalAccommodation: {},
	board: {},
	credits: 0,
	flight: {},
	luggage: {
		quantity: 0,
		maxWeightPerBaggage: 0,
	},
	activities: [],
	coupon: undefined,
	paymentType: PAYMENT_METHODS.CARD,
	insurance: {},
	// on souhaite persister les données saisies si on revient sur la page paiement avant d'avoir la confirmation de réservation
	// exemple : reload, annulation depuis un partenaire externe et redirection sur la page de paiement, erreur de book
	contact: undefined,
	paymentMode: PAYMENT_TERMS["1x"],
	passengers: [
		{
			type: PASSENGER_TYPE.ADULT,
			index: 1,
		},
		{
			type: PASSENGER_TYPE.ADULT,
			index: 2,
		},
	],
	quotationPayloads: [],
	insuranceFlex: undefined,
	occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }], // SDP
	cabin: {}, // SDP
	destinationResort: {}, // SDP,
	stopoverCityId: undefined,
	stopoverDirection: STOPOVER_DIRECTIONS.OUTBOUND,
	stopoverDuration: undefined,
};

export default (booking = INITIAL_BOOKING, action) => {
	switch (action.type) {
		case UPDATE_SDP_FORM_DESTINATION: {
			return { ...booking, destinationResort: action.destinationResort };
		}
		case FETCH_STOPOVER_PACKAGE_REQUEST:
		case FETCH_STOPOVER_QUOTE_REQUEST: {
			return {
				...booking,
				flight: {},
				stopoverDuration: undefined,
			};
		}
		case FETCH_STOPOVER_PACKAGE_SUCCESS:
		case FETCH_STOPOVER_QUOTE_SUCCESS: {
			return {
				...booking,
				flight: action.res.data.packages?.flights?.find(flight => flight.included),
				stopoverDuration: action.res.data.packages?.accommodationNights,
			};
		}
		case SAVE_QUOTATION_PAYLOAD: {
			const localStorageQuotationPayloads = JSON.parse(
				localStorage.getItem(`reduxPersist:${action.shop}:booking`)
			);

			const quotationPayloads = get(localStorageQuotationPayloads, "quotationPayloads")
				? get(localStorageQuotationPayloads, "quotationPayloads").filter(
						quotationPayload => quotationPayload.productUri !== action.product.uri
				  )
				: [];

			const QUOTATION_PAYLOADS_MAX_LENGTH = 500;
			const quotationPayloadToSave = QUOTATION_PAYLOAD_PATHS.reduce(
				(acc, path) => set(acc, path, get(action.quotationpayload, path)),
				{
					productUri: action.product.uri,
					isChildrenBirthdateRequired: action.product.isChildrenBirthdateRequired,
				}
			);
			return {
				...booking,
				quotationPayloads: [
					...(quotationPayloads.length > QUOTATION_PAYLOADS_MAX_LENGTH
						? quotationPayloads.slice(1)
						: quotationPayloads),
					quotationPayloadToSave,
				],
			};
		}
		case CLEAR_BOOKING:
			return {
				...INITIAL_BOOKING,
				adults: booking.adults,
				infants: booking.infants,
				children: booking.children,
				passengers: booking.passengers || [],
				quotationPayloads: booking.quotationPayloads,
			};
		case UPDATE_BOOKING_PASSENGER: {
			const passengers = booking.passengers.filter(passenger => {
				return passenger.type !== action.passengerType;
			});

			const matchedPassengers = booking.passengers.filter(passenger => {
				return passenger.type === action.passengerType;
			});

			for (let i = 0; i < action.count; i++) {
				passengers.push(
					Object.assign({}, matchedPassengers[i], {
						index: i + 1,
						type: action.passengerType,
					})
				);
			}

			const bookingPart = {
				passengers,
			};

			if (action.passengerType === PASSENGER_TYPE.ADULT) {
				bookingPart.adults = action.count;
			} else if (action.passengerType === PASSENGER_TYPE.CHILD) {
				bookingPart.children = action.count;
			} else if (action.passengerType === PASSENGER_TYPE.INFANT) {
				bookingPart.infants = action.count;
			}

			return Object.assign({}, booking, {
				...bookingPart,
			});
		}
		case UPDATE_BOOKING_CHILDREN_BIRTHDATES: {
			const passengers = booking.passengers.concat([]);
			const child = passengers.find(
				passenger =>
					passenger.type === PASSENGER_TYPE.CHILD && passenger.index === action.index + 1
			);

			child.birthdate = action.birthdate;

			return Object.assign({}, booking, {
				passengers,
			});
		}
		case FETCH_PROFILE_REQUEST: {
			return {
				...booking,
				contact: undefined,
			};
		}
		case UPDATE_BOOKING_INFANTS_BIRTHDATES: {
			const passengers = booking.passengers.concat([]);
			const infant = passengers.find(
				passenger =>
					passenger.type === PASSENGER_TYPE.INFANT && passenger.index === action.index + 1
			);
			infant.birthdate = action.birthdate;

			return Object.assign({}, booking, {
				passengers,
			});
		}
		case SAVE_SELECTED_SDP_PRODUCT: {
			return {
				...booking,
				accommodation: {},
				board: {},
			};
		}
		case RESTORE_BOOKING_SESSION_REQUEST:
		case FETCH_SDP_QUOTE_REQUEST:
		case CLEAR_BOOKING_QUOTATION_PAYMENT:
		case CHECK_SDP_AVAILABILITIES_REQUEST:
		case CHECK_AVAILABILITIES_REQUEST:
			// TODO composer les reducers : avoir un reducer pour booking quotation
			return Object.assign({}, booking, {
				accommodation: {},
				board: {},
				flight: {},
				insurance: {},
				paymentType: PAYMENT_METHODS.CARD,
				paymentMode: PAYMENT_TERMS["1x"],
				credits: 0,
				luggage: {
					quantity: 0,
					maxWeightPerBaggage: 0,
				},
				coupon: undefined,
				transfer: {},
				activities: [],
				insuranceFlex: undefined,
			});
		case UPDATE_BOOKING:
			return Object.assign({}, booking, action.booking);
		case RESTORE_BOOKING_SESSION_SUCCESS: {
			const {
				quoteRequest = {},
				quoteResponse = {},
				preBookRequest = {},
				preBookResponse = {},
				bookRequest = {},
				flightOptionsResponse = {},
				promoCodeResponse = {},
			} = action.res.data;

			// contact

			const customer = bookRequest?.customer || {};

			const contact = {
				name: {
					gender: customer?.civility,
					lastName: customer?.lastName,
					firstName: customer?.firstName,
				},
				email: customer?.email,
				phone: customer?.phone,
				address: {
					line1: customer?.address?.address1,
					line2: customer?.address?.address2,
					postCode: customer?.address?.postCode,
					city: customer?.address?.city,
					country: customer?.address?.country,
					phone: customer?.phone,
				},
			};

			// passengers

			const passengers = bookRequest.passengers?.map((passenger, index) => {
				// On décrémente le mois car le service de connectivité utilise 1 pour janvier par exemple

				const passengerData = {
					index: index + 1,
					type: passenger.type,
					name: {
						gender: passenger.civility,
						firstName: passenger.firstName,
						lastName: passenger.lastName,
					},
					frequentFlyerTravellerCardNumber: passenger.frequentFlyerTravellerCardNumber,
				};

				if (passenger.dateOfBirth) {
					passengerData.birthdate = {
						...passenger.dateOfBirth,
						month: passenger.dateOfBirth.month - 1,
					};
				}

				return passengerData;
			});

			// accommodation price and label

			const accommodationBoardCode = preBookRequest.accommodationBoardCode;

			const accommodation =
				quoteResponse.accommodations?.accommodationItems?.find(accommodationItem => {
					return accommodationItem.codes?.find(
						code => code.code === accommodationBoardCode
					);
				}) || {};

			const accommodationItemsWithDescription = accommodation?.items?.map(item => {
				const description = quoteResponse.accommodations?.accommodationItemDescriptions?.find(
					description => description.accommodationItemCode === item.accommodationItemCode
				);
				return {
					...item,
					...description,
				};
			});

			// board price and label

			const boardData =
				accommodation?.codes?.find(board => {
					return board.code === accommodationBoardCode;
				}) || {};

			const boardDescription =
				quoteResponse.accommodations?.boards?.find(
					description => description.boardCode === boardData?.boardCode
				) || {};

			// flight price and label

			const flight = quoteResponse.flights?.find(
				flight => flight.code === preBookRequest.flight
			);

			// transfer

			const transfer =
				flightOptionsResponse?.transfers?.find(
					transfer => transfer.code === preBookRequest.transfer
				) || {};

			// insurance

			const insurance = preBookResponse.insurances?.insurancesItem?.find(
				insurance => insurance.code === bookRequest.insurance
			);

			// coupon

			const coupon = promoCodeResponse?.value > 0 && PAYMENT_COUPON_TYPE.PROMOCODE;

			return {
				...INITIAL_BOOKING,
				contact,
				adults: quoteRequest.adults,
				children: quoteRequest.children,
				infants: quoteRequest.infants,
				duration: {
					value: quoteRequest.duration,
				},
				departureCity: {
					code: quoteRequest.departureCityCode,
				},
				offer: {
					type: quoteRequest.departureCityCode
						? OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION
						: OFFER_TYPES.ACCOMODATION_ONLY,
				},
				departureDate: quoteRequest.departureDate,
				price: quoteRequest.price,
				accommodation: {
					...accommodation,
					items: accommodationItemsWithDescription,
				},
				board: {
					...boardDescription, // boardDescription have to be first because boardData.included should override boardDescription.included (which have to be removed in the json)
					...boardData,
				},
				flight: flight || {},
				luggage: {
					quantity: preBookRequest?.flightOptions?.flightBagageOption?.quantity,
					maxWeightPerBaggage:
						preBookRequest?.flightOptions?.flightBagageOption?.maxWeightPerBaggage,
				},
				transfer,
				passengers,
				coupon,
				activities: preBookRequest.activities || [],
				insurance: insurance || {},
				paymentType: bookRequest.payment?.type,
				paymentMode: bookRequest.payment?.terms,
			};
		}
		case FETCH_FLIGHT_OPTIONS_REQUEST: {
			// on reinitialise la sélection du transfer lorsque l'on recupère les transferts du vol selectionné
			return {
				...booking,
				transfer: undefined,
			};
		}
		case FETCH_FLIGHT_OPTIONS_SUCCESS: {
			const data = action.res.data;

			const hasPricePerBooking =
				data?.flightOptions?.flightBagageOption?.pricePerBooking >= 0;

			const isLuggageIncluded = data?.flightOptions?.flightBagageOption?.included === true;

			return {
				...booking,
				// Si on change de vol, il faut reinitialiser le nombre de bagages a 0 si les bagages sont inclus dans le nouveau vol
				// ou si on passe d'un prix de bagage par personne à un prix de baggages par booking et que le nombre de bagage selectionne est different
				// du nombre de bagages max
				luggage: {
					quantity:
						(hasPricePerBooking &&
							booking.luggage?.quantity !== booking.adults + booking.children) ||
						isLuggageIncluded
							? 0
							: booking.luggage?.quantity,
					maxWeightPerBaggage: booking.luggage?.maxWeightPerBaggage,
				},

				transfer: data.transfers && data.transfers[0],
			};
		}
		case CHECK_STOPOVER_AVAILABILITIES_SUCCESS: {
			const data = action.res.data;
			if (QUOTATION_STATUS_VALIDE.includes(data.status)) {
				return {
					...booking,
					accommodationNights: data?.accommodationNights,
				};
			}
			return {
				...booking,
			};
		}
		case CHECK_SDP_AVAILABILITIES_SUCCESS:
		case CHECK_AVAILABILITIES_SUCCESS: {
			const data = action.res.data;
			const selectedActivities = [...booking.activities];

			if (QUOTATION_STATUS_VALIDE.includes(data.status)) {
				const quoteActivities = data.activities || [];

				// Pour chaque activité, on regarde si le nombre de participants inclus pour la date suggérée est > 0
				// Si oui, alors on ajoute l'activité dans la liste des activités sélectionnées avec le nombre de chaque participant
				// initialisé au nombre de participants inclus

				quoteActivities.forEach(quoteActivity => {
					const suggestedDate = quoteActivity.suggestedDate;

					const guestsOfSuggestedDate = quoteActivity.guestsPerDay[suggestedDate] || [];

					const includedGuest = guestsOfSuggestedDate.find(guestOfSuggestedDate => {
						return guestOfSuggestedDate.includedGuest > 0;
					});

					if (includedGuest) {
						const guests = [];

						guestsOfSuggestedDate.forEach(guestOfSuggestedDate => {
							const isGuestIncluded = guestOfSuggestedDate.includedGuest > 0;

							if (isGuestIncluded) {
								guests.push({
									code: guestOfSuggestedDate.code,
									guestsCount: guestOfSuggestedDate.includedGuest,
								});
							}
						});

						const selectedActivity = selectedActivities.find(
							activity => activity.code === quoteActivity.code
						);

						if (selectedActivity) {
							selectedActivity.guests = guests;
							selectedActivity.date = suggestedDate;
						} else {
							selectedActivities.push({
								code: quoteActivity.code,
								name: quoteActivity.name,
								date: suggestedDate,
								guests: guests,
							});
						}
					}
				});
			}

			return {
				...booking,
				activities: selectedActivities,
				// preselectionne le vol inclus
				flight: data.flights?.find(flight => flight.included),
			};
		}
		case UPDATE_BOOKING_ACTIVITY: {
			const code = action.activity.code;
			const activityName = action.activity.name;
			const guestsCount = action.activity.guest.guestsCount;
			const guestCode = action.activity.guest.code;

			const activities = booking.activities.concat([]);

			const activitytoUpdate = activities.find(activity => {
				return activity.code === code;
			});

			if (activitytoUpdate) {
				const guestToUpdate = activitytoUpdate.guests.find(guest => {
					return guest.code === guestCode;
				});

				if (guestToUpdate) {
					guestToUpdate.guestsCount = guestsCount;
				} else {
					activitytoUpdate.guests.push({
						code: guestCode,
						guestsCount,
					});
				}

				const activitytoUpdateGuestcount = activitytoUpdate.guests.reduce(
					(acc, { guestsCount }) => {
						return acc + guestsCount;
					},
					0
				);

				if (activitytoUpdateGuestcount === 0) {
					remove(activities, activityToRemove => {
						return activityToRemove.code === activitytoUpdate.code;
					});
				}
			} else {
				activities.push({
					code: code,
					name: activityName,
					guests: [
						{
							code: guestCode,
							guestsCount,
						},
					],
				});
			}

			return { ...booking, activities };
		}

		case UPDATE_BOOKING_ACTIVITY_DATE: {
			const { code, date } = action.payload;

			const selectedActivities = [...booking.activities];

			const activitytoUpdate = selectedActivities.find(activity => {
				return activity.code === code;
			});

			if (activitytoUpdate) {
				activitytoUpdate.date = date;
			}
			return { ...booking, activities: selectedActivities };
		}
		case PRE_BOOK_REQUEST: {
			return {
				...booking,
			};
		}
		case SEND_PROMOTION_CODE_SUCCESS: // when only promotioncode is enabled, coupon should be set to PROMOCODE
		case FETCH_USABLE_COUPONS_SUCCESS: {
			const credit = get(action, "res.data.credit", {});
			const totalUsableCredit = calculateUsableCreditsTotal(
				get(credit, "generic.usable", []),
				get(credit, "travelback.usable", []),
				get(credit, "sponsorship.usable", [])
			);

			if (!booking.coupon) {
				return {
					...booking,
					...{
						coupon:
							totalUsableCredit > 0
								? PAYMENT_COUPON_TYPE.CREDIT
								: PAYMENT_COUPON_TYPE.PROMOCODE,
					},
				};
			}

			return booking;
		}
		case FETCH_SDP_QUOTE_SUCCESS: {
			// On marque le vol inclus retourné par la sdp/quote comme le vol sélectionné afin que les dates du sdp search summary soit valorisé avec les bonnes infos de dates
			const flights = get(action, "res.data.flights", []);
			const includedFlight = flights.find(flight => flight.included);

			return {
				...booking,
				flight: includedFlight,
				offer: {
					type: OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION,
				},
				// equivalent de i.so au niveau de chaque date de depart du moteur de recherche
				hasSpecialOffer: get(action, "res.data.specialOffer"),
			};
		}
		default:
			return booking;
	}
};
