import { createBrowserHistory } from "history";

let history;

export default shop => {
	if (!history) {
		history = createBrowserHistory({ basename: `/${shop}` });

		history.listen(location => {
			// GEstion des ancres dans les urls où on doit scroller vers l'élément de la page
			// @see https://medium.com/@gajus/making-the-anchor-links-work-in-spa-applications-618ba2c6954a
			const id = location.hash.replace("#", "");
			const element = document.getElementById(id);
			const offset = 65; // petit offset d'ajustement pour bien tomber sur l'element (ici c'est la hauteur du header)
			if (element) {
				scrollTo(0, window.pageYOffset + element.getBoundingClientRect().top - offset);
			}
		});
	}

	return history;
};
