import { useContext } from "react";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import SmartDPMarketingText from "app/pages/SmartDP/SmartDPMarketingHeadline/SmartDPMarketingHeadline";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPartnerMarketing } from "app/reducers/partnerSelector";
import { messagePropType } from "app/utils/propTypes";

const SmartDPMarketingHeadlineContainer = ({
	heroTitleMobile,
	heroSubtitleMobile,
	heroTitleDesktop,
	heroSubtitleDesktop,
	heroTextAlign,
}) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;
	const title = isMobile ? heroTitleMobile : heroTitleDesktop;
	const subtitle = isMobile ? heroSubtitleMobile : heroSubtitleDesktop;
	return <SmartDPMarketingText title={title} subtitle={subtitle} textAlign={heroTextAlign} />;
};

SmartDPMarketingHeadlineContainer.propTypes = {
	heroTitleMobile: messagePropType,
	heroSubtitleMobile: messagePropType,
	heroTitleDesktop: messagePropType,
	heroSubtitleDesktop: messagePropType,
	heroTextAlign: PropTypes.string,
};

const mapStateToProps = (state, props) => {
	const marketing = getPartnerMarketing(state);
	const {
		heroTitleDesktop,
		heroTitleMobile,
		heroSubtitleDesktop,
		heroSubtitleMobile,
		heroTextAlign,
	} = props;
	return {
		heroTitleDesktop: heroTitleDesktop || marketing?.heroTitleDesktop,
		heroTitleMobile: heroTitleMobile || marketing?.heroTitleMobile,
		heroSubtitleDesktop: heroSubtitleDesktop || marketing?.heroSubtitleDesktop,
		heroSubtitleMobile: heroSubtitleMobile || marketing?.heroSubtitleMobile,
		heroTextAlign: heroTextAlign || marketing?.heroTextAlign,
	};
};

export default connect(mapStateToProps)(SmartDPMarketingHeadlineContainer);
