import { connect } from "react-redux";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import PropTypes from "prop-types";
import MarketingBanner from "app/pages/Header/MarketingBanner/MarketingBanner";

const MarketingBannerContainer = ({ banners = [] }) => {
	return <MarketingBanner banners={banners} />;
};

const mapStateToProps = state => {
	return {
		userIsConnected: isAuthenticated(state),
	};
};

MarketingBannerContainer.propTypes = {
	banners: PropTypes.array,
};

export default connect(mapStateToProps)(MarketingBanner);
