import { UPDATE_THEME } from "app/actionTypes";

export default function themeReducer(
	theme = {
		colors: {},
	},
	action
) {
	switch (action.type) {
		case UPDATE_THEME:
			return {
				...action.data,
			};
		default:
			return theme;
	}
}
