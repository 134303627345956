import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import FacebookContainer from "app/pages/Auth/Facebook/FacebookContainer.jsx";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import PasswordPolicyValidation from "app/pages/Auth/Signup/PasswordPolicyValidation";
import { ALERT_TYPE, PASSWORD_LIMIT_CHARS, PASSWORD_POLICY_REGEX, RESOLUTION } from "app/constants";
import AlertMessage from "app/pages/.shared/AlertMessage";
import CheckboxFormik from "app/pages/.shared/form/CheckboxFormik";
import InputFormik from "app/pages/.shared/form/InputFormik";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import { boolean, object, string } from "yup";
import { sendTagOnEmailSignupError } from "app/utils/analytics";
import useShowPassword from "app/utils/hooks/useShowPassword";

import "../auth.scss";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import { useCallback, useContext, useRef, useState } from "react";
import SignInWithGoogleButtonContainer from "app/pages/Auth/Google/SignInWithGoogleButtonContainer";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import {
	arrow,
	flip,
	FloatingArrow,
	FloatingPortal,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
} from "@floating-ui/react";
import AppGlobalsContext from "app/AppGlobalsContext";

const validationSchema = object().shape({
	email: string()
		.required({ id: "error.email.required" })
		.email({ id: "error.email.format" }),
	password: string()
		.min(PASSWORD_LIMIT_CHARS, { id: "auth.password.policy.not.valid" })
		.required({ id: "error.password.required" })
		.test(
			"atLeastOneUppercase",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.UPPERCASE.test(value)
		)
		.test(
			"atLeastOneDigit",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.DIGIT.test(value)
		)
		.test(
			"atLeastOneSpecialChar",
			{ id: "auth.password.policy.not.valid" },
			value => value && value.length > 0 && PASSWORD_POLICY_REGEX.SPECIAL_CHAR.test(value)
		),
	terms: boolean()
		.required({ id: "error.must.accept.terms" })
		.oneOf([true], { id: "error.must.accept.terms" }),
});

const Signup = ({
	handleSubmit,
	showTopFacebook = true,
	facebookOnSuccess,
	onToogleSigninForm,
	cnilDocumentName,
	confidentialiteDocumentName,
	signupButtonLabel = <FormattedMessage id="auth.sign.up.button.label" />,
	headline = <FormattedMessage id="auth.signup.headline" />,
	footer = null,
}) => {
	const { resolution } = useContext(AppGlobalsContext);

	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const [icon, showPassword] = useShowPassword();
	const { track } = useAnalytics();

	const handleCheckClick = useCallback(() => {
		track("signup_click_email");
	}, []);

	const arrowRef = useRef(null);

	const [openDropdownMenu, toggleDropdownMenu] = useState(false);

	const { context, x, y, refs, strategy } = useFloating({
		placement: isMobile ? "top" : "left",
		strategy: "absolute",
		open: openDropdownMenu,
		onOpenChange: toggleDropdownMenu,
		middleware: [
			offset(10),
			shift(),
			flip(),
			arrow({
				element: arrowRef,
			}),
		],
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);

	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

	return (
		<div className="auth">
			<div className="auth__main">
				<div className="auth__switch">
					<div
						className="auth__switch__item auth__switch__item--active"
						data-testid="switch-to-signUp"
					>
						<Typography
							variant={TYPOGRAPHY_VARIANTS.REGULAR}
							variantLG={TYPOGRAPHY_VARIANTS.LARGE}
							isBold
						>
							<FormattedMessage id="home.signup.button.label" />
						</Typography>
					</div>
					<div
						className="auth__switch__item"
						onClick={onToogleSigninForm}
						data-testid="switch-to-login"
					>
						<Typography
							variant={TYPOGRAPHY_VARIANTS.REGULAR}
							variantLG={TYPOGRAPHY_VARIANTS.LARGE}
						>
							<FormattedMessage id="home.signin.button.label" />
						</Typography>
					</div>
				</div>

				{headline && (
					<Typography
						variant={TYPOGRAPHY_VARIANTS.LARGE}
						isBold
						className="auth__headline"
						data-testid="auth-headline"
					>
						{headline}
					</Typography>
				)}

				<Formik
					initialValues={{
						email: "",
						password: "",
						terms: false,
					}}
					onSubmit={handleSubmit}
					validateOnChange={false}
					validateOnBlur={false}
					validationSchema={validationSchema}
				>
					{({
						errors = {},
						values = {},
						isSubmitting,
						setErrors,
						setSubmitting,
						setStatus,
						submitCount,
					}) => {
						return (
							<Form
								noValidate
								className="auth__form"
								onChange={() => {
									setStatus({});
								}}
							>
								<FormErrorMessages
									errors={errors}
									isSubmitting={isSubmitting}
									submitCount={submitCount}
									onError={() =>
										sendTagOnEmailSignupError({
											status: "",
											error: errors[Object.keys(errors)[0]]?.id,
										})
									}
								/>
								<InputFormik
									type="email"
									name="email"
									id="auth-email"
									data-testid="email-input"
									label={<FormattedMessage id="auth.email.label" />}
								/>

								<div ref={refs.setReference} {...getReferenceProps({})}>
									<InputFormik
										type={showPassword ? "text" : "password"}
										name="password"
										id="auth-password"
										data-testid="password-input"
										label={<FormattedMessage id="auth.password.label" />}
										className="auth__password"
										note={icon}
									/>
								</div>

								<FloatingPortal>
									{openDropdownMenu && values.password && (
										<>
											<div
												ref={refs.setFloating}
												className="auth__dropdown"
												{...getFloatingProps({
													style: {
														position: strategy,
														left: x ?? "",
														top: y ?? "",
													},
												})}
											>
												<FloatingArrow
													ref={arrowRef}
													context={context}
													fill={"white"}
													strokeWidth={1}
													className="date-selector-input__popper-arrow"
												/>
												<PasswordPolicyValidation value={values.password} />
											</div>
										</>
									)}
								</FloatingPortal>

								<div className="auth__accept-cgv">
									<Field
										component={CheckboxFormik}
										name="terms"
										id="accept-terms"
										grouped={false}
									>
										<div className="auth__terms" data-testid="auth-terms">
											<FormattedMessage
												id="auth.terms.label"
												values={{
													cguLink: (
														<a
															className="relative-link"
															rel="noopener noreferrer"
															target="_blank"
															href={confidentialiteDocumentName}
														>
															<FormattedMessage id="auth.terms.label.cgu.link.label" />
														</a>
													),
													confidentialiteLink: (
														<a
															className="relative-link"
															rel="noopener noreferrer"
															target="_blank"
															href={confidentialiteDocumentName}
														>
															<FormattedMessage id="auth.terms.label.confidentialite.link.label" />
														</a>
													),
												}}
											/>
										</div>
									</Field>
									{cnilDocumentName && (
										<a
											rel="noopener noreferrer"
											target="_blank"
											href={cnilDocumentName}
											data-testid="cnil-document"
											className="auth__cnil-document"
										>
											<IconInfo width={12} height={12} />
										</a>
									)}
								</div>
								<div className="auth__action">
									{errors._error && (
										<AlertMessage
											alertType={ALERT_TYPE.ERROR}
											message={<FormattedMessage id={errors._error.id} />}
										/>
									)}
									<Button
										className="auth__button"
										submit
										variant="primary"
										loading={isSubmitting}
										data-testid="signup-button"
										onClick={handleCheckClick}
									>
										{signupButtonLabel}
									</Button>
								</div>

								<div className="auth__separator">
									<FormattedMessage id="general.ou" />
								</div>

								<div className="auth__google">
									<SignInWithGoogleButtonContainer
										uxMode={"signup"}
										onAuthSuccess={facebookOnSuccess}
										onAuthError={() => {
											setErrors({ _error: { id: "error.generic" } });
											setSubmitting(false);
										}}
									/>
								</div>

								{showTopFacebook && (
									<div className="auth__facebook" data-testid="auth-facebook-top">
										<FacebookContainer
											signup
											onSuccess={facebookOnSuccess}
											onError={() => {
												setErrors({ _error: { id: "error.generic" } });
												setSubmitting(false);
											}}
										/>
									</div>
								)}
							</Form>
						);
					}}
				</Formik>
				{footer && footer.props?.children && <div className="auth__footer">{footer}</div>}
			</div>
		</div>
	);
};

Signup.propTypes = {
	signupButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	showTopFacebook: PropTypes.bool,
	cnilDocumentName: PropTypes.string,
	confidentialiteDocumentName: PropTypes.string,
	facebookOnSuccess: PropTypes.func,
	onToogleSigninForm: PropTypes.func,
	handleSubmit: PropTypes.func,
};

export default Signup;
