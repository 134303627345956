import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";
import isEmpty from "lodash/isEmpty";

const initialState = {
	dvaf: {}, // Bloc DVAF (footer) quotation page
	cgv: undefined, // Terms & conditions
	cnil: undefined, // Cnil
	confidentialite: undefined, // Policy and Terms of use
	insurances: {},
	isInformationStandardFormHiddenOnHotelOnly: false,
	legales: undefined, // Legales notices
	payment: {},
};

export default (documents = initialState, action) => {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			const documents = action.res.data.documents || {};
			const paymentDocuments = documents.payment;

			const newState = {
				...initialState,
				...documents,
				dvaf: action.res.data.dvaf || {},
				cnil: documents.cnil && (documents.cnil.url || documents.cnil.file),
				confidentialite:
					documents.confidentialite &&
					(documents.confidentialite.url || documents.confidentialite.file),
			};

			if (!isEmpty(paymentDocuments)) {
				Object.keys(paymentDocuments).forEach(document => {
					if (paymentDocuments[document].url) {
						newState.payment[document] = paymentDocuments[document].url;
					} else if (paymentDocuments[document].file) {
						newState.payment[document] = paymentDocuments[document].file;
					}
				});
			}

			return newState;
		}
		default:
			return documents;
	}
};
