import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
	getFallbackRedirectUrl,
	getPartnerMarketingHomeBlocks,
	getWebsiteTunnels,
} from "app/reducers/partnerSelector";
import { isAbsoluteUrl } from "app/utils/urlDataExtractor";
import { useEffect, useState } from "react";
import HomeLayoutContainer from "app/pages/HomeLayout/HomeLayoutContainer";

const RootContainer = props => {
	const { websiteTunnels = [], fallbackUrl } = props;

	const [
		shouldRedirectToExternalFallbackUrl,
		toggleShouldRedirectToExternalFallbackUrl,
	] = useState(false);

	useEffect(() => {
		if (websiteTunnels.length === 0 && isAbsoluteUrl(fallbackUrl)) {
			window.location = fallbackUrl;
			toggleShouldRedirectToExternalFallbackUrl(true);
		}
	}, []);

	if (!shouldRedirectToExternalFallbackUrl) {
		if (websiteTunnels.length === 0 && fallbackUrl && fallbackUrl !== "/") {
			return (
				<Navigate
					to={{
						pathname: fallbackUrl,
					}}
				/>
			);
		}
		return <HomeLayoutContainer {...props} />;
	}

	return null;
};

RootContainer.propTypes = {
	websiteTunnels: PropTypes.array,
	fallbackUrl: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		websiteTunnels: getWebsiteTunnels(state),
		homeBlocks: getPartnerMarketingHomeBlocks(state),
		fallbackUrl: getFallbackRedirectUrl(state),
	};
};

export default connect(mapStateToProps)(RootContainer);
