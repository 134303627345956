const INITIAL_STATE = {
	isInApp: false,
};

// Dans le projet lambda_edge_viewer_request, se trouve le code qui valorise le header x-inapp qui donne la valeur de isInApp
// Si ?inapp=true ou si user agent contient vp-android-smartphone ou vp-iphone alors isInApp = true
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default: {
			return state;
		}
	}
};
