import { FETCH_LANDING_PAGE_SUCCESS } from "app/pages/LandingPage/landingPageActionTypes";
import { computedProductDates } from "app/utils/utils";

const INITIAL_STATE = {
	current: {},
	products: [],
	catalogProducts: [],
	list: [],
	activeFilter: undefined,
	activeLandingPagesCode: undefined,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_LANDING_PAGE_SUCCESS: {
			const { landingPageSeo = {}, products = [], catalogProducts = [] } = action?.res?.data;

			const newProductList = computedProductDates(products);
			const newCatalogProductList = computedProductDates(catalogProducts);

			return {
				...state,
				current: landingPageSeo,
				products: newProductList,
				catalogProducts: newCatalogProductList,
				activeFilter: undefined,
			};
		}
		default: {
			return state;
		}
	}
};
