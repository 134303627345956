import PropTypes from "prop-types";
import { Fragment, memo } from "react";
import Button from "app/pages/.shared/form/Button";
import "./FacebookLogin.scss";
import { FormattedMessage } from "react-intl";

const FacebookLogin = ({ isDisabled, onClick, isLoading }) => {
	return (
		<Button
			loading={isLoading}
			onClick={onClick}
			disabled={isDisabled}
			className="button button--facebook"
			data-testid="facebook-login-button"
		>
			{!isDisabled && (
				<Fragment>
					<i className="icon icon--facebook-rond" />
					<div>
						<FormattedMessage id="auth.facebook.button.label" />
					</div>
				</Fragment>
			)}
		</Button>
	);
};

FacebookLogin.propTypes = {
	onClick: PropTypes.func,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

export default memo(FacebookLogin);
