import {
	FETCH_SEARCH_MY_BOOKING,
	RESET_SEARCH_MY_BOOKING,
	FETCH_SEARCH_MY_BOOKING_NOTIFICATION,
} from "app/actionTypes";
import axios from "axios";
import env from "app/utils/env";

export const fetchSearchMyBooking = ({ bookingReference, email, brand }) => {
	const url = `${env("ACCOUNT_API_URL")}/getCustomerBooking`;
	const headers = {
		"Content-type": "application/json",
	};
	return {
		type: FETCH_SEARCH_MY_BOOKING,
		promise: axios.post(url, { bookingReference, lastName: email, brand }, { headers }),
	};
};

export const resetSearchMyBooking = () => {
	return {
		type: RESET_SEARCH_MY_BOOKING,
	};
};

export const fetchSearchMyBookingNotification = ({ bookingReference, email, shop, brand }) => {
	const url = `${env("ACCOUNT_API_URL")}/getCustomerRequests/count`;
	const headers = {
		"Content-type": "application/json",
	};
	return {
		type: FETCH_SEARCH_MY_BOOKING_NOTIFICATION,
		promise: axios.get(url, {
			headers,
			params: { brandCode: brand, shopCode: shop, bookingReference, email },
		}),
	};
};
