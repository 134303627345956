import { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./MediaArticle.scss";
import Paragraphs from "app/pages/.shared/Paragraphs";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const MediaArticle = ({ title, descriptions = [] }) => {
	let descriptionWrapperRef = useRef();
	const titleArticleRef = useRef();
	useEffect(() => {
		// pour remonter le contenu de l'article lorsque l'on change d'article dont le contenu est trop lond, ce qui évite de rester en bas
		descriptionWrapperRef.current.scrollTop = 0;
	});

	useEffect(() => {
		const articleDescriptionNode = descriptionWrapperRef?.current;
		const articleTitleNode = titleArticleRef?.current;

		if (articleDescriptionNode && articleTitleNode) {
			// Force browser to reflow by accessing offsetWidth
			// eslint-disable-next-line no-void
			void articleDescriptionNode.offsetWidth;
			// eslint-disable-next-line no-void
			void articleTitleNode.offsetWidth;

			// Reset the animation
			articleDescriptionNode.classList.remove("fade-in-up");
			articleTitleNode.classList.remove("fade-in-up");

			// Reflow again to ensure CSS recognises the change
			// eslint-disable-next-line no-void
			void articleDescriptionNode.offsetWidth;
			// eslint-disable-next-line no-void
			void articleTitleNode.offsetWidth;

			// Add the class again to trigger the animation
			articleDescriptionNode.classList.add("fade-in-up");
			articleTitleNode.classList.add("fade-in-up");
		}
	}, [title, descriptionWrapperRef?.current, titleArticleRef?.current]);

	return (
		<div className="media-article">
			<div className="media-article__article">
				<div className="media-article__title-container" ref={titleArticleRef}>
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL2}
						isBold
						className="media-article__title-label"
					>
						{title}
					</Typography>
				</div>
				<div className="media-article__scroller" ref={descriptionWrapperRef}>
					<div className="media-article__description">
						<Paragraphs paragraphs={descriptions} enableHTMLFormatting />
					</div>
				</div>
			</div>
		</div>
	);
};

MediaArticle.propTypes = {
	title: PropTypes.string,
	descriptions: PropTypes.arrayOf(PropTypes.string),
};

export default memo(MediaArticle);
