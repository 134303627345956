import { getBrandDisplayName, getMetaTags, getTheme } from "app/reducers/partnerSelector";
import { getUITheme } from "app/reducers/themeSelector";
import { connect } from "react-redux";
import DefaultHeadMetadata from "app/utils/metas/DefaultHeadMetadata";

const mapStateToProps = state => {
	return {
		brandDisplayName: getBrandDisplayName(state),
		theme: getUITheme(state),
		sanityTheme: getTheme(state),
		googleSiteVerificationId: state.partner.googleSiteVerificationId,
		metaTags: getMetaTags(state),
	};
};

export default connect(mapStateToProps)(DefaultHeadMetadata);
