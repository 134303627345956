import { FETCH_FIRST_PRODUCTS_SUCCESS, FETCH_PRODUCTS_SUCCESS } from "app/actionTypes";

const initialState = {
	topCountries: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_FIRST_PRODUCTS_SUCCESS:
		case FETCH_PRODUCTS_SUCCESS: {
			return {
				...state,
				...{
					topCountries: action.res.data.topCountries || [],
				},
			};
		}
		default:
			return state;
	}
};
