import PropTypes from "prop-types";
import { connect } from "react-redux";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import { getRobotsMetaTag } from "app/reducers/partnerSelector";

const SmartDPSearchHeadMetadata = ({ robotsMetaTag }) => {
	return (
		<HeadMetadata
			description="page.description.sdp.home"
			title="page.title.sdp.home"
			robotsMetaTag={robotsMetaTag}
		/>
	);
};

SmartDPSearchHeadMetadata.propTypes = {
	robotsMetaTag: PropTypes.string,
};

const mapStateToProps = state => {
	const robotsMetaTag = getRobotsMetaTag(state);
	return {
		robotsMetaTag: robotsMetaTag?.sdpSearch,
	};
};

export default connect(mapStateToProps)(SmartDPSearchHeadMetadata);
