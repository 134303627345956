import {
	finishLoadingBar,
	showLoadingBar,
} from "app/pages/.shared/LoadingBar/loadingBarActionCreators";
import {
	REPLACE_SDP_PRODUCTS_FILTERS,
	RESET_SDP_PRODUCTS_FILTER,
	SET_SDP_PRODUCTS_FILTER_BY,
	SET_SDP_PRODUCTS_FILTERS,
	UNSET_SDP_PRODUCTS_FILTER_BY,
	UNSET_SDP_PRODUCTS_FILTERS,
} from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionsTypes";

export const setSDPProductsFilter = filterBy => dispatch => {
	dispatch(showLoadingBar());

	setTimeout(() => {
		dispatch(finishLoadingBar());
	}, 300);

	dispatch({
		type: SET_SDP_PRODUCTS_FILTER_BY,
		filterBy,
	});
};

export const unsetSDPProductsFilter = name => dispatch => {
	dispatch(showLoadingBar());

	setTimeout(() => {
		dispatch(finishLoadingBar());
	}, 300);

	dispatch({
		type: UNSET_SDP_PRODUCTS_FILTER_BY,
		name,
	});
};

export const unsetSDPProductsFilters = filters => ({
	type: UNSET_SDP_PRODUCTS_FILTERS,
	filters,
});

export const setSDPProductsFilters = filters => ({
	type: SET_SDP_PRODUCTS_FILTERS,
	filters,
});

export const replaceSDPProductsFilters = filters => ({
	type: REPLACE_SDP_PRODUCTS_FILTERS,
	filters,
});

export const resetAllSDPProductsFilter = () => dispatch => {
	dispatch(showLoadingBar());

	setTimeout(() => {
		dispatch(finishLoadingBar());
	}, 300);

	dispatch({
		type: RESET_SDP_PRODUCTS_FILTER,
	});
};
