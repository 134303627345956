import { useContext } from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { object, string } from "yup";
import { ALERT_TYPE } from "app/constants";
import { validatePhoneNumberType } from "app/pages/Account/Profile/profileSchema";
import AppGlobalsContext from "app/AppGlobalsContext";
import AlertMessage from "app/pages/.shared/AlertMessage";
import Button from "app/pages/.shared/form/Button";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import PhoneIntl from "app/pages/.shared/profile/PhoneIntl";
import "./RecoverBookingData.scss";
import { isEmpty } from "lodash";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

export const validationSchema = object().shape({
	"recover-booking-data-number": string()
		.test("phone (mobile or a fixed-line number)", { id: "error.phone.invalid" }, numberIntl =>
			validatePhoneNumberType(numberIntl)
		)
		.required({ id: "error.phone.required" }),
});

const RecoverBookingData = ({ handleSubmit = () => {} }) => {
	const { shop } = useContext(AppGlobalsContext);

	return (
		<div className="recover-booking-data-form">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL2}
				isBold
				className="recover-booking-data-form__title"
				component="h1"
			>
				<FormattedMessage id="search.my.booking.recover.booking.form.title" />
			</Typography>

			<div className="recover-booking-data-form__title-separator" />

			<div className="recover-booking-data-form__intro">
				<FormattedMessage id="search.my.booking.recover.booking.form.intro" />
			</div>

			<Formik
				initialValues={{
					"recover-booking-data-number": undefined,
				}}
				onSubmit={handleSubmit}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={validationSchema}
			>
				{({
					errors = {},
					isSubmitting,
					setStatus,
					submitCount,
					setFieldValue,
					setFieldTouched,
					setFieldError,
					setErrors,
				}) => (
					<Form
						noValidate
						className="recover-booking-data-form__form"
						onChange={event => {
							setStatus({});
							if (!isEmpty(errors)) {
								setFieldError(event?.target?.name);
								setErrors({});
							}
						}}
					>
						<FormErrorMessages
							errors={errors}
							isSubmitting={isSubmitting}
							submitCount={submitCount}
						/>
						<div className="recover-booking-data-form__input">
							<PhoneIntl
								id="recover-booking-data-number"
								name="recover-booking-data-number"
								shop={shop}
								setFieldValue={setFieldValue}
								setFieldTouched={setFieldTouched}
								setFieldError={setFieldError}
								label={
									<FormattedMessage id="search.my.booking.recover.booking.number.placeholder" />
								}
								data-testid="phone-input"
							/>
						</div>

						{errors._error && (
							<AlertMessage
								alertType={ALERT_TYPE.ERROR}
								message={
									Array.isArray(errors._error) ? (
										<FormattedMessage id="error.sponsorship.friends.email.invalid" />
									) : (
										<FormattedMessage {...errors._error} />
									)
								}
								{...Array.isArray(errors._error) && {
									errors: errors._error.map((error, index) => (
										<FormattedMessage key={index} {...error} />
									)),
								}}
							/>
						)}
						<div className="recover-booking-data-form__button">
							<Button
								submit
								variant="primary"
								loading={isSubmitting}
								data-testid="recover-button"
							>
								<span style={{ textTransform: "uppercase" }}>
									<FormattedMessage id="search.my.booking.recover.booking.form.submit.button.label" />
								</span>
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

RecoverBookingData.propTypes = {
	handleSubmit: PropTypes.func,
};

export default RecoverBookingData;
