import omit from "lodash/omit";
import { CREDIT_CARDS_TYPES } from "app/constants";

let cse;

export const initAdyen = adyenApiKey => {
	if (!cse) {
		import("klook-adyen-cse-web/js/addOns/adyen.cardtype")
			.then(() => {
				return import("klook-adyen-cse-web");
			})
			.then(({ createEncryption }) => {
				cse = createEncryption(adyenApiKey, {
					enableValidations: false,
				});
				cse.determine = window.adyen.cardTypes.determine;

				return cse;
			});
	}
};

export const determineCardType = cardNumber => {
	// Attention, determineCardType("") renvoie mc. bug adyen ?
	// Supprimer cette vérification dès que https://github.com/Adyen/adyen-cse-web/issues/66 est résolue
	if (cardNumber) {
		const cleanedCardNumber = cardNumber?.replace(/-/g, "");
		const cardType = cse.determine(cleanedCardNumber);
		return cardType ? cardType.cardtype : undefined;
	}

	return undefined;
};

export const validateCreditCardData = data => {
	if (determineCardType(data.number) === CREDIT_CARDS_TYPES.MAESTRO) {
		if (data.cvc) {
			return cse.validate(data);
		}
		return cse.validate(omit(data, "cvc"));
	}

	return cse.validate(data);
};

export const encryptCreditCard = data => {
	return cse.encrypt({ ...data });
};

export const isCreditCardAmex = number => {
	return number && (number.substring(0, 2) === "34" || number.substring(0, 2) === "37");
};

export const isCreditCardBCMC = number => {
	return number && number.substring(0, 4) === "6703";
};
