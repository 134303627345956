import { default as AxiosIns } from "axios";
import Cookies from "js-cookie";

const axios = AxiosIns.create();

axios.interceptors.request.use(
	config => {
		let shop;
		if (window) {
			shop = window?.location?.pathname?.split("/")[1];
		}
		const token = Cookies.get("authToken", { path: `/${shop}` });
		if (token) {
			config.headers.Authorization = token;
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

export default axios;
