import { memo, useContext } from "react";
import PropTypes from "prop-types";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconLightning from "app/pages/.shared/IconLightning";
import { FormattedMessage } from "react-intl";
import SvgIconMagnifyingGlassBold from "app/pages/.shared/IconMagnifyingGlassBold";
import { IconHelp, IconLuggage } from "app/pages/.shared/static/icons";
import { sendTagOnMenuGoToSearchBooking } from "app/utils/analytics";
import DotNotification from "app/pages/.shared/DotNotification/DotNotification";
import { brandPropTypes } from "app/utils/propTypes";
import { BRANDS } from "app/constants";
import AppGlobalsContext from "app/AppGlobalsContext";
import IconHome from "app/pages/.shared/static/icons/IconHome";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const AccountMenuFooter = ({
	onItemClick = () => {},
	brand,
	isAuthenticated,
	bookingReference,
	showCustomerRequestNotification,
	showSmartDPOnRootUrl = false,
}) => {
	const isBrandPS = brand === BRANDS.PS;

	const { shop = "" } = useContext(AppGlobalsContext);

	return (
		<div className="account-menu__footer">
			{isAuthenticated && (
				<>
					{showSmartDPOnRootUrl ? (
						<>
							<div className="account-menu__section">
								<RelativeLink
									to={{ pathname: "/listing" }}
									data-testid="account-menu-flashsale-item"
									onClick={onItemClick}
									className="account-menu__head-link"
								>
									<div className="account-menu__head-item account-menu__head-item--link">
										<div className="account-menu__head-icon">
											<IconLightning />
										</div>
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											isBold
											className="account-menu__head-title"
										>
											<FormattedMessage id="sdp.menu.item.flashsales" />
										</Typography>
									</div>
								</RelativeLink>
							</div>
							<div className="account-menu__section">
								<RelativeLink
									to={{ pathname: "/" }}
									data-testid="account-menu-search-trip-item"
									onClick={onItemClick}
									className="account-menu__head-link"
								>
									<div className="account-menu__head-item account-menu__head-item--link">
										<div className="account-menu__head-icon">
											<SvgIconMagnifyingGlassBold />
										</div>
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											isBold
											className="account-menu__head-title"
										>
											<FormattedMessage id="sdp.menu.item.search.trip" />
										</Typography>
									</div>
								</RelativeLink>
							</div>
						</>
					) : (
						<div className="account-menu__section">
							<RelativeLink
								to={{ pathname: "/listing" }}
								data-testid="account-menu-all-sales-item"
								onClick={onItemClick}
								className="account-menu__head-link"
							>
								<div className="account-menu__head-item account-menu__head-item--link">
									<div className="account-menu__head-icon">
										<IconHome />
									</div>
									<Typography
										variant={TYPOGRAPHY_VARIANTS.LARGE}
										isBold
										className="account-menu__head-title"
									>
										<FormattedMessage id="menu.mobile.all.sales" />
									</Typography>
								</div>
							</RelativeLink>
						</div>
					)}
				</>
			)}
			{!isAuthenticated && (
				<>
					{bookingReference ? (
						<>
							<div className="account-menu__section">
								<div className="account-menu__head-item">
									<div className="account-menu__head-icon">
										<IconLuggage />
									</div>
									<Typography
										variant={TYPOGRAPHY_VARIANTS.LARGE}
										isBold
										className="account-menu__head-title"
									>
										<FormattedMessage id="menu.item.my.bookings" />
									</Typography>
								</div>

								<div className="account-menu__list">
									<RelativeLink
										to={{
											pathname: `/account/mybookings/${bookingReference}`,
										}}
										rel="noopener noreferrer"
										data-testid="account-menu-faq-contact-item"
										onClick={onItemClick}
										className="account-menu__item"
									>
										<FormattedMessage
											id="menu.item.my.booking"
											values={{
												bookingReference: bookingReference,
											}}
										/>
									</RelativeLink>
									<RelativeLink
										to={{ pathname: "/booking-search" }}
										rel="noopener noreferrer"
										data-testid="account-menu-booking-search-item"
										onClick={onItemClick}
										className="account-menu__item"
									>
										<FormattedMessage id="menu.mobile.go.to.other.bookings" />
									</RelativeLink>
								</div>
							</div>

							<div className="account-menu__section">
								<div className="account-menu__head-item">
									<div className="account-menu__head-icon">
										<IconHelp />
									</div>

									<Typography
										variant={TYPOGRAPHY_VARIANTS.LARGE}
										isBold
										className="account-menu__head-title"
									>
										<FormattedMessage id="menu.item.need.help" />
									</Typography>
								</div>

								<div className="account-menu__list">
									<RelativeLink
										to={{ pathname: "/account/faq-contact" }}
										rel="noopener noreferrer"
										data-testid="account-menu-faq-contact-item"
										onClick={onItemClick}
										className="account-menu__item"
									>
										<FormattedMessage id="menu.item.faq.contact" />
									</RelativeLink>
									<RelativeLink
										to={{
											pathname: "/account/customer-request/my-requests",
											search: `?bookingReference=${bookingReference}`,
										}}
										rel="noopener noreferrer"
										data-testid="account-menu-my-requests-item"
										onClick={onItemClick}
										className="account-menu__item"
									>
										<FormattedMessage id="menu.item.my.requests" />
										{showCustomerRequestNotification && <DotNotification />}
									</RelativeLink>
								</div>
							</div>
						</>
					) : (
						<>
							<div className="account-menu__section">
								<RelativeLink
									to={{ pathname: "/faq" }}
									rel="noopener noreferrer"
									target="_blank"
									data-testid="account-menu-help-item"
									onClick={onItemClick}
									className="account-menu__head-link"
								>
									<div className="account-menu__head-item account-menu__head-item--link">
										<div className="account-menu__head-icon">
											<IconHelp />
										</div>
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											isBold
											className="account-menu__head-title"
										>
											<FormattedMessage id="menu.item.help" />
										</Typography>
									</div>
								</RelativeLink>
							</div>

							<div className="account-menu__section">
								<RelativeLink
									to={{
										pathname: isBrandPS
											? `https://booking.perfectstay.com/${shop}`
											: "/booking-search",
									}}
									target={isBrandPS && `_blank`}
									rel="noopener noreferrer"
									onClick={() => {
										sendTagOnMenuGoToSearchBooking();
										onItemClick();
									}}
									data-testid="go-to-booking-search-link"
									className="account-menu__head-link"
								>
									<div className="account-menu__head-item account-menu__head-item--link">
										<div className="account-menu__head-icon">
											<IconLuggage />
										</div>
										<Typography
											variant={TYPOGRAPHY_VARIANTS.LARGE}
											isBold
											className="account-menu__head-title"
										>
											<FormattedMessage id="menu.mobile.go.to.my.bookings" />
										</Typography>
									</div>
								</RelativeLink>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

AccountMenuFooter.propTypes = {
	brand: brandPropTypes,
	onItemClick: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	bookingReference: PropTypes.string,
	showCustomerRequestNotification: PropTypes.bool,
	showSmartDPOnRootUrl: PropTypes.bool,
};

export default memo(AccountMenuFooter);
