import PropTypes from "prop-types";
import { memo } from "react";

/*
 Casino est uniquement disponible en France pour les résidents Français. La note Casino est donc en dure
 en français dans le code. Pas besoin de la faire traduire dans d'autres langues
 */
const NoteCasino = ({ minAmount, maxAmount }) => {
	return (
		<span data-testid="note-casino">
			{`*Offre de financement réservée aux particuliers résidents en France, valable pour tout
			achat de ${minAmount} € à ${maxAmount} € avec un départ à plus de 15 jours de la date de
			commande, soumise à acceptation par FLOA et nécessitant le paiement
			d'une première mensualité à la date de commande. Vous disposez d'un délai de 14 jours
			pour renoncer à votre crédit. Exemple en 4 fois pour un achat de 400 €: 4 mensualités de
			102.37 €, soit un coût du financement de 9.48€ dans la limite de 2.37% du montant de
			l'achat. Pour en savoir plus, `}{" "}
			<a
				style={{ fontSize: "inherit" }}
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.floabank.fr/images/pdf/CB4X/Conditions-Gnrales-Vente-CB3X-CB4X.pdf"
			>
				veuillez consulter les CGV.
			</a>
		</span>
	);
};

NoteCasino.propTypes = {
	minAmount: PropTypes.string,
	maxAmount: PropTypes.string,
};

export default memo(NoteCasino);
