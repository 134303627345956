import PropTypes from "prop-types";
import { Fragment, memo, useContext, useEffect } from "react";
import "./pages/.styles/index.scss";
import { brandPropTypes, strategyShape } from "app/utils/propTypes";
import { BRANDS, BRANDS_WITH_ACCESS_TO_MY_BOOKING, STRATEGY } from "app/constants";
import { LoadingBar } from "app/pages/.shared/LoadingBar/LoadingBar";
import { matchRoutes, Navigate, Route, Routes, useLocation } from "react-router-dom";
import FooterContainer from "app/pages/Footer/FooterContainer";
import {
	AsyncAccountContainer,
	AsyncAuthBookingLayerContainer,
	AsyncAuthContainer,
	AsyncBooking,
	AsyncBookingHeaderContainer,
	AsyncFaqContainer,
	AsyncFicheProduitContainer,
	AsyncHeaderContainer,
	AsyncHomeContainer,
	AsyncSignupAuthContainer,
	AsyncListingContainer,
	AsyncMerchandisingContainer,
	AsyncLandingPageContainer,
	AsyncNotFound,
	AsyncPaymentExternalRedirect,
	AsyncPriceMatchGuarantee,
	AsyncSearchBookingPage,
	AsyncSearchMyBookingContainer,
	AsyncSmartDPContainer,
	AsyncStaticPageContainer,
	AsyncStopoverContainer,
	AsyncTechnicalErrorContainer,
	AsyncUnsubscribeContainer,
	AsyncHotelOnlyContainer,
} from "app/routes";
import BookWithConfidence from "app/pages/.shared/BookWithConfidence/BookWithConfidence";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import AppGlobalsContext from "app/AppGlobalsContext";
import RequireSignupFirst from "app/pages/Auth/RequireSignupFirst";
import QueriesParser from "app/pages/.shared/QueriesParser/QueriesParser";
import RootContainer from "app/pages/Root/RootContainer";
import OperatorRedirect from "app/pages/.shared/OperatorRedirect/OperatorRedirect";
import DevInfos from "app/pages/.shared/DevInfos/DevInfos";
import { useFsFlag } from "@flagship.io/react-sdk";
import isEmpty from "lodash/isEmpty";
import { datadogRum } from "@datadog/browser-rum";
import { getRoutes } from "src/server/routes";
import DefaultHeadMetadataContainer from "app/utils/metas/DefaultHeadMetadataContainer";
import NavigateWithParams from "app/pages/.shared/NavigateWithParams/NavigateWithParams";

const computeViewName = routeMatches => {
	let viewName = "";
	for (let index = 0; index < routeMatches.length; index++) {
		const routeMatch = routeMatches[index];
		const path = routeMatch.route.path;
		// Skeep pathless routes
		if (!path) {
			continue;
		}

		if (path.startsWith("/")) {
			// Handle absolute child route paths
			viewName = path;
		} else {
			// Handle route paths ending with "/"
			viewName += viewName.endsWith("/") ? path : `/${path}`;
		}
	}

	return viewName || "/";
};

const PerfectApp = ({
	authLayer,
	brand,
	isWhiteLabel,
	shop,
	isAuthenticated,
	updateTheme,
	isFlashSale,
}) => {
	let routes;

	const { resolution, strategy } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const { pathname } = useLocation();
	const themeFlag = useFsFlag("theme", {});

	const themeFlagValue = themeFlag.getValue();

	useEffect(() => {
		if (!isEmpty(themeFlagValue)) {
			const colors = {
				colorPrimary: {
					hex: themeFlagValue?.colorPrimary?.original,
				},
				colorSecondary: {
					hex: themeFlagValue?.colorSecondary?.original,
				},
				colorPrimaryDark: {
					hex: themeFlagValue?.colorPrimary?.dark,
				},
				colorPrimaryLight: {
					hex: themeFlagValue?.colorPrimary?.light,
				},
				colorSecondaryDark: {
					hex: themeFlagValue?.colorSecondary?.dark,
				},
				colorSecondaryLight: {
					hex: themeFlagValue?.colorSecondary?.light,
				},
			};

			updateTheme({ colors });
		}
	}, [themeFlagValue]);

	if (brand === BRANDS.XX) {
		routes = (
			<>
				<Routes>
					<Route path="/home/*" element={<></>} />
					<Route path="/faq" element={<></>} />
					<Route path="/account/*" element={<AsyncHeaderContainer />} />
					<Route
						path="/listing"
						element={!isAuthenticated ? <></> : <AsyncHeaderContainer />}
					/>
					<Route path="/not-found" element={<AsyncHeaderContainer />} />
					<Route path="/merch" element={<AsyncHeaderContainer />} />
					<Route path="/technical-error/*" element={<AsyncHeaderContainer />} />
					<Route path="/booking/*" element={<AsyncBookingHeaderContainer />} />
					<Route path="/priceMatchGuarantee" element={<AsyncHeaderContainer />} />
					<Route
						path="/:productUri"
						element={
							!isAuthenticated ? <></> : <AsyncHeaderContainer showBackBtn={true} />
						}
					/>
				</Routes>

				<Routes>
					<Route
						path="/home/*"
						element={
							<Navigate
								to={{
									pathname: `/`,
								}}
							/>
						}
					/>
					<Route
						path="/account/*"
						element={
							!isAuthenticated ? (
								<Navigate
									to={{
										pathname: `/`,
									}}
								/>
							) : (
								<AsyncAccountContainer />
							)
						}
					/>
					<Route
						path="/auth"
						element={
							<Navigate
								to={{
									pathname: `/`,
								}}
							/>
						}
					/>
					<Route path="/booking/*" element={<AsyncBooking />} />
					<Route path="/external-redirect" element={<AsyncPaymentExternalRedirect />} />
					<Route path="/not-found" element={<AsyncNotFound />} />
					<Route path="/technical-error/*" element={<AsyncTechnicalErrorContainer />} />
					<Route path="/schizophrene" element={<OperatorRedirect />} />
					<Route
						path="/listing"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncListingContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>
					<Route
						path="/merch"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncMerchandisingContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>
					<Route
						path="/landing-pages"
						element={
							<QueriesParser>
								<AsyncLandingPageContainer />
							</QueriesParser>
						}
					/>
					<Route path="/priceMatchGuarantee" element={<AsyncPriceMatchGuarantee />} />
					<Route path="/faq/*" element={<AsyncFaqContainer />} />
					<Route path="/page" element={<AsyncStaticPageContainer />} />
					<Route
						path="/sdp/*"
						element={
							<QueriesParser>
								<AsyncSmartDPContainer />
							</QueriesParser>
						}
					/>
					<Route
						path="/:productUri"
						element={
							<QueriesParser>
								<RequireSignupFirst
									renderComponent={props => {
										return <AsyncFicheProduitContainer {...props} />;
									}}
								/>
							</QueriesParser>
						}
					/>
					<Route
						path="/*"
						element={
							<QueriesParser>
								<AsyncSearchBookingPage />
							</QueriesParser>
						}
					/>
				</Routes>
			</>
		);
	} else {
		routes = (
			<>
				<Routes>
					<Route
						path="/home/*"
						element={<AsyncHeaderContainer showMenuItems={false} />}
					/>
					<Route
						path="/auth"
						element={<AsyncHeaderContainer showMenuItems={false} showBackBtn={false} />}
					/>
					<Route path="/unsubscribe" element={<AsyncHeaderContainer />} />
					<Route path="/account/*" element={<AsyncHeaderContainer />} />
					<Route path="/sign-up/*" element={<></>} />
					<Route path="/schizophrene" element={<></>} />
					<Route path="/priceMatchGuarantee" element={<AsyncHeaderContainer />} />
					<Route path="/booking/*" element={<AsyncBookingHeaderContainer />} />
					<Route path="/external-redirect" element={<></>} />
					<Route path="/not-found" element={<AsyncHeaderContainer />} />
					<Route path="/technical-error/*" element={<AsyncHeaderContainer />} />
					<Route path="/magazine" element={<></>} />
					<Route path="/faq" element={<></>} />
					<Route path="/page/:slug" element={<AsyncHeaderContainer />} />
					<Route path="/sdp/*" element={<></>} />
					<Route path="/stopover/*" element={<></>} />
					<Route path="/hotelonly/*" element={<></>} />
					<Route path="/landing-pages/*" element={<AsyncHeaderContainer />} />
					<Route
						path="/listing"
						element={brand !== BRANDS.TZ ? <AsyncHeaderContainer /> : <></>}
					/>
					<Route path="/merch" element={<AsyncHeaderContainer />} />
					<Route
						path="/product/id/:productId"
						element={<AsyncHeaderContainer showBackBtn={true} />}
					/>
					<Route
						path="/booking-auth"
						element={isMobile ? <></> : <AsyncHeaderContainer showMenuItems={false} />}
					/>
					<Route
						path="/booking-search"
						element={isMobile ? <></> : <AsyncHeaderContainer />}
					/>
					<Route
						path="/:productUri"
						element={<AsyncHeaderContainer showBackBtn={true} />}
					/>
					<Route path="/*" element={<></>} />
				</Routes>

				<Routes>
					<Route
						path="" // obliger de spécifier "" et "/" pour que les meta tags robots google bots soit correct et ne prenne pas les valeurs par défaut lors du server side rendering
						element={
							<QueriesParser>
								<RootContainer />
							</QueriesParser>
						}
					/>
					<Route
						path="/" // obliger de spécifier "" et "/" pour que les meta tags robots google bots soit correct et ne prenne pas les valeurs par défaut lors du server side rendering
						element={
							<QueriesParser>
								<RootContainer />
							</QueriesParser>
						}
					/>
					{isWhiteLabel && (
						<Route
							path="/home/*"
							element={
								<QueriesParser>
									<AsyncHomeContainer />
								</QueriesParser>
							}
						/>
					)}
					{isWhiteLabel && <Route path="/auth" element={<AsyncAuthContainer />} />}
					{isWhiteLabel && (
						<Route
							path="/unsubscribe"
							element={
								<QueriesParser>
									<AsyncUnsubscribeContainer />
								</QueriesParser>
							}
						/>
					)}
					{(isWhiteLabel ||
						BRANDS_WITH_ACCESS_TO_MY_BOOKING.includes(brand) ||
						isAuthenticated) && (
						<Route path="/account/*" element={<AsyncAccountContainer />} />
					)}

					{isWhiteLabel && (
						<Route
							path="/sign-up/:operationCode/*"
							element={
								<QueriesParser>
									<AsyncSignupAuthContainer />
								</QueriesParser>
							}
						/>
					)}

					{isWhiteLabel && (
						<Route
							path="/landing-page/:operationCode/*"
							element={
								<NavigateWithParams
									to={params => `/sign-up/${params.operationCode}`}
									replace
								/>
							}
						/>
					)}

					<Route
						path="/landing-pages/:landingPageCode/*"
						element={
							<QueriesParser>
								<AsyncLandingPageContainer />
							</QueriesParser>
						}
					/>

					{brand === BRANDS.AF && (
						<Route
							path="/ourcommitments"
							element={<Navigate to={{ pathname: "/page/ourcommitments" }} />}
						/>
					)}

					<Route
						path="/product/id/:productId"
						element={
							<QueriesParser>
								{strategy === STRATEGY.SIGNUP_FIRST ||
								(strategy === STRATEGY.AUTH_FLASHSALE && isFlashSale) ? (
									<RequireSignupFirst
										renderComponent={props => {
											return <AsyncFicheProduitContainer {...props} />;
										}}
									/>
								) : (
									<AsyncFicheProduitContainer />
								)}
							</QueriesParser>
						}
					/>

					<Route path="/schizophrene" element={<OperatorRedirect />} />
					<Route path="/priceMatchGuarantee" element={<AsyncPriceMatchGuarantee />} />
					<Route path="/booking/*" element={<AsyncBooking />} />
					<Route path="/external-redirect" element={<AsyncPaymentExternalRedirect />} />
					<Route path="/not-found" element={<AsyncNotFound />} />
					<Route path="/technical-error/*" element={<AsyncTechnicalErrorContainer />} />
					<Route
						path="/listing"
						element={
							<QueriesParser>
								{strategy === STRATEGY.SIGNUP_FIRST ||
								strategy === STRATEGY.AUTH_FLASHSALE ? (
									<RequireSignupFirst
										renderComponent={props => {
											return <AsyncListingContainer {...props} />;
										}}
									/>
								) : (
									<AsyncListingContainer />
								)}
							</QueriesParser>
						}
					/>
					<Route
						path="/merch"
						element={
							<QueriesParser>
								{strategy === STRATEGY.SIGNUP_FIRST ? (
									<RequireSignupFirst
										renderComponent={props => {
											return <AsyncMerchandisingContainer {...props} />;
										}}
									/>
								) : (
									<AsyncMerchandisingContainer />
								)}
							</QueriesParser>
						}
					/>

					<Route path="/faq/*" element={<AsyncFaqContainer />} />
					<Route path="/page/:slug" element={<AsyncStaticPageContainer />} />
					<Route
						path="/sdp/*"
						element={
							<QueriesParser>
								<AsyncSmartDPContainer />
							</QueriesParser>
						}
					/>

					<Route
						path="/stopover/*"
						element={
							<QueriesParser>
								<AsyncStopoverContainer />
							</QueriesParser>
						}
					/>

					<Route
						path="/hotelonly/*"
						element={
							<QueriesParser>
								<AsyncHotelOnlyContainer />
							</QueriesParser>
						}
					/>

					<Route path="/booking-auth" element={<AsyncAuthBookingLayerContainer />} />

					<Route path="/booking-search" element={<AsyncSearchMyBookingContainer />} />

					<Route
						path="/:productUri"
						element={
							<QueriesParser>
								{strategy === STRATEGY.SIGNUP_FIRST ||
								(strategy === STRATEGY.AUTH_FLASHSALE && isFlashSale) ? (
									<RequireSignupFirst
										renderComponent={props => {
											return <AsyncFicheProduitContainer {...props} />;
										}}
									/>
								) : (
									<AsyncFicheProduitContainer />
								)}
							</QueriesParser>
						}
					/>

					<Route path="*" element={<AsyncNotFound />} />
				</Routes>
			</>
		);
	}

	useEffect(() => {
		const routes = getRoutes(shop);
		const routeMatches = matchRoutes(routes, `/${shop}${pathname}`);
		const viewName = routeMatches && computeViewName(routeMatches);
		if (viewName) {
			datadogRum.startView({ name: viewName?.slice(6) });
		}
	}, [pathname, shop]);

	return (
		<Fragment>
			<LoadingBar />

			<DefaultHeadMetadataContainer />

			{authLayer}

			{routes}

			<Routes>
				{pathname !== "/faq" &&
				pathname !== "/external-redirect" &&
				!(pathname.includes("/listing/map") && isMobile) ? (
					<Route
						path="*"
						element={
							<>
								<BookWithConfidence shop={shop} />
								<FooterContainer />
							</>
						}
					/>
				) : (
					<></>
				)}
			</Routes>

			<DevInfos />
		</Fragment>
	);
};

PerfectApp.propTypes = {
	authLayer: PropTypes.element,
	brand: brandPropTypes,
	shop: PropTypes.string,
	strategy: strategyShape,
	isWhiteLabel: PropTypes.bool,
	isAuthenticated: PropTypes.bool,
	isFlashSale: PropTypes.bool,
	updateTheme: PropTypes.func,
};

export default memo(PerfectApp);
