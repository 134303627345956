export const calculateEcheancier = ({ total = 0, fees = [], terms }) => {
	let taux = 0;

	const ranges = fees?.rateRanges || [];
	const feeRange = ranges.find(e => e.priceFrom <= total && e.priceTo >= total);
	taux = feeRange?.feePercentage || 0;

	if (fees?.feeOnFirstTerm) {
		const coutCredit = (total * taux).toFixed(2);

		const mensualite = (Number(total) / terms).toFixed(2);

		const mensualite1 = (Number(mensualite) + Number(coutCredit)).toFixed(2);

		return {
			apport: Number(mensualite1),
			coutCredit: Number(coutCredit),
			mensualite: Number(mensualite),
		};
	}
	const coutCredit = (total * taux).toFixed(2);

	const panierAvecFrais = total * (1 + taux);

	const mensualite = (Number(panierAvecFrais) / terms).toFixed(2);

	const mensualite1 = (Number(panierAvecFrais) - (terms - 1) * Number(mensualite)).toFixed(2);

	return {
		apport: Number(mensualite1),
		coutCredit: Number(coutCredit),
		mensualite: Number(mensualite),
	};
};
