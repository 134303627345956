import { useContext } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { isWhiteLabel } from "app/reducers/brandSelector";
import {
	getAvailableCreditNotesTotal,
	getAvailableCreditsTotal,
	hasAnyCreditNotes,
} from "app/pages/Account/MyCoupons/couponSelector";
import { brandPropTypes } from "app/utils/propTypes";
import {
	getFallbackRedirectUrl,
	getFilteredMarketingBanners,
} from "app/reducers/partnerSelector.js";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import AccountMenu from "app/pages/Header/AccountMenu/AccountMenu";
import { toggleAsideMenu, hideAsideMenu } from "app/pages/Header/menuActionCreators";

const AccountMenuContainer = props => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	return (
		<>
			<AccountMenu {...props} isMobile={isMobile} />
		</>
	);
};

AccountMenuContainer.propTypes = {
	brand: brandPropTypes,
	marketingBanners: PropTypes.array,
	userIsConnected: PropTypes.bool,
};

const mapStateToProps = state => {
	const partner = state.partner;

	return {
		shouldDisplayCreditNotesEntry: hasAnyCreditNotes(state),
		isWhiteLabel: isWhiteLabel(state),
		userIsConnected: isAuthenticated(state),
		operatorEmail: state.auth.operatorEmail,
		enableCredits: partner.enableCredits,
		creditsAmount: getAvailableCreditsTotal(state),
		creditNotesAmount: getAvailableCreditNotesTotal(state),
		brand: state.brand.code,
		marketingBanners: getFilteredMarketingBanners(state),
		fallbackRedirectUrl: getFallbackRedirectUrl(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ toggleAsideMenu, hideAsideMenu }, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountMenuContainer);
