import { memo } from "react";
import "./LogoHeader.scss";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import PropTypes from "prop-types";

const LogoHeader = ({ isWhite, showSpanLogo }) => {
	return (
		<div className="logo-header" data-testid="logo-header">
			<div className="logo-header__title">
				<LogoBrandContainer isWhite={isWhite} showSpanLogo={showSpanLogo} />
			</div>
		</div>
	);
};

LogoHeader.propTypes = {
	isWhite: PropTypes.string,
	showSpanLogo: PropTypes.bool,
};

export default memo(LogoHeader);
