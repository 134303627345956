import { createSelector } from "reselect";
import { BRANDS } from "app/constants";
import get from "lodash/get";
import Cookies from "js-cookie";

const getBrand = state => state.brand;
const getProfile = state => state.profile;

export const shouldShowFrequencyModal = createSelector(
	[getBrand, getProfile],
	(brand = {}, profile = {}) => {
		return (
			brand.code === BRANDS.AX &&
			get(profile, "newsletterPreference.shouldDisplayFrequencyModal") === true
		);
	}
);

/*
 *	le but est de savoir quand on peut appeler /me quand pour preremplir le profile
 *	la condition est d'avoir un token d'authentification (AF, TO EK etc ...) ou un tokenFromPartner (VP, etc ...)
 */
export const shouldGetProfileAtPayment = () => {
	return Boolean(Cookies.get("authToken")) || Boolean(Cookies.get("tokenFromPartner"));
};
