import { useLocation, useParams, useNavigate } from "react-router-dom";

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();
		return <Component location={location} navigate={navigate} params={params} {...props} />;
	}

	return ComponentWithRouterProp;
}

export default withRouter;
