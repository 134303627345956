import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { recoverBookingData } from "app/pages/CorporateSite/RecoverBookingData/RecoverBookingDataActions";
import RecoverBookingData from "app/pages/CorporateSite/RecoverBookingData/RecoverBookingData";
import { sendTagOnRecoverBookingData } from "app/utils/analytics";
import AppGlobalsContext from "app/AppGlobalsContext";
import IconCircleTick from "app/pages/.shared/static/icons/IconCircleTick";
import Button from "app/pages/.shared/form/Button";
import { HTTP_STATUS_CODES } from "app/constants";

const RecoverBookingDataContainer = ({
	recoverBookingData = () => {},
	onRecoverSuccess = () => {},
}) => {
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const { brand, shop } = useContext(AppGlobalsContext);

	const handleSubmit = (values, { setErrors, setStatus, setSubmitting }) => {
		sendTagOnRecoverBookingData({
			phone: values["recover-booking-data-number"],
		});
		setSubmitting(false);

		recoverBookingData({
			brandShop: {
				brandCode: brand,
				shopCode: shop,
			},
			phone: values["recover-booking-data-number"],
		}).then(res => {
			if (res.status === HTTP_STATUS_CODES.OK) {
				setStatus({ success: true });
				setSubmitSuccess(true);
			} else if (res.status === HTTP_STATUS_CODES.NOT_FOUND) {
				setErrors({ _error: { id: "error.recover.booking.number.not.found" } });
				setStatus({ success: false });
			} else if (res.status === HTTP_STATUS_CODES.TOO_MANY_REQUEST) {
				setErrors({ _error: { id: "error.recover.booking.too.many.request" } });
				setStatus({ success: false });
			} else {
				setErrors({ _error: { id: "error.generic" } });
				setStatus({ success: false });
			}
		});
	};

	return submitSuccess ? (
		<div className="recover-booking-data-form">
			<div
				className="recover-booking-data-form__success"
				data-testid="recover-booking-data-success"
			>
				<IconCircleTick height={50} width={50} style={{ marginBottom: "20px" }} />
				<FormattedMessage id="search.my.booking.recover.booking.success.message" />
				<div className="recover-booking-data-form__got-sms-button">
					<Button submit={false} variant="primary" onClick={onRecoverSuccess}>
						<FormattedMessage id="search.my.booking.recover.booking.got.sms.button.label" />
					</Button>
				</div>
			</div>
		</div>
	) : (
		<RecoverBookingData handleSubmit={handleSubmit} />
	);
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			recoverBookingData,
		},
		dispatch
	);
};

RecoverBookingDataContainer.propTypes = {
	recoverBookingData: PropTypes.func,
	onRecoverSuccess: PropTypes.func,
};

export default connect(
	null,
	mapDispatchToProps
)(RecoverBookingDataContainer);
