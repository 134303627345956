import { createSelector } from "reselect";
import Cookies from "js-cookie";

const getAuth = state => state.auth;

export const isOperatorMode = createSelector(
	[getAuth],
	(auth = {}) => {
		return Boolean(auth.operatorEmail);
	}
);

export const getUUID = createSelector(
	[getAuth],
	(auth = {}) => {
		return auth.uuid;
	}
);

export const getSessionBiid = createSelector(
	[getAuth],
	(auth = {}) => {
		return auth.sessionBiid;
	}
);

/**
 * Vérifie si l'utilisateur est authentifié ou pas
 * Préférer cette méthode plutot que getCredentials si on a besoin de savoir cote serveur si le user est authentifié ou non
 * @returns {boolean} true si authentifié
 */
export const isAuthenticated = state => {
	return (
		Cookies.get("auth", { path: `/${state?.shop}` }) === "auth" &&
		Cookies.get("authToken", { path: `/${state?.shop}` })
	);
};

export const isIdentified = createSelector(
	[getAuth, isAuthenticated],
	(auth = {}, isAuthenticated = false) => {
		return !isAuthenticated && auth.uuid;
	}
);
