import { connect } from "react-redux";
import { getSearchMyBookingCustomerRequestsCount } from "app/pages/Account/SearchMyBooking/searchMyBookingSelector";
import AccountMenuFooter from "app/pages/Header/AccountMenu/AccountMenuFooter/AccountMenuFooter";
import { getBrand } from "app/reducers/brandSelector";

const mapStateToProps = state => {
	return {
		showCustomerRequestNotification: getSearchMyBookingCustomerRequestsCount(state) > 0,
		bookingReference: state.searchMyBooking?.booking?.bookingReference,
		brand: getBrand(state),
		showSmartDPOnRootUrl: state.partner.showSmartDPOnRootUrl,
	};
};

export default connect(mapStateToProps)(AccountMenuFooter);
