import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";
import get from "lodash/get";

const defaultState = {
	marketing: {},
};

export default function vendorReducer(vendorState = defaultState, action) {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			return {
				...vendorState,
				marketing: {
					flex: {
						standardRateDescription: get(
							action,
							"res.data.brand.vendor.marketing.flex.standardRateDescription",
							[]
						),
					},
				},
			};
		}
		default:
			return vendorState;
	}
}
