import { connect } from "react-redux";
import { getMenuConnectMessages } from "app/reducers/partnerSelector";
import { getBrand } from "app/reducers/brandSelector";
import AccountMenuUnauthenticated from "app/pages/Header/AccountMenu/AccountMenuUnauthenticated/AccountMenuUnauthenticated";

const mapStateToProps = state => {
	const menuConnectMessages = getMenuConnectMessages(state);

	return {
		brand: getBrand(state),
		menuConnectMessages: menuConnectMessages?.visitorMessage,
	};
};

export default connect(mapStateToProps)(AccountMenuUnauthenticated);
