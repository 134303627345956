import { getStore } from "app/configureStore";
import env from "app/utils/env";
import axios from "axios";
import { FETCH_CONTEXTUALIZED_SIGNUP_PAGES_AUTH } from "app/pages/SignupPage/Auth/SignupAuthActionTypes";

export const getContextualizedSignupPagesPagesAuth = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_CONTEXTUALIZED_SIGNUP_PAGES_AUTH,
		promise: axios.get(
			`${endpoint}/${
				state.productsVisibility
			}/resources/${shop}/contextualizedSignupPages.json`
		),
	};
};
