import { createSelector } from "reselect";
import {
	IATA_DOM_COUNTRIES,
	OFFER_CONTRACT_TYPES,
	OFFER_TYPES,
	PRICES_COUNT_THRESHOLD,
	QUOTATION_PAYLOAD_PATHS,
} from "app/constants";
import isEmpty from "lodash/isEmpty";
import flow from "lodash/flow";
import toPairs from "lodash/toPairs";
import findIndex from "lodash/findIndex";
import includes from "lodash/includes";
import flattenDeep from "lodash/flattenDeep";
import groupBy from "lodash/groupBy";
import find from "lodash/find";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";
import flatten from "lodash/flatten";
import get from "lodash/get";
import set from "lodash/set";
import isEqual from "lodash/isEqual";
import { INITIAL_BOOKING } from "app/pages/Booking/bookingReducer";
import { getCurrentShop } from "app/reducers/shopSelector";
import { getBookingOfferType, getOffer } from "app/pages/Booking/bookingSelectors";
import { getShowTransportOfferOnly } from "app/reducers/partnerSelector";

const getPrePackageOffer = state => state.prePackageOffer;
const getBookedOffer = state => state.booking.offer;
const getBookedRentalAccommodation = state => state.booking.rentalAccommodation;
const getBookedDepartureCity = state => state.booking.departureCity;
const getBookedDuration = state => state.booking.duration;

export const getAvailablePrepackageOffers = createSelector(
	[getPrePackageOffer, getShowTransportOfferOnly],
	(prepackage = {}, showTransportOfferOnly) => {
		const allOffers = prepackage.offers || [];

		const offersWithTransport = allOffers.filter(
			(offer = {}) => offer.pricing?.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION
		);

		// display all offers if no offer with transportation
		return showTransportOfferOnly && offersWithTransport.length > 0
			? offersWithTransport
			: allOffers;
	}
);

export const getAllAvailableDurations = createSelector(
	[getAvailablePrepackageOffers],
	(offers = []) => {
		const availableDurations = offers.map(offer => {
			return offer.pricing.availableDurations ? offer.pricing.availableDurations : [];
		});

		return flow(
			flatten,
			uniq,
			sortBy
		)(availableDurations);
	}
);

export const getDepartureCities = createSelector(
	[getAvailablePrepackageOffers, getBookedOffer],
	(offers = [], selectedPrePackage) => {
		if (!selectedPrePackage || offers.length === 0) {
			return [];
		}

		const prePackage = find(offers, prePackage => {
			return prePackage.pricing.type === selectedPrePackage.type;
		});

		const departuresCities = prePackage ? prePackage.pricing.departureCities : [];

		return departuresCities ? departuresCities : [];
	}
);

export const getMainDepartureCities = createSelector(
	[getDepartureCities],
	(departuresCities = []) => {
		return departuresCities.filter(city => {
			return !includes(IATA_DOM_COUNTRIES, city.code);
		});
	}
);

export const getOutreMerDepartureCities = createSelector(
	[getDepartureCities],
	(departuresCities = []) => {
		return departuresCities.filter(city => {
			return includes(IATA_DOM_COUNTRIES, city.code);
		});
	}
);

export const getAlternativeDepartureCities = createSelector(
	[getAvailablePrepackageOffers, getBookedOffer],
	(offers = [], selectedPrePackage) => {
		if (!selectedPrePackage || offers.length === 0) {
			return [];
		}

		const prePackage = find(offers, prePackage => {
			return prePackage.pricing.type === selectedPrePackage.type;
		});

		const alternativeDepartureCities = prePackage
			? prePackage.pricing.alternativeDepartureCities
			: [];

		return alternativeDepartureCities ? alternativeDepartureCities : [];
	}
);

// TODO Refacto selector avec arguments
export const getRentalAccommodations = offerCode => {
	if (offerCode === OFFER_TYPES.ACCOMODATION_ONLY) {
		return createSelector(
			[getAvailablePrepackageOffers, getBookedDuration],
			(offers = [], selectedDuration) => {
				let duration;

				if (!offers) {
					return [];
				}

				const offer = find(offers, offer => {
					return offer.pricing.type === OFFER_TYPES.ACCOMODATION_ONLY;
				});

				if (offer) {
					duration = find(offer.pricing.durations, duration => {
						return duration.value === selectedDuration.value;
					});
				}

				return duration ? duration.accommodations : [];
			}
		);
	}

	return createSelector(
		[],
		() => []
	);
};

// TODO Refacto selector avec arguments
export const getDurations = offerCode => {
	if (offerCode === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
		return createSelector(
			[getDepartureCities, getAlternativeDepartureCities, getBookedDepartureCity],
			(departureCities = [], alternativeDepartureCities = [], selectedDepartureCity) => {
				if (!selectedDepartureCity || !departureCities || departureCities.length === 0) {
					return [];
				}

				const allDeparturesCities = departureCities.concat(alternativeDepartureCities);

				var departureCity = find(allDeparturesCities, departureCity => {
					return departureCity.code === selectedDepartureCity.code;
				});

				const durations = departureCity ? departureCity.durations : [];

				return durations || [];
			}
		);
	} else if (offerCode === OFFER_TYPES.ACCOMODATION_ONLY) {
		return createSelector(
			[getAvailablePrepackageOffers, getBookedOffer],
			(offers = [], selectedOffer) => {
				if (!selectedOffer || !offers) {
					return [];
				}

				var offer = find(offers, offer => {
					return offer.pricing.type === selectedOffer.type;
				});

				return offer ? offer.pricing.durations : [];
			}
		);
	}

	return createSelector(
		[],
		() => []
	);
};

// TODO Refacto selector avec arguments
export const getPrices = (offerCode, contractType) => {
	if (contractType === OFFER_CONTRACT_TYPES.HOTEL) {
		return createSelector(
			[getDurations(offerCode), getBookedDuration, getCurrentShop],
			(durations = [], selectedDuration, shop) => {
				let pricesPerMonth = [];

				if (isEmpty(selectedDuration) || !durations || durations.length === 0) {
					return [];
				}

				var duration = find(durations, duration => {
					return duration.value === selectedDuration.value;
				});

				if (duration && duration.prices) {
					// for GB market we use the new date calendar which need the month in string "3/2021" on mobile
					if (duration.prices.length >= PRICES_COUNT_THRESHOLD || shop === "en-GB") {
						pricesPerMonth = groupBy(
							duration.prices,
							price =>
								new Date(price.dd).getUTCMonth() +
								"/" +
								new Date(price.dd).getUTCFullYear()
						);
						pricesPerMonth = flow(
							toPairs,
							flattenDeep
						)(pricesPerMonth);
					} else {
						pricesPerMonth = duration.prices;
					}
				}

				return pricesPerMonth;
			}
		);
	} else if (contractType === OFFER_CONTRACT_TYPES.RENTAL) {
		return createSelector(
			[getRentalAccommodations(offerCode), getBookedRentalAccommodation, getCurrentShop],
			(rentalAccommodations = [], selectedRentalAccommodation = {}, shop) => {
				let pricesPerMonth = [];

				if (isEmpty(selectedRentalAccommodation) || !rentalAccommodations) {
					return [];
				}

				var rentalAccommodation = find(rentalAccommodations, rentalAccommodation => {
					return (
						rentalAccommodation.accommodation ===
						selectedRentalAccommodation.accommodation
					);
				});

				if (rentalAccommodation && rentalAccommodation.prices) {
					// for GB market we use the new date calendar which need the month in string "3/2021" on mobile
					if (
						rentalAccommodation.prices.length >= PRICES_COUNT_THRESHOLD ||
						shop === "en-GB"
					) {
						pricesPerMonth = groupBy(
							rentalAccommodation.prices,
							price =>
								new Date(price.dd).getUTCMonth() +
								"/" +
								new Date(price.dd).getUTCFullYear()
						);
						pricesPerMonth = flow(
							toPairs,
							flattenDeep
						)(pricesPerMonth);
					} else {
						pricesPerMonth = rentalAccommodation.prices;
					}
				}

				return pricesPerMonth;
			}
		);
	}
	return createSelector(
		[],
		() => []
	);
};

export const hasOfferWithFlight = createSelector(
	[getAvailablePrepackageOffers],
	(offers = []) => {
		return (
			findIndex(
				offers,
				offer => offer.pricing.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION
			) !== -1
		);
	}
);

export const getAccommodationNegociated = createSelector(
	[getAvailablePrepackageOffers],
	(offers = []) => {
		const hotelOnlyOffer = find(
			offers,
			offer => offer.pricing.type === OFFER_TYPES.ACCOMODATION_ONLY
		);

		return hotelOnlyOffer ? hotelOnlyOffer.negotiated : [];
	}
);

export const getTransportNegociated = createSelector(
	[getAvailablePrepackageOffers],
	(offers = []) => {
		const hotelWithFlightOffer = find(
			offers,
			offer => offer.pricing.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION
		);

		return hotelWithFlightOffer ? hotelWithFlightOffer.negotiated : [];
	}
);

export const getAllNegociated = createSelector(
	[getTransportNegociated, getAccommodationNegociated],
	(transportNegocitated = [], accommodationNegociated = []) => {
		if (transportNegocitated.length > 0) {
			const transportNegocitatedToDisplay = [...transportNegocitated];
			return transportNegocitatedToDisplay.map(transportNego => {
				const nego = accommodationNegociated.find(accNego => {
					return accNego.label === transportNego.label;
				});

				if (!nego && accommodationNegociated.length > 0) {
					transportNego.isTransportOnly = true;
				}

				return transportNego;
			});
		}
		return accommodationNegociated;
	}
);

export const getBookingOfferLabel = createSelector(
	[getBookingOfferType, getAvailablePrepackageOffers],
	(selectedOfferType, offers = []) => {
		const offerData = offers.find(offer => offer.pricing?.type === selectedOfferType);

		return offerData?.pricing?.label;
	}
);

export const getNegotiatedOfSelectedOffer = createSelector(
	[getTransportNegociated, getAccommodationNegociated, getOffer],
	(transportNegociated = [], accommodationNegociated = [], selectedOffer) => {
		const selectedOfferType = selectedOffer.type;

		if (selectedOfferType === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
			return transportNegociated.map(transportNego => transportNego);
		} else if (selectedOfferType === OFFER_TYPES.ACCOMODATION_ONLY) {
			return accommodationNegociated.map(accNego => accNego);
		}

		return [];
	}
);

export const getMonths = (offerCode, contractType) => {
	return createSelector(
		[getPrices(offerCode, contractType)],
		(pricesPerMonth = []) => pricesPerMonth.filter(price => typeof price === "string")
	);
};
const getCurrentBooking = state => state.booking;
const getQuotationPayloads = state => state.booking.quotationPayloads;

export const getQuotationPayload = productUri =>
	createSelector(
		[getQuotationPayloads],
		quotationPayloads =>
			quotationPayloads.find(quotationPayload => quotationPayload.productUri === productUri)
	);

export const selectBookingDataToHydrate = productUri =>
	createSelector(
		[getQuotationPayload(productUri), getCurrentBooking],
		(quotationPayload, currentBooking) => {
			const quotationPayloadCopy = quotationPayload ? { ...quotationPayload } : {};

			QUOTATION_PAYLOAD_PATHS.forEach(path => {
				if (isEqual(get(currentBooking, path), get(INITIAL_BOOKING, path))) {
					set(
						quotationPayloadCopy,
						path,
						get(quotationPayloadCopy, path, get(INITIAL_BOOKING, path))
					);
				} else {
					set(quotationPayloadCopy, path, get(currentBooking, path));
				}
			});

			return quotationPayloadCopy;
		}
	);
