import { FETCH_STRESS_DATA_SUCCESS } from "app/actionTypes";
import { FETCH_PARTNER_DATA_SUCCESS } from "app/reducers/partnerActionTypes";

const INITIAL_STATE = {
	stressMarketingMessages: [],
	productStressMarketingData: {},
};

export default (stressMarketingState = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_PARTNER_DATA_SUCCESS: {
			const { list } = action?.res?.data?.stressMarketing || [];
			return {
				...stressMarketingState,
				stressMarketingMessages: list,
			};
		}
		case FETCH_STRESS_DATA_SUCCESS: {
			return {
				...stressMarketingState,
				productStressMarketingData: action?.res?.data,
			};
		}
		default:
			return stressMarketingState;
	}
};
