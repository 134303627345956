import {
	AUTH_SUCCESS,
	CHECK_AVAILABILITIES_SUCCESS,
	FETCH_TOKEN_INFO_SUCCESS,
	LOGOUT,
	LOGOUT_FROM_ONE_DEVICES_SUCCESS,
	SET_ID,
} from "app/actionTypes";
import {
	CLEAR_OAUTHORIZATION_CODE,
	OAUTH_SUCCESS,
	SAVE_OAUTHORIZATION_CODE,
} from "app/pages/Auth/OAuth/oAuthActionTypes";
import { CHECK_SDP_AVAILABILITIES_SUCCESS } from "app/pages/SmartDP/smartDPActionTypes";
import { SET_SESSION_BIID, SET_USER_BIID, SET_CAN_LOGIN_AT } from "app/pages/Auth/AuthActionTypes";

const initialState = {
	oAuthorizationCode: undefined,
	tokenInfos: [],
	uuid: undefined,
	sessionBiidExpirationDate: undefined,
	sessionBiid: undefined,
	userBiid: undefined,
	operatorEmail: undefined, // email de l'operator si celui s'est connecté
	canLoginAt: undefined,
};

export default function authReducer(auth = initialState, action) {
	switch (action.type) {
		case SET_SESSION_BIID: {
			return {
				...auth,
				sessionBiid: action.sessionBiid,
				sessionBiidExpirationDate: action.sessionBiidExpirationDate,
			};
		}
		case SET_USER_BIID: {
			return {
				...auth,
				userBiid: action.userBiid,
			};
		}
		case AUTH_SUCCESS: {
			return Object.assign({}, auth, {
				uuid: action.uuid,
			});
		}
		case OAUTH_SUCCESS: {
			return {
				...auth,
				oAuthorizationCode: undefined,
			};
		}
		case CLEAR_OAUTHORIZATION_CODE: {
			return {
				...auth,
				oAuthorizationCode: undefined,
			};
		}
		case SAVE_OAUTHORIZATION_CODE: {
			return {
				...auth,
				oAuthorizationCode: action.code,
			};
		}
		case LOGOUT_FROM_ONE_DEVICES_SUCCESS:
		case FETCH_TOKEN_INFO_SUCCESS: {
			return {
				...auth,
				tokenInfos: action.tokenInfos,
			};
		}
		case LOGOUT:
			return {
				...initialState,
				sessionBiidExpirationDate: auth.sessionBiidExpirationDate,
				sessionBiid: auth.sessionBiid,
				userBiid: auth.userBiid,
			};
		case CHECK_SDP_AVAILABILITIES_SUCCESS:
		case CHECK_AVAILABILITIES_SUCCESS: {
			return { ...auth, operatorEmail: action.res.data.operator };
		}
		case SET_ID:
			return Object.assign({}, auth, {
				uuid: action.uuid,
			});
		case SET_CAN_LOGIN_AT:
			return {
				...auth,
				canLoginAt: action.canLoginAt,
			};
		default:
			return auth;
	}
}
