import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import TripAdvisorSubratings from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorSubratings/TripAdvisorSubratings";
import TripAdvisorRating from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorRating/TripAdvisorRating";
import TripAdvisorReviewRatingCount from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorReviewRatingCount/TripAdvisorReviewRatingCount";
import TripAdvisorReview from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisorReview/TripAdvisorReview";
import "./TripAdvisorDetail.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const TripAdvisorDetail = ({
	rating = 0,
	ranking_string,
	reviewsCount,
	reviewRatingCount,
	subratings = [],
	reviews = [],
}) => {
	return (
		<div className="tripadvisor-detail" data-testid="tripadvisor-detail">
			<Typography
				variant={TYPOGRAPHY_VARIANTS.XL2}
				isBold
				className="tripadvisor-detail__title"
			>
				<FormattedMessage id="tripadvisor.note.title" />
			</Typography>
			<div className="tripadvisor-detail__data">
				<TripAdvisorRating
					rating={rating}
					ranking_string={ranking_string}
					reviewsCount={reviewsCount}
					showRanking={true}
				/>
				<TripAdvisorReviewRatingCount reviewRatingCount={reviewRatingCount} />
				<TripAdvisorSubratings subratings={subratings} />
			</div>
			<div className="tripadvisor-detail__reviews">
				<Typography
					variant={TYPOGRAPHY_VARIANTS.XL2}
					isBold
					className="tripadvisor-detail__title"
				>
					<FormattedMessage id="tripadvisor.recent.reviews.title" />
				</Typography>
				{reviews?.length > 0 &&
					reviews.map(({ rating, title, text, published_date, user }, index) => (
						<TripAdvisorReview
							key={index}
							rating={rating}
							title={title}
							text={text}
							travel_date={published_date}
							user={user}
						/>
					))}
			</div>
		</div>
	);
};

TripAdvisorDetail.propTypes = {
	subratings: PropTypes.array,
	rating: PropTypes.number,
	ranking_string: PropTypes.string,
	reviewsCount: PropTypes.number,
	reviewRatingCount: PropTypes.object,
	reviews: PropTypes.array,
};

export default memo(TripAdvisorDetail);
