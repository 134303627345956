import { useEffect } from "react";

const useClickAway = (ref, hander, ignoreComponents) => {
	useEffect(() => {
		const listener = event => {
			if (
				!ref.current ||
				ref.current.contains(event.target) ||
				event.target.classList.contains("click-away-ignore") ||
				(Array.isArray(ignoreComponents) &&
					ignoreComponents.length > 0 &&
					ignoreComponents.some(className => event.target.classList.contains(className)))
			) {
				return;
			}
			hander(event);
		};
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);

		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, hander]);
};

export default useClickAway;
