import {
	FETCH_SEARCH_MY_BOOKING_FAILURE,
	FETCH_SEARCH_MY_BOOKING_SUCCESS,
	RESET_SEARCH_MY_BOOKING,
	FETCH_SEARCH_MY_BOOKING_NOTIFICATION_SUCCESS,
} from "app/actionTypes";

const initialSearchMyBooking = {
	booking: undefined,
	timestamp: undefined,
	notification: {},
};

export default function searchMyBookingReducer(searchMyBooking = initialSearchMyBooking, action) {
	switch (action.type) {
		case FETCH_SEARCH_MY_BOOKING_SUCCESS:
			return {
				...searchMyBooking,
				booking: action.res.data.booking,
				timestamp: action.res.data.timestampISO8601,
			};
		case FETCH_SEARCH_MY_BOOKING_NOTIFICATION_SUCCESS:
			return {
				...searchMyBooking,
				notification: action.res.data,
			};
		case FETCH_SEARCH_MY_BOOKING_FAILURE:
			return {
				...searchMyBooking,
			};
		case RESET_SEARCH_MY_BOOKING:
			return {
				...searchMyBooking,
				notification: {},
				booking: undefined,
				timestamp: undefined,
			};
		default:
			return searchMyBooking;
	}
}
