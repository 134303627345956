export const FETCH_ALL_STOPOVER_CITIES = "FETCH_STOPOVER_CITIES";
export const FETCH_ALL_STOPOVER_CITIES_SUCCESS = "FETCH_STOPOVER_CITIES_SUCCESS";
export const FETCH_ALL_STOPOVER_CITIES_FAILURE = "FETCH_STOPOVER_CITIES_FAILURE";
export const FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES =
	"FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES";
export const FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_SUCCESS =
	"FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_SUCCESS";
export const FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_FAILURE =
	"FETCH_STOPOVER_AVAILABLE_DEPARTURE_DATE_CITIES_FAILURE";
export const FETCH_STOPOVER_QUOTE = "FETCH_STOPOVER_QUOTE";
export const FETCH_STOPOVER_QUOTE_REQUEST = "FETCH_STOPOVER_QUOTE_REQUEST";
export const FETCH_STOPOVER_QUOTE_SUCCESS = "FETCH_STOPOVER_QUOTE_SUCCESS";
export const FETCH_STOPOVER_QUOTE_FAILURE = "FETCH_STOPOVER_QUOTE_FAILURE";
export const FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION = "FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION";
export const FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION_REQUEST =
	"FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION_REQUEST";
export const FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION_SUCCESS =
	"FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION_SUCCESS";
export const FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION_FAILURE =
	"FETCH_STOPOVER_ACCOMMODATION_DESCRIPTION_FAILURE";
export const CHECK_STOPOVER_AVAILABILITIES = "CHECK_STOPOVER_AVAILABILITIES";
export const CHECK_STOPOVER_AVAILABILITIES_REQUEST = "CHECK_STOPOVER_AVAILABILITIES_REQUEST";
export const CHECK_STOPOVER_AVAILABILITIES_SUCCESS = "CHECK_STOPOVER_AVAILABILITIES_SUCCESS";
export const CHECK_STOPOVER_AVAILABILITIES_FAILURE = "CHECK_STOPOVER_AVAILABILITIES_FAILURE";
export const FETCH_STOPOVER_PACKAGE = "FETCH_STOPOVER_PACKAGE";
export const FETCH_STOPOVER_PACKAGE_REQUEST = "FETCH_STOPOVER_PACKAGE_REQUEST";
export const FETCH_STOPOVER_PACKAGE_SUCCESS = "FETCH_STOPOVER_PACKAGE_SUCCESS";
export const FETCH_STOPOVER_PACKAGE_FAILURE = "FETCH_STOPOVER_PACKAGE_FAILURE";
