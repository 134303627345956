import { createSelector } from "reselect";

const getSearchMyBookingNotifications = state => state?.searchMyBooking?.notification;

export const getSearchMyBookingCustomerRequestsCount = createSelector(
	[getSearchMyBookingNotifications],
	(notification = {}) => {
		return notification?.totalNotRead || 0;
	}
);
