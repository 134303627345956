import { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { untouch } from "redux-form";
import Login from "app/pages/Auth/Signin/Login.jsx";
import { authSuccess, logout, login, setCanLoginAt } from "app/pages/Auth/AuthActions";
import { sendTagOnEmailSignin, sendTagOnEmailSigninError } from "app/utils/analytics";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";
import { getParentPath } from "app/utils/routerUtils";
import { BRANDS, HTTP_STATUS_CODES } from "app/constants";
import { mapErrorHttpCodeToMessage } from "app/utils/utils";
import { fetchMyBookingsNotification } from "app/pages/Account/MyBookings/myBookingsActionCreators";
import { resetSearchMyBooking } from "app/pages/Account/SearchMyBooking/searchMyBookingActionCreators";
import AppGlobalsContext from "app/AppGlobalsContext";
import { fetchCreditCampaign } from "app/pages/Account/MyCoupons/couponActionCreators";
import { getInscriptionCreditCampaign } from "app/pages/Account/MyCoupons/couponSelector";
import { IconCredit } from "app/pages/.shared/static/icons";
import { FormattedMessage } from "react-intl";

const LoginContainer = ({
	headline,
	footer,
	showTopFacebook,
	onAuthSuccess,
	onToogleSignupForm,
	untouch,
	acceptCookyPolicy,
	homeRedirection = true,
	logout,
	login,
	authSuccess,
	canLoginAt,
	setCanLoginAt,
	fetchMyBookingsNotification = () => {},
	resetSearchMyBooking = () => {},
	fetchCreditCampaign = () => {},
	creditCampaign = [],
	inscriptionCreditCampaign = {},
	partnercode,
}) => {
	const { brand, shop } = useContext(AppGlobalsContext);
	const location = useLocation();
	const navigate = useNavigate();
	const onToogleSignup =
		onToogleSignupForm && typeof onToogleSignupForm === "function"
			? onToogleSignupForm
			: () => {
					const parentPath = getParentPath(location.pathname);
					navigate({ pathname: `${parentPath}/signup` });
			  };

	useEffect(() => {
		if (partnercode && creditCampaign?.length === 0) {
			fetchCreditCampaign(partnercode);
		}
	}, [partnercode]);

	const enableFacebookLogin = brand !== BRANDS.AX;
	const handleSubmit = useCallback((values, { setErrors, setStatus, setSubmitting }) => {
		login(values).then(res => {
			if (res.status === HTTP_STATUS_CODES.OK) {
				logout();
				authSuccess(res);
				sendTagOnEmailSignin();
				acceptCookyPolicy();
				resetSearchMyBooking();
				fetchMyBookingsNotification({
					brand,
					shop,
					email: values.email,
				});
				if (onAuthSuccess && typeof onAuthSuccess === "function") {
					onAuthSuccess(res);
				} else {
					navigate("/listing", { replace: true }); // TODO pour le cas /home/signup continue de fonctionner. A refactorer en utilisant authSuccess
				}
			} else {
				setStatus({ success: false });
				setSubmitting(false);
				sendTagOnEmailSigninError({ status: res.status, error: res.id });
				setErrors({
					_error: mapErrorHttpCodeToMessage(res.status, {
						[HTTP_STATUS_CODES.UNAUTHORIZED]: { id: "error.password.incorrect" },
						[HTTP_STATUS_CODES.FORBIDDEN]: {
							id: "error.facebook.account.without.password",
						},
						[HTTP_STATUS_CODES.NOT_FOUND]: { id: "error.email.not.registered" },
					}),
				});
				if (res?.data?.canLoginAt) {
					setCanLoginAt(res.data.canLoginAt);
				}
			}
		});
	}, []);

	let headlineNode = headline;
	if (inscriptionCreditCampaign && inscriptionCreditCampaign?.amount) {
		headlineNode = (
			<div className="auth__credit-banner" data-testid="auth-credit-banner">
				<IconCredit className="auth__credit-icon" />
				<FormattedMessage id="auth.credit.campaign.login.label" tagName="span" />
			</div>
		);
	} else {
		headlineNode = headline;
	}

	return (
		<Login
			homeRedirection={homeRedirection}
			showTopFacebook={enableFacebookLogin && showTopFacebook}
			headline={headlineNode}
			footer={footer}
			handleSubmit={handleSubmit}
			facebookOnSuccess={success => {
				// TODO refactorer avec onSuccess
				acceptCookyPolicy();
				if (onAuthSuccess && typeof onAuthSuccess === "function") {
					onAuthSuccess(success);
				} else {
					navigate("/listing", { replace: true }); // TODO pour le cas /home/signup continue de fonctionner. A refactorer en utilisant authSuccess
				}
			}}
			onToogleSignupForm={onToogleSignup}
			untouch={untouch}
			canLoginAt={canLoginAt}
			setCanLoginAt={setCanLoginAt}
		/>
	);
};

LoginContainer.propTypes = {
	untouch: PropTypes.func,
	onAuthSuccess: PropTypes.func,
	authSuccess: PropTypes.func,
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	onToogleSignupForm: PropTypes.func,
	acceptCookyPolicy: PropTypes.func,
	logout: PropTypes.func,
	login: PropTypes.func,
	showTopFacebook: PropTypes.bool,
	homeRedirection: PropTypes.bool,
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	setCanLoginAt: PropTypes.func,
	canLoginAt: PropTypes.number,
	fetchMyBookingsNotification: PropTypes.func,
	resetSearchMyBooking: PropTypes.func,
	fetchCreditCampaign: PropTypes.func,
	creditCampaign: PropTypes.array,
	inscriptionCreditCampaign: PropTypes.object,
	partnercode: PropTypes.string,
};

const mapStateToProps = state => {
	return {
		canLoginAt: state.auth.canLoginAt,
		creditCampaign: state.coupons.creditCampaign,
		inscriptionCreditCampaign: getInscriptionCreditCampaign(state),
		partnercode: state.partner.code,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			untouch,
			acceptCookyPolicy,
			logout,
			login,
			authSuccess,
			setCanLoginAt,
			resetSearchMyBooking,
			fetchMyBookingsNotification,
			fetchCreditCampaign,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginContainer);
