import { useContext, useMemo } from "react";
import RumContext from "./RumContext";
import PropTypes from "prop-types";

export const RumContextProvider = ({ componentName, customAttributes, children }) => {
	const parentContext = useContext(RumContext);
	const newContext = useMemo(
		() => ({
			component: componentName,
			customAttributes: {
				...parentContext.customAttributes,
				...customAttributes,
			},
			componentBreadCrumbs: `${parentContext.componentBreadCrumbs}.${componentName}`,
		}),
		[
			componentName,
			parentContext.componentBreadCrumbs,
			parentContext.customAttributes,
			customAttributes,
		]
	);

	return <RumContext.Provider value={newContext}>{children}</RumContext.Provider>;
};

RumContextProvider.propTypes = {
	componentName: PropTypes.string,
	customAttributes: PropTypes.any,
	children: PropTypes.element,
};

export function WithRumContext(componentName, options, Component) {
	if (typeof Component === "undefined") {
		return WithRumContext(componentName, {}, options);
	}

	// eslint-disable-next-line react/no-multi-comp
	return props => (
		<RumContextProvider
			componentName={componentName}
			customAttributes={options.customAttributes}
		>
			<Component {...props} />
		</RumContextProvider>
	);
}
