import { FETCH_FIRST_PRODUCTS_SUCCESS, FETCH_PRODUCTS_SUCCESS } from "app/actionTypes";
import { computedProductDates } from "app/utils/utils";
import { compact } from "lodash/array";
import { MAX_PRODUCTS_TO_RENDER_SERVER_SIDE } from "app/constants";

const initialState = [];

export default (products = initialState, action) => {
	switch (action.type) {
		case FETCH_FIRST_PRODUCTS_SUCCESS: {
			const { products: dataProducts } = action.res.data;
			let productList = [];

			if (dataProducts && action.spotlight) {
				const spotlightProduct = dataProducts.find(
					product => product.uri === action.spotlight
				);
				// add spotlight product to first products to render
				productList = [
					...dataProducts.slice(0, MAX_PRODUCTS_TO_RENDER_SERVER_SIDE),
					spotlightProduct,
				];
			} else {
				productList =
					dataProducts && dataProducts.slice(0, MAX_PRODUCTS_TO_RENDER_SERVER_SIDE);
			}
			let newProductList = [];

			if (productList && productList.length > 0) {
				newProductList = computedProductDates(compact(productList));
			}
			return newProductList;
		}
		case FETCH_PRODUCTS_SUCCESS: {
			const productList = action.res.data.products;
			let newProductList = [];

			if (productList && productList.length > 0) {
				newProductList = computedProductDates(productList);
			}
			return newProductList;
		}
		default:
			return products;
	}
};
