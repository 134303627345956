import axios from "axios";

export const FETCH_HOTELS = "FETCH_HOTELS";
export const FETCH_HOTELS_REQUEST = "FETCH_HOTELS_REQUEST";
export const FETCH_HOTELS_SUCCESS = "FETCH_HOTELS_SUCCESS";
export const FETCH_HOTELS_FAILURE = "FETCH_HOTELS_FAILURE";

export const fetchHotels = quotationPayload => {
	return {
		type: FETCH_HOTELS,
		// TODO: Modify with real API URL /quote to implement in envVar file
		promise: axios.post("http://localhost:9000/hotelonly/quote", {
			...quotationPayload,
		}),
	};
};
