import "./patchReact";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { loadableReady } from "@loadable/component";
import { Provider } from "react-redux";
import { configureStore } from "../app/configureStore";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { initPersistor } from "app/utils/persistor";
import ResponsiveStateProvider from "app/pages/.shared/responsive/ResponsiveStateProvider.jsx";
import { IntlProvider } from "react-intl";
import env from "app/utils/env";
import PerfectAppContainer from "app/PerfectAppContainer";
import { fetchMessages } from "src/intl/intlUtils";
import ScrollToTop from "app/pages/.shared/Scroll/ScrollToTop";
import TechnicalErrorDefault from "app/pages/TechnicalError/default/TechnicalErrorDefault";
import FooterContainer from "app/pages/Footer/FooterContainer";
import AnalyticsContext from "app/utils/analytics/AnalyticsContext";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import RumErrorBoundary from "app/datadog/RumErrorBoundary";
import { RumContextProvider } from "app/datadog/RumContextProvider";

//scoped references
const initialState = window.__INITIAL_STATE__ || {};
const { shop, brand, envVars, abTests = {} } = initialState;
const locale = shop.slice(0, 2);
const store = configureStore(initialState);

let intl;

// On migre toutes les données du localstorage dans la shop par défaut fr-FR pour ne pas être considéré comme déconnecté
// lors du passage vers secu-af
if (window.localStorage) {
	const authData = window.localStorage.getItem("reduxPersist:auth");
	if (authData) {
		window.localStorage.setItem("reduxPersist:fr-FR:auth", authData);
		window.localStorage.removeItem("reduxPersist:auth");
	}

	const emailData = window.localStorage.getItem("reduxPersist:email");
	if (emailData) {
		window.localStorage.setItem("reduxPersist:fr-FR:email", emailData);
		window.localStorage.removeItem("reduxPersist:email");
	}

	const cookiePolicyData = window.localStorage.getItem("reduxPersist:isCookiePolicyAccepted");
	if (cookiePolicyData) {
		window.localStorage.setItem("reduxPersist:fr-FR:isCookiePolicyAccepted", cookiePolicyData);
		window.localStorage.removeItem("reduxPersist:isCookiePolicyAccepted");
	}

	const partnerData = window.localStorage.getItem("reduxPersist:partner");
	if (partnerData) {
		window.localStorage.setItem("reduxPersist:fr-FR:partner", partnerData);
		window.localStorage.removeItem("reduxPersist:partner");
	}
}

/*
 ** async execution sequence
 */

const asyncIntlPolyfill = locale => {
	const polyfills = [];

	if (shouldPolyfillCanonicalLocales()) {
		polyfills.push([import("@formatjs/intl-getcanonicallocales/polyfill")]);
	}

	if (shouldPolyfillPluralRules()) {
		polyfills.push([import("@formatjs/intl-pluralrules/polyfill")]);

		switch (locale) {
			default:
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/en"));
				break;
			case "fr":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/fr"));
				break;
			case "es":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/es"));
				break;
			case "nl":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/nl"));
				break;
			case "it":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/it"));
				break;
			case "de":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/de"));
				break;
			case "ar":
				polyfills.push(import("@formatjs/intl-pluralrules/locale-data/ar"));
				break;
		}
	}

	if (polyfills.length === 0) {
		return Promise.resolve();
	}

	return Promise.all(polyfills);
};

asyncIntlPolyfill(locale).then(() => {
	const apiURL = env("CONTENT_API_URL") || env("BASE_URL");

	fetchMessages(apiURL, brand.code, shop).then(messages => {
		// Note : Seulement cote front (voir i18nMiddleware pour la generation serveur)

		intl = {
			messages: messages,
			locale: shop.includes("ar") ? "ar" : shop, // astuce : utiliser "ar" et non par "ar-SA" pour avoir les chiffres en 1, 2, 3 ....
		};
		asyncStoreLoader();
	});
});

function asyncStoreLoader() {
	initPersistor(store, { shop }, renderApp);
}

function renderApp() {
	const injectAnalyticScripts = () => {
		let gtmId = envVars.GTM_ID;

		if (gtmId) {
			const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer', '${gtmId}');`;

			const script = document.createElement("script");
			script.text = GTM_SCRIPT;
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(script);
		}
	};

	const checkEnvironnementVariables = () => {
		const { BASE_URL, QUOTE_API_URL } = envVars;

		if (!BASE_URL) {
			throw new Error("Missing environment variable : BASE_URL");
		}

		if (!QUOTE_API_URL) {
			throw new Error("Missing environment variable : QUOTE_API_URL");
		}
	};

	checkEnvironnementVariables();

	const initialFlagsData = abTests?.initialFlagsData;

	const app = (
		<Provider store={store}>
			<ResponsiveStateProvider>
				<IntlProvider {...intl}>
					<AnalyticsContext.Provider value={{ store: store }}>
						<RumContextProvider componentName="AppComponent">
							<Router basename={`${envVars.PROXY_BASENAME_PREFIX || ""}/${shop}`}>
								<Routes>
									<Route
										path="*"
										element={
											<RumErrorBoundary
												fallback={
													<React.Fragment>
														<HeaderContainer />
														<div style={{ margin: "40px 0" }}>
															<TechnicalErrorDefault />
														</div>
														<FooterContainer />
													</React.Fragment>
												}
											>
												<ScrollToTop />
												<PerfectAppContainer
													initialFlagsData={initialFlagsData} // avoid flickering due to default value of useFsFlag
												/>
											</RumErrorBoundary>
										}
									/>
								</Routes>
							</Router>
						</RumContextProvider>
					</AnalyticsContext.Provider>
				</IntlProvider>
			</ResponsiveStateProvider>
		</Provider>
	);

	loadableReady(() => {
		ReactDOM.hydrate(app, document.querySelector("#perfectstay"), () => {
			// timeout pour s'assurer que les photos chargent bien après le chat et le script Google Analytics
			// eslint-disable-next-line max-nested-callbacks
			setTimeout(() => {
				injectAnalyticScripts();
			}, 500);
		});
	});
}

if (module.hot) {
	module.hot.accept("app/PerfectAppContainer", () => {
		renderApp();
	});
}
