import { memo } from "react";
import PropTypes from "prop-types";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";

const IMG_SIZES = {
	medium: { width: 978 },
	small: { width: 768 },
	large: { width: 2880 },
	xlarge: { width: 2880 },
};

const SmartDPMarketingHeroImage = ({
	imageThumbnail,
	imageUrl,
	gradient,
	sizes,
	resolution,
	backgroundPosition,
	isBackgroundProgressiveImg = false,
}) => {
	return isBackgroundProgressiveImg ? (
		<BackgroundProgressiveImg
			sizes={sizes ? sizes : IMG_SIZES}
			resolution={resolution}
			thumbnail={imageThumbnail}
			src={imageUrl}
			backgroundPosition={backgroundPosition}
			quality="q_auto:best"
			gradient={gradient}
		/>
	) : (
		<ProgressiveImg
			sizes={sizes ? sizes : IMG_SIZES}
			resolution={resolution}
			thumbnail={imageThumbnail}
			src={imageUrl}
			backgroundPosition={backgroundPosition}
			quality="q_auto:best"
			gradient={gradient}
		/>
	);
};

SmartDPMarketingHeroImage.propTypes = {
	imageThumbnail: PropTypes.string,
	imageUrl: PropTypes.string,
	gradient: PropTypes.string,
	sizes: PropTypes.object,
	resolution: PropTypes.string,
	isBackgroundProgressiveImg: PropTypes.bool,
	backgroundPosition: PropTypes.string,
};

export default memo(SmartDPMarketingHeroImage);
