import { createSelector } from "reselect";

const getContextualizedSignupPages = state => state.signupPagesAuth.signupPages;
const getActiveContextualizedSignupPageCode = state => state.signupPagesAuth.activeSignupPageCode;

export const getCurrentContextualizedSignupPage = createSelector(
	[getContextualizedSignupPages, getActiveContextualizedSignupPageCode],
	(signupPages = [], activeSignupPageCode) => {
		return signupPages?.find(signupPage => signupPage?.code === activeSignupPageCode);
	}
);
