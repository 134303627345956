export default function promiseMiddleware() {
	return next => action => {
		const { promise, type, ...rest } = action;

		if (!promise) {
			return next(action);
		}

		const SUCCESS = type + "_SUCCESS";
		const REQUEST = type + "_REQUEST";
		const FAILURE = type + "_FAILURE";
		next({ ...rest, type: REQUEST });
		return promise
			.then(res => {
				next({ ...rest, res, type: SUCCESS });
				return res;
			})
			.catch(error => {
				next({ ...rest, error, type: FAILURE });

				console.error("promiseMiddleware", FAILURE, error); // eslint-disable-line no-console

				// https://github.com/mzabriskie/axios/blob/master/UPGRADE_GUIDE.md#error-handling
				if (error.response) {
					return error.response;
				}
				return error;
			});
	};
}
